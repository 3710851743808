import makeValidationSchema from './PartyConfigMakeValidationSchema'
import { components } from 'cng-web-lib'
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngSelectField, CngSwitchField }
  }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  module: '',
  disableFilterByUserFlag: 'false'
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index }) {
  const { setValue,getValues, watch } = useFormContext()
  const [disableFilterByUserFlag, setDisableFilterByUserFlag] = useState(false)

  let moduleList = []
  const nangPartyConfigJson = sessionStorage.getItem("nangPartyConfig")
  const nangPartyConfig = nangPartyConfigJson ? JSON.parse(nangPartyConfigJson) : null

  if(nangPartyConfig!=null){
      for(let i =0; i< nangPartyConfig.length; i++ ){
      var data = nangPartyConfig[i];
        if(data.module=="EHBL"){
          moduleList.push( { text: 'eHBL', value: 'EHBL' })
        }
        if(data.module=="ACIOCN"){
          moduleList.push({ text: 'ACI Ocean', value: 'ACIOCN' });
        }
        if(data.module=="ACEOCN"){
          moduleList.push({ text: 'ACE Ocean', value: 'ACEOCN' });
        }
        if(data.module=="ACEISF"){
          moduleList.push({ text: 'ACE ISF', value: 'ACEISF' });
        }
      }
  }

  useEffect(()=>{
    let value = getValues(getFieldName('disableFilterByUserFlag'));

    if(value=="true"){
      setDisableFilterByUserFlag(true)
    }else{
      setDisableFilterByUserFlag(false)
    }
  },[])

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `userPartyConfigs.${index}.${field}` : field
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CngSelectField
          required
          label='Module'
          name={getFieldName('module')}
          size='small'
          items={moduleList}
        />
      </Grid>
      <Grid item xs={12}>
            <CngSwitchField
              name={getFieldName('disableFilterByUserFlag')}
              label="Disable Filter By User Flag"
              checked={disableFilterByUserFlag}
              onChange={(_, value) => {
                if(value==true){
                  setDisableFilterByUserFlag(true)
                  setValue(getFieldName('disableFilterByUserFlag'),"true")
                }else{
                  setDisableFilterByUserFlag(false)
                  setValue(getFieldName('disableFilterByUserFlag'),"false")
                }
              }}
            />
          </Grid>
    </Grid>
  )
}

const SCACFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default SCACFormProperties
