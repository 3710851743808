import React from 'react'
import { Grid, Box, Container, Typography, makeStyles } from '@material-ui/core'


const useStyles = makeStyles(() => ({
  aboutUs: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/rbiAssets/aboutus.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    paddingTop: '26px',
    backgroundColor: '#fff'
  },
  list: {
    listStyle: 'none',
    '& > li': {
      marginLeft: 16,
      marginBottom: 8,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 8,
        width: 4,
        height: 4,
        backgroundColor: '#064fa2',
        borderRadius: '50%'
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}))

function AboutUsView() {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.aboutUs}>
          <Container>
            <Grid container spacing={1} style={{ marginBottom: '33px' }}>
              <Grid item xs={12}>
                <Typography variant='h1' style={{ fontWeight: 'bold', color: '#D25D13', textAlign: 'center', padding: '20px 0' }}>About Us</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>Since 2006 the ACE (Automated Commercial Environment) regulation is in force. ACI (Advance Commercial Information) is now also mandatory.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>Road carriers are required to provide information about the team, truck, trailer and shipment entering Canada or the U.S. prior to arrival at the border.
                  Electronic manifests must be submitted one hour before arrival at the port of entry for regular shipments, and 30 minutes in advance for ACCELERATED shipments.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>The transmission of the manifest serves only to assess the risk and is required for each shipment.
                  In the case where a truck arrives at the border without first submitting the necessary information, the driver is liable to a fine and may be denied access to the country.</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginBottom: '20px' }}>
              <Grid item xs={12}>
                <Typography variant='h4' style={{ fontWeight: 'bold' }}>Why RBI CANADA</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>RBI offers over 30 years of experience in transborder services.
                  We offer more than simple customs services, we give you the support and solutions that are in line with your overall needs.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>Unlike conventional transborder service providers, we have a global vision of the challenges, laws and norms regulating traffic across the border.
                  Our in-depth knowledge of laws and regulations in international trade gives us the understanding needed to advise you properly.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>As a supplier of integrated Canadian and American transborder services, we are well positioned to offer services unparalleled in North America.
                  We will help you achieve your traffic strategy and answer all your needs in logistics management.</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginBottom: '20px' }}>
              <Grid item xs={12}>
                <Typography variant='h4' style={{ fontWeight: 'bold' }}>Other important points:</Typography>
              </Grid>
              <Grid item xs={12}>
                <ul className={classes.list}>
                  <li>Centralized processing</li>
                  <li>Dedication to customers</li>
                  <li>Comprehensive knowledge of ACE/ACI processes and regulations</li>
                  <li>Electronic imaging capability</li>
                  <li>Workflow and archiving program</li>
                  <li>Easy access to our friendly and knowledgeable customer service team</li>
                  <li>Service available 24/7</li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>
    </Grid>
  )
}

export default AboutUsView
