import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import UserProfileApiUrls from '../../../apiUrls/UserProfileApiUrls'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'

const {
  button: { CngIconButton },
  form: {
    field: { CngTextField }
  },
  dropzone: { CngFileUpload }
} = components

const defaultValue = {
  companyName: '',
  companyAddress: '',
  companyPhoneNumber: '',
  companyFax: ''
}
const useStyles = makeStyles(() => ({
  body: {
    position: 'relative'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff80',
    zIndex: 2
  }
}))

function PartyCoverSheetDetailsSection(props) {
  const { showNotification } = props

  const { watch, getValues, setValue, reset } = useFormContext()
  const { securedSendRequest } = useServices()
  const classes = useStyles()
  const [isUploading, setIsUploading] = useState(false)
  const uploadedFile = watch('partyCSDetails')?.[0]
  console.log("uploadedFile:" + JSON.stringify(uploadedFile));

  function handleSelectFile(data) {
    setIsUploading(true)
    const formData = new FormData()
    const fileForUserDetails = FileForUserGetUserDetails()

    formData.append(
      'fileForUserDetails',
      fileForUserDetails
        ? JSON.stringify({
          fileForUserId: fileForUserDetails.fileForUserId,
          fileForUserPartyId: fileForUserDetails.fileForUserPartyId,
          fileForUserLoginId: fileForUserDetails.fileForUserLoginId
        })
        : '{}'
    )

    formData.append('file', data[0].file)

    securedSendRequest.execute(
      'POST',
      UserProfileApiUrls.UPLOAD_LOGO,
      formData,
      (response) => {
        const { partyCSDetails } = response.data
        console.log("logo: "+JSON.stringify(partyCSDetails));
        if (partyCSDetails.errorMessages && !_.isEmpty(partyCSDetails.errorMessages)) {
          showNotification('error', partyCSDetails.errorMessages)
        } else {
          reset({ ...getValues(), partyCSDetails: partyCSDetails })

          showNotification('success', 'Upload Logo Successful.')
        }
      },
      (error) => console.error(error),
      () => setIsUploading(false),
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }
  return (
    <CngSection title='Company Details'>
      <Grid alignItems='center' container justify='flex-end' spacing={1}>
        {_.isEmpty(uploadedFile) ? (
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', 'plus']}
              onClick={() => setValue('partyCSDetails', [defaultValue])}
              size='small'
              type='outlined'
            />
          </Grid>
        ) : (
            <React.Fragment>
              <Grid item xs={12} sm>
                <Grid alignItems='center' container justify='flex-end' spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <CngTextField
                      label='Company Name'
                      inputProps={{ maxLength: 60 }}
                      name='partyCSDetails.0.companyName'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CngTextField
                      label='Company Address'
                      inputProps={{ maxLength: 200 }}
                      name='partyCSDetails.0.companyAddress'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CngTextField
                      label='Company Phone Number'
                      inputProps={{ maxLength: 100 }}
                      name='partyCSDetails.0.companyPhoneNumber'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CngTextField
                      label='Company Fax'
                      inputProps={{ maxLength: 100 }}
                      name='partyCSDetails.0.companyFax'
                      size='small'
                    />
                  </Grid>
                  {isUploading && (
                    <Box className={classes.loader}>
                      <CircularProgress />
                    </Box>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CngFileUpload
                        accept={['.jpeg', '.jpg', '.png']}
                        files={[]}
                        maxFiles={1}
                        maxSize={6291456}
                        onFileSelect={handleSelectFile}
                        moreActions={[]}
                        renderRow={() => <></>}
                      />
                    </Grid>
                    {uploadedFile && (
                      <Grid item xs={12}>
                        <CngSection
                          title='Uploaded Logo'
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={4}>
                              <CngField label='File Name'>
                                {uploadedFile.fileName}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                              <CngField label='File Size'>
                                {uploadedFile.fileSize}
                              </CngField>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={4}>
                              <CngField label='File Extension'>
                                {uploadedFile.fileExt}
                              </CngField>
                            </Grid>
                            <Grid item xs={12}>
                              <CngField label='File Path'>
                              {uploadedFile.filePath}
                              </CngField>
                            </Grid>
                          </Grid>
                        </CngSection>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Grid container spacing={1}>
                  <Grid item>
                    <CngIconButton
                      icon={['fal', 'trash']}
                      onClick={() => {
                        setValue('partyCSDetails', [])
                      }}
                      size='small'
                      type='outlined'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
      </Grid>
    </CngSection>
  )
}

export default PartyCoverSheetDetailsSection
