import React from 'react'
import { Grid, Box, Container, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle1, faCircle2, faCircle3 } from '@fortawesome/pro-light-svg-icons'
import AboutUsImage from 'src/assets/fciAssets/aboutus.jpg'
import WhoWeAreImage from 'src/assets/fciAssets/whoweare.jpg'
import MissionImage from 'src/assets/fciAssets/mission.jpg'

const useStyles = makeStyles(() => ({
  aboutUs: {
    paddingTop: '87px'
  },
  list: {
    listStyle: 'none',
    '& > li': {
      display: 'flex',
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  oddSection: {
    backgroundColor: '#f2f2f2',
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#dd0028'
  },
  image: {
    maxWidth: '384px',
    borderRadius: '10px'
  },
  numberIcon: {
    marginRight: 8,
    color: '#dd0028'
  }
}))

function AboutUsView() {
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box className={classes.aboutUs} >
      <Grid container spacing={5} style={{ paddingTop: '20px' }}>

        <Grid item xs={12}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h1' style={{ fontWeight: 'bold', color: '#7F2A3C', textAlign: 'center' }}>About Us</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  Whether you are a budding startup or a well established company in the logistics field, Fineline Crossing will catapult your company to the next level.
                  We offer ACE and ACI eManifest with the needs of the Owner Operator, Carrier and Brokerage in mind.
                  Our software packages, support team and industry expertise will organize your workflow and elevate your company's productivity, saving you precious time and money.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} className={classes.oddSection}>
          <Container>
            <Grid container alignItems='center' >
              <Grid item xs={12} md={6}>
                <Box display='flex' justifyContent='center' paddingRight={10} paddingBottom={2}>
                  <img src={AboutUsImage} className={classes.image} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='h1' style={{ fontWeight: 'bold' }}>Our Origin Story</Typography>
                <Box my={2}>
                  <Box className={classes.divider} />
                </Box>
                <Typography variant='body1'>
                  Fineline Crossing Inc. was founded in 2021 as an off-shoot of our parent company Xpert IT Solutions, in Ontario, Canada.
                  Our humble journey began as a family-owned small business with the launch of our first product Xpert Dispatch, which quickly became our claim to fame and remains a best-seller to this day.
                  Over the last few decades, we have been fortunate enough to expand our services, grow our team, build our client network, and bear the fruits of our tireless dedication.
                  Today, we service thousands of carrier and logistics companies from all over North America out of multiple branch offices.
                  With almost 20 years of experience in the logistics software industry guiding us, we continue to blaze the trail with innovative dispatch solutions.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Container>
            <Grid container alignItems='center' >
              {mdDown &&
                <Grid item xs={12} md={6}>
                  <Box display='flex' justifyContent='center' paddingRight={10} paddingBottom={2}>
                    <img src={WhoWeAreImage} className={classes.image} />
                  </Box>
                </Grid>
              }

              <Grid item xs={12} md={6}>
                <Typography variant='h1' style={{ fontWeight: 'bold' }}>Who We Are</Typography>
                <Box my={2}>
                  <Box className={classes.divider} />
                </Box>
                <Typography variant='body1'>
                  We are a well-rounded team of technical, industry and interpersonal expertise including software developers, engineers, hardware technicians, customer service, support, administration and sales.
                  We prize communication and professionalism internally within our team as well as externally with all interactions.
                </Typography>
              </Grid>

              {!mdDown &&
                <Grid item xs={12} md={6}>
                  <Box display='flex' justifyContent='center' paddingRight={10} paddingBottom={2}>
                    <img src={WhoWeAreImage} className={classes.image} />
                  </Box>
                </Grid>
              }

            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} className={classes.oddSection}>
          <Container>
            <Grid container alignItems='center' >
              <Grid item xs={12} md={6}>
                <Box display='flex' justifyContent='center' paddingRight={10} paddingBottom={2}>
                  <img src={MissionImage} className={classes.image} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant='h1' style={{ fontWeight: 'bold' }}>Our Mission</Typography>
                <Typography variant='subtitile2' style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
                  Excellence is not an exception; it is a prevailing attitude.
                </Typography>
                <Box my={2}>
                  <Box className={classes.divider} />
                </Box>

                <ul className={classes.list}>
                  <li>
                    <span><FontAwesomeIcon icon={faCircle1} size='2xl' className={classes.numberIcon} /></span>
                    <div>
                      First and foremost as a tech company, it is our mission to innovate novel and efficient solutions in order to help clients, like you, streamline their workflow.
                      For this reason, we deliver user friendly and cost effective software and hardware optimized for security, high performance as well as reliability.
                    </div>
                  </li>
                  <li>
                    <span><FontAwesomeIcon icon={faCircle2} size='2xl' className={classes.numberIcon} /></span>
                    <div>
                      Second, we highly value interactions with our clients and strive to make their experience with Xpert one of high satisfaction.
                      To achieve this, we offer <b>24/7</b> support and free training in multiple languages <b>(English, Hindi, Punjabi)</b> from a team of expert personnel.
                    </div>
                  </li>
                  <li>
                    <span><FontAwesomeIcon icon={faCircle3} size='2xl' className={classes.numberIcon} /></span>
                    <div>
                      Finally, it is also our mission to operate under a principled value system of integrity, respect and transparency not only with our clients and within our workspace, but also with our community at large.
                    </div>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Grid>

      </Grid>
    </Box>
  )
}

export default AboutUsView
