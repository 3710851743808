import makeValidationSchema from './ClientCodeMakeValidationSchema'
import { components } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form';

const {
  form: {
    field: { CngTextField, CngSelectField }
  }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  clientCode: '',
  module: 'SBCI'
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index }) {

  const { watch } = useFormContext()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number'
      ? `userClientCodes.${index}.${field}`
      : field
  }, [])

  const module = watch(getFieldName('module'))

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CngTextField
          name={getFieldName('clientCode')}
          label='Client Code'
          size='small'
          isRequired={module!="AES"}
        />
      </Grid>
      {module == 'AES' && (
        <Grid item xs={12}>
          <CngTextField
            name={getFieldName('carrierCode')}
            label='Carrier Code'
            size='small'
            inputProps={{ maxLength: 4 }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CngSelectField
          label='Module'
          name={getFieldName('module')}
          size='small'
          items={[
            { text: 'SBCI', value: 'SBCI' },
            { text: 'FDA_PN', value: 'FDA_PN' },
            { text: 'AES', value: 'AES' }
          ]}
        />
      </Grid>
    </Grid>
  )
}

const ClientCodeFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default ClientCodeFormProperties
