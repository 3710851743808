import makeValidationSchema from './UserConfigurationMakeValidationSchema'
import { components } from 'cng-web-lib'
import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'

const {
  form: {
    field: { CngTextField, CngSelectField }
  }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  mailBoxID: '',
  module: 'ACEHW'
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index }) {
  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `userConfig.${index}.${field}` : field
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CngTextField
          name={getFieldName('mailBoxID')}
          label='Mail Box ID'
          isRequired
          size='small'
        />
      </Grid>
      <Grid item xs={12}>
        <CngSelectField
          label='Module'
          name={getFieldName('module')}
          size='small'
          isRequired
          items={[
            { text: 'ACI Air', value: 'ACIAIR' },
            { text: 'ACI Ocean', value: 'ACIOCN' },
            /*{ text: 'Doc Generation', value: 'DOCGEN' },
            { text: 'ACE Highway', value: 'ACEHW' },*/
            { text: 'ACI Highway', value: 'ACIHW' },
            { text: 'eHBL', value: 'EHBL' }
          ]}
        />
      </Grid>
    </Grid>
  )
}

const UserConfigurationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default UserConfigurationFormProperties
