import Page from 'src/views/staticHomeView/ContactView/Page'
import React from 'react'
import { Box, Card, Container, Grid, Typography } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import FormProperties from './RegisterFormProperties'

const {
  form: { CngForm }
} = components

const { FormState } = constants

function RegisterView() {
  return (
    <Page title='Register'>
      <Container maxWidth='md'>
        <Box py={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mb={4}>
                <Typography variant='h1' style={{ fontWeight: 800 }}>
                  Submit Your UPS Registration Information
                </Typography>
              </Box>
              <Box>
                <CngForm
                  formikProps={FormProperties.formikProps}
                  formState={FormState.COMPLETED}
                  renderBodySection={(labelMap, shouldHideMap) => (
                    <Card>
                      <Box padding={2}>
                        <FormProperties.Fields shouldHideMap={shouldHideMap} />
                      </Box>
                    </Card>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default RegisterView
