import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import CngField from '../../../components/cngcomponents/CngField'
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'
import UserProfileApiUrls from '../../../apiUrls/UserProfileApiUrls'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'

const {
  button: { CngButton },
  dropzone: { CngFileUpload }
} = components

const useStyles = makeStyles(() => ({
  body: {
    position: 'relative'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff80',
    zIndex: 2
  }
}))

function SignatureSection(props) {
  const { showNotification } = props

  const { watch, getValues, setValue, reset } = useFormContext()
  const { securedSendRequest } = useServices()
  const classes = useStyles()
  const [isUploading, setIsUploading] = useState(false)
  const uploadedFile = watch('userSignFile')?.[0]

  function handleSelectFile(data) {
    setIsUploading(true)

    const formData = new FormData()
    const fileForUserDetails = FileForUserGetUserDetails()

    formData.append(
      'fileForUserDetails',
      fileForUserDetails
        ? JSON.stringify({
            fileForUserId: fileForUserDetails.fileForUserId,
            fileForUserPartyId: fileForUserDetails.fileForUserPartyId,
            fileForUserLoginId: fileForUserDetails.fileForUserLoginId
          })
        : '{}'
    )

    formData.append('file', data[0].file)

    securedSendRequest.execute(
      'POST',
      UserProfileApiUrls.UPLOAD_SIGN_FILE,
      formData,
      (response) => {
        const { userSignFile } = response.data

        if (userSignFile.errorMessages && !_.isEmpty(userSignFile.errorMessages)) {
          showNotification('error', userSignFile.errorMessages)
        } else {
          reset({ ...getValues(), userSignFile })

          showNotification('success', 'Upload Sign file Successful.')
        }
      },
      (error) => console.error(error),
      () => setIsUploading(false),
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  return (
    <CngSection title='Signature'>
      <Box className={classes.body}>
        {isUploading && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CngFileUpload
              accept={['.jpeg', '.jpg', '.png']}
              files={[]}
              maxFiles={1}
              maxSize={6291456}
              onFileSelect={handleSelectFile}
              moreActions={[]}
              renderRow={() => <></>}
            />
          </Grid>
          {uploadedFile && (
            <Grid item xs={12}>
              <CngSection
                title='Uploaded Signature'
                action={
                  <CngButton
                    color='secondary'
                    onClick={() => setValue('userSignFile', [])}
                    size='small'
                  >
                    Remove
                  </CngButton>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <CngField label='File Name'>
                      {uploadedFile.fileName}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <CngField label='File Size'>
                      {uploadedFile.fileSize}
                    </CngField>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <CngField label='File Extension'>
                      {uploadedFile.fileExt}
                    </CngField>
                  </Grid>
                  <Grid item xs={12}>
                    <CngField label='File Path'>
                      {uploadedFile.filePath}
                    </CngField>
                  </Grid>
                </Grid>
              </CngSection>
            </Grid>
          )}
        </Grid>
      </Box>
    </CngSection>
  )
}

export default SignatureSection
