import { Card, CardContent, Grid, Paper } from '@material-ui/core'
import React,{useState} from 'react'; 
//import CardHeader from "components/Card/CardHeader.js"; 
import RegularButton from "src/components/CustomButton.js";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import sweetalert from 'sweetalert'
import {
   components,
   useServices
 } from 'cng-web-lib';
import A6RESPONSE from 'src/apiUrls/A6ResponseApiUrls.js'
import ARRIVALRESPONSE from 'src/apiUrls/ArrivalResponseApiUrls.js'
import ACIAIRRESPONSE from 'src/apiUrls/AciAirResponseApiUrls.js'

var cardStyle = {
  //display: 'block',
  //width: '81vw',
  //transitionDuration: '0.3s',
 height: '41vw'
}
const{
  card: {
     CngSimpleCardHeader,
  },
   CngGridItem,
}=components

function UploadResponse(){ 
  
  const {securedSendRequest } = useServices();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState({});
  const [dropdownvalue, setDropdownvalue] = useState('select');

  const handleChange = (event) => {
    setDropdownvalue(event.target.value);
  };

       // On file select (from the pop up) 
  const onFileChange = event => { 
    
    console.log("file")
    // Update the state 
    setSelectedFile(event.target.files[0]); 
    var file = event.target.files[0];
    var reader = new FileReader();
    reader.onload = function(event) {
      // The file's text will be printed here
      setFileData(JSON.parse(event.target.result))
    };
  
    reader.readAsText(file);
    
  }; 
   
  // On file upload (click the upload button) 
  const onFileUpload = () => { 
    
    var url='';
    if(dropdownvalue != null &&dropdownvalue != 'select') 
    {
    if(dropdownvalue=='AA'){
      url=ACIAIRRESPONSE.POST;
    }
    else if(dropdownvalue=='AAD4'){
        url=ACIAIRRESPONSE.D4_POST;
    }
    else if(dropdownvalue=='AO'){
       url=A6RESPONSE.POST;
    }
    else if(dropdownvalue=='OAM')
    {
      url=ARRIVALRESPONSE.POST; 
    }
    else if(dropdownvalue=='AAM')
    {
      url=ARRIVALRESPONSE.POST;
    }

    function onSuccess(response) {
      console.log(response)
      sweetalert("Response Submitted","" ,"success");
    }
  
    function onError(error) {
      //showNotification("error", errorMsg)
      sweetalert("Error while uploading response file","","error")
    }
  
    function onComplete(){
  
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  
    securedSendRequest.execute('POST',url, fileData, onSuccess, onError, onComplete,config,null);
    /*axios.interceptors.request.use(function (config) {
 
      if(localStorage.getItem("userToken") != null || localStorage.getItem("userToken") != undefined)
      {
        const token = JSON.parse(localStorage.getItem("userToken"));
        config.headers.Authorization =  "Bearer "+ token;
      }
        else {
          console.log("Session expired!")
          config.headers.Authorization =  null;
         }
      return config;
    });
    
    axios.post(url, this.state.fileData,{
      headers: {
          'Content-Type': 'application/json',
      }
     }).then((response) => {
      // Success
      sweetalert("Response Submitted","" ,"success");

    })
     .catch((error) => {
     
    sweetalert("Error while uploading response file","","error")
    
      })  */
    }
    else{
      sweetalert("Please select message type!")
    } 
    
  }
   
  // File content to be displayed after 
  // file upload is complete 
  const displayFileData = () => { 
   
    if (selectedFile) { 
        
      return ( 
        <div> 
          <br />
          <h3>File Details:</h3> 
          <br />
          <p>File Name: {selectedFile.name}</p> 
          <br />
          <p>File Type: {selectedFile.type}</p> 
          <br />
          <p> 
            Last Modified:{" "} 
            {selectedFile.lastModifiedDate.toDateString()} 
          </p> 
        </div> 
      ); 
    } else { 
      return ( 
        <div> 
          <br /> 
          <br />
          <h4>Choose file to upload </h4> 
        </div> 

      ); 
    } 
  }; 
   
    return ( 
      <Card style={cardStyle}>
        <CngSimpleCardHeader title="Upload Response File"/>
        <CardContent>
        <Grid container spacing={3}>
        <Grid item xs={12}>
              <CngGridItem xs={12} sm={6}>
                 <InputLabel id="messagetype">Message Type</InputLabel>
                 <Select
                      labelId="message type"
                      id="rtype"
                      value={dropdownvalue}
                      onChange={handleChange}
                      style={{width:'50%'}}
                  >
                 <MenuItem value="AA">ACI Air</MenuItem>
                 <MenuItem value="AAD4">ACI Air - D4 Notice</MenuItem>
                 <MenuItem value="AO">ACI Ocean</MenuItem>
                 <MenuItem value="OAM">Ocean Arrival Message</MenuItem>
                 <MenuItem value="AAM">Air Arrival Message</MenuItem>
                </Select>
             </CngGridItem>
             <div>
             <span></span>
             <br></br>
             </div>
             <div>
             <span></span>
             <br></br>
             </div>
             <Grid item xs={12}>
              <Grid container>
                <CngGridItem xs={12} sm={4}>
                <RegularButton>
                  <input type="file"accept=".json" color="primary" onChange={onFileChange}/>
                </RegularButton>
                </CngGridItem>
                <CngGridItem xs={6} sm={3}>
                <RegularButton  type="submit" color="primary" onClick={onFileUpload}
                  disabled={!selectedFile} >
                  Upload!
                </RegularButton>
                </CngGridItem>
              </Grid> 
              </Grid>
                {displayFileData()} 
        </Grid>
        </Grid>  
        </CardContent>
        </Card>
    ); 
} 

export default UploadResponse; 