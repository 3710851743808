import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    mailBoxID: Yup.string()
      .max(10, 'User Configuration: MailBox ID cannot be more than 10 characters.')
      .matches(
        /^[a-z0-9]+$/i,
        'User Configuration: MailBox ID is in invalid format. Only alphanumeric values allowed.'
      )
      .required('User Configuration: MailBox ID is required.'),
    module: Yup.string().required('User Configuration: Module is required.')
  })
}

export default makeValidationSchema
