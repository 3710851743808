import React, { useEffect, useState } from 'react'
import { constants, useServices } from 'cng-web-lib'
import NaCodeMaintenanceApiURLs from '../../../apiUrls/NaCodeMaintenanceApiUrls'

const {
  CodeMaintenanceType,
  filter: { EQUAL }
} = constants

const StatusNotificationContext = React.createContext()

const StatusNotificationConsumer = StatusNotificationContext.Consumer

function StatusNotificationProvider({ children }) {
  const [aceSnTypeOptions, setAceSnTypeOptions] = useState([])
  const [aciSnTypeOptions, setAciSnTypeOptions] = useState([])
  const [nbciSnTypeOptions, setNbciSnTypeOptions] = useState([])
  const [aceOcnSnTypeOptions, setAceOcnSnTypeOptions] = useState([])
  const [aceIsfSnTypeOptions, setAceIsfSnTypeOptions] = useState([])
  const { fetchRecords } = useServices()

  useEffect(() => {
    Promise.all([
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACEHW_STATUS_NOTIFI_TYPE'
            }
          ]
        },
        (response) =>
          response.content.map((snType) => ({
            value: snType.code,
            text: snType.descriptionEn
          }))
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACIHW_STATUS_NOTIFI_TYPE'
            }
          ]
        },
        (response) =>
          response.content.map((snType) => ({
            value: snType.code,
            text: snType.descriptionEn
          }))
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'NBCI_STATUS_NOTIFICATION'
            }
          ]
        },
        (response) =>
          response.content.map((snType) => ({
            value: snType.code,
            text: snType.descriptionEn
          }))
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACEOCN_STATUS_NOTIFICATION'
            }
          ]
        },
        (response) =>
          response.content.map((snType) => ({
            value: snType.code,
            text: snType.descriptionEn
          }))
      ),
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: CodeMaintenanceType.CODE_MASTER },
          filters: [
            { field: 'active', operator: EQUAL, value: 'true' },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACEISF_STATUS_NOTIFICATION'
            }
          ]
        },
        (response) =>
          response.content.map((snType) => ({
            value: snType.code,
            text: snType.descriptionEn
          }))
      )
    ]).then(([aceSnType, aciSnType, nbciSnType, aceOcnSnType, aceIsfSnType]) => {
      setAceSnTypeOptions(aceSnType)
      setAciSnTypeOptions(aciSnType)
      setNbciSnTypeOptions(nbciSnType)
      setAceOcnSnTypeOptions(aceOcnSnType)
      setAceIsfSnTypeOptions(aceIsfSnType)
    })
  }, [])

  function getOptions(destination) {
    switch (destination) {
      case 'CBP':
        return aceSnTypeOptions
      case 'CBSA':
        return aciSnTypeOptions
      case 'NBCI':
        return nbciSnTypeOptions
      case 'ACEOCN':
        return aceOcnSnTypeOptions
      case 'ACEISF':
        return aceIsfSnTypeOptions
    }
  }

  return (
    <StatusNotificationContext.Provider value={{ getOptions }}>
      {children}
    </StatusNotificationContext.Provider>
  )
}

export default StatusNotificationProvider

export { StatusNotificationContext, StatusNotificationConsumer }
