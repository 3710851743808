import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core'

import HomeJumboBg from 'src/assets/img/hero/home-jumbotron-bg.png'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import clsx from 'clsx'
import { toPublicUrl } from 'src/views/staticHomeView/HomeView/urlUtil.js'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    backgroundColor: theme.palette.common.white,
    paddingTop: 80, //Old Value: 200
    paddingBottom: 80, //Old Value: 200
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    },
    '& .section-preheader': {
      color: theme.palette.primary.main,
      fontSize: '0.75rem !important',
      fontWeight: '700'
    },
    '& .section-header': {
      fontSize: '2.1422rem !important'
    },
    '& .section-text': {
      color: '#1C1D2157',
      fontSize: '1rem !important'
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}))

function Hero1({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth='lg'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              height='100%'
            >
              <Typography
                variant='overline'
                color='secondary'
                className={'section-preheader'}
              >
                Introducing 
              </Typography>
              <Typography
                variant='h1'
                color='textPrimary'
                className={'section-header'}
              >
                {/* NextGen */}
                Logistics simplifies cross-border paperwork
              </Typography>
              <Box mt={3}>
                <Typography
                  variant='body1'
                  color='textSecondary'
                  className={'section-text'}
                >
                  {/* Nextgen is initiative to adapt new technologies and design
                  principles to keep up with the changing trends. It includes
                  solutions to well known issues as well as new
                  features/functionalities for enabling products/applications to
                  provide business values */}
Increased security means increased paperwork, especially when moving truck freight across the U.S.-Canadian border.<br />

Web-based GeTS simplifies and speeds preparation and filing of eManifests, commercial invoices and government forms, including NAFTA documentation. Manual data-entry time goes down. Data quality goes up. You maximize efficiency and reduce the costs of U.S. Canada cross-border trade.<br />

This powerful tool enables trade professionals to complete trade documentation with the ability to prepare cargo eManifests and commercial invoices using the same data. You then can submit these forms online to both U.S. Customs and Border Protection (CBP) and the Canada Border Services Agency (CBSA).<br />

This helps ensure compliance with both ACE and ACI programs. For highway carriers, direct submission means they can receive responses back in seconds. And issuance of entry numbers provides added visibility and confidence traveling to the border.<br />

                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box position='relative'>
              {/* <div className={classes.shape}>
                <img
                  alt='Shapes'
                  src={toPublicUrl('/static/home/shapes.svg')}
                />
              </div> */}
              <div className={classes.image}>
                {/* <img alt='Presentation' src='/static/home/dark-light.png' /> */}
                {/* <img alt='NextGen UI' src={HomeJumboBg} /> */}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Hero1.propTypes = {
  className: PropTypes.string
}

export default Hero1
