import UPSHero from './UPSHero'
import Page from 'src/views/staticHomeView/ContactView/Page'
import React from 'react'
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import UPSVehicle from '../../../../assets/img/ups-vehicle.jpg'
import DataIntegration from '../../../../assets/img/DataIntegration.svg'
import CostSavings from '../../../../assets/img/CostSaving.svg'
import TimeSavings from '../../../../assets/img/TimeSaving.svg'
import LowStartUpCosts from '../../../../assets/img/LowStart-upCost.svg'
import { components } from 'cng-web-lib'

const {
  button: { CngButton }
} = components

const useStyles = makeStyles((theme) => ({
  register: {
    backgroundColor: '#f2f1ef',
    borderRadius: 16,
    padding: 48,
    [theme.breakpoints.down('md')]: {
      padding: 24
    }
  },
  button: {
    backgroundColor: '#ffc400',
    borderRadius: 999,
    boxShadow: 'none',
    color: '#121212',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd033',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    },
    '&:focus': {
      backgroundColor: '#ffa200'
    }
  },
  background1: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/Background1.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: 550
  },
  background2: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/Background2.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#ffc400'
  },
  upsVehicle: {
    width: '100%',
    borderRadius: 16
  }
}))

function UPSHomeView() {
  const classes = useStyles()
  const history = useHistory()
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))

  sessionStorage.clear()
  sessionStorage.setItem('homePagePath', '/ups')
  return (
    <Page title='Home'>
      <Box className={classes.background1}>
        <UPSHero />
      </Box>
      <Box pb={6}>
        <Container>
          <Grid alignItems='center' container>
            <Grid item xs={12} lg={6}>
              <Box
                paddingRight={mdDown ? 0 : 10}
                paddingBottom={mdDown ? 6 : 0}
              >
                <img src={UPSVehicle} className={classes.upsVehicle} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant='h1' style={{ fontWeight: 700 }}>
                Manual Data-Entry Time Goes Down. Data Quality Goes Up.
              </Typography>
              <Box my={2}>
                <Box className={classes.divider} />
              </Box>
              <Typography>
                Maximise efficiency and reduce the costs of U.S. Canada
                cross-border trade. This powerful tool enables trade
                professionals to complete trade documentation with the ability
                to prepare cargo eManifests and commercial invoices using the
                same data.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.background2}>
        <Container>
          <Box maxWidth={900} py={6} style={{ margin: '0 auto' }}>
            <Typography
              variant='h1'
              style={{ fontWeight: 700, textAlign: 'center' }}
            >
              Ensures Compliance with Both ACE and ACI Programs
            </Typography>
            <Box my={2}>
              <Box className={classes.divider} style={{ margin: '0 auto' }} />
            </Box>
            <Typography style={{ marginBottom: 8, textAlign: 'center' }}>
              You then can submit these forms online to both U.S. Customs and
              Border Protection (CBP) and the Canada Border Services Agency
              (CBSA). This helps ensure compliance with both ACE and ACI
              programs.
            </Typography>
            <Typography style={{ textAlign: 'center' }}>
              For highway carriers, direct submission means they can receive
              responses back in seconds. And issuance of entry numbers provides
              added visibility and confidence traveling to the border.
            </Typography>
          </Box>
          <Box py={6}>
            <Typography
              variant='h1'
              style={{ fontWeight: 700, textAlign: 'center' }}
            >
              UPS BorderReady Benefits
            </Typography>
            <Box mt={2} mb={5}>
              <Box className={classes.divider} style={{ margin: '0 auto' }} />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Benefit
                  icon={DataIntegration}
                  title='Data Integration'
                  description='Interface with existing in-house dispatch systems, as well as systems of customers, service providers and regulatory bodies.'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Benefit
                  icon={CostSavings}
                  title='Cost Savings'
                  description='Reduce data-entry errors by sharing common data between ACE and ACI, and ACE/ACI and commercial invoice information.'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Benefit
                  icon={TimeSavings}
                  title='Time Savings'
                  description='Access information faster, eliminate data-entry duplication and receive real-time responses to submissions.'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Benefit
                  icon={LowStartUpCosts}
                  title='Low Start-Up Costs'
                  description='Requires only a PC with an internet connection, no installation or purchase of software or IT maintenance necessary.'
                />
              </Grid>
            </Grid>
          </Box>
          <Box pt={2} pb={6}>
            <Box className={classes.register}>
              <Grid alignItems='center' container spacing={3}>
                <Grid item xs={12} md>
                  <Typography variant='h4' style={{ fontWeight: 700 }}>
                    Interested in UPS BorderReady?
                  </Typography>
                </Grid>
                <Grid item xs={12} md='auto'>
                  <CngButton
                    className={classes.button}
                    onClick={() => history.push('/ups-end-user-web-agreement')}
                  >
                    Register now
                  </CngButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Page>
  )
}

function Benefit(props) {
  const { icon, title, description } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img src={icon} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h4' style={{ fontWeight: 700 }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{description}</Typography>
      </Grid>
    </Grid>
  )
}

export default UPSHomeView
