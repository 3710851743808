import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core'

import ContactForm from './ContactForm'
import GoogleMap from 'src/views/staticHomeView/ContactView/GoogleMap'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.sectionOddBg,
    color: theme.palette.text.widgetTitle
  },
  iframe: {
    border: 0,
    height: '70vh'
  },
  header: {
    fontSize: '2rem',
    color: theme.palette.text.widgetTitle,
    fontWeight: 'bold'
  },
  order2: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 3
    }
  },
  order3: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  }
}))

function Contact({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8097696548534!2d103.78001401409188!3d1.2883061621305776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1bacbe400001%3A0xa885dc968949b86e!2sThe%20Crimson!5e0!3m2!1sen!2ssg!4v1608645356193!5m2!1sen!2ssg'
        width='100%'
        frameBorder='0'
        className={classes.iframe}
        allowFullScreen=''
        aria-hidden='false'
        tabIndex='0'
      ></iframe>

      <Container maxWidth='md'>
        <Box pb={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mt={5} mb={-2}>
                <Typography variant='h1' className={classes.header}>
                  Contact us
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6} className={classes.order2}>
              <Box display='flex' className={`align-left`}>
                <GoogleReCaptchaProvider reCaptchaKey='6LcnQhIaAAAAAOynWLOsrSBuZGqaxfHpiKOseysT'>
                  <ContactForm />
                </GoogleReCaptchaProvider>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className={classes.order3}>
              <Typography variant='body2'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat
              </Typography>
              <Box mt={3}>
                <Box fontWeight='700' component='h4' mb={1}>
                  Singapore
                </Box>
                <Typography variant='body2'>
                  31 Science Park Road, Singapore 117611
                </Typography>
                <br />
                <Link href='mailto:email@gets.com'>
                  <Typography variant='body2'>email@gets.com</Typography>
                </Link>
                <Link href='tel:+65 6887 7888'>
                  <Typography variant='body2'>+65 6887 7888</Typography>
                </Link>
              </Box>
              <Box mt={3}>
                <Box fontWeight='700' component='h4' mb={1}>
                  Hours
                </Box>
                <Typography variant='body2'>
                  Monday - Friday &emsp;
                  <b>9:00am - 6:00pm</b>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
}

Contact.propTypes = {
  className: PropTypes.string
}

export default Contact
