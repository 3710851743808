import React from 'react'
import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  link: {
    color: '#0000ee'
  }
}))

function TradeResourcesView() {
  const classes = useStyles()

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Container maxWidth='md'>
      <Box py={6}>
        <Box mb={4}>
          <Typography variant='h1' color='secondary' style={{ fontWeight: 600 }}>
            Trade Resources
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box mb={2}>
              <Typography variant='h4' style={{ fontWeight: 600 }}>Tools</Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Link 
                  className={classes.link}
                  onClick={() => openWindow('http://www.ups.com/ga/MetricConversion?loc=en_US')}>
                  UPS unit of measure conversion tool
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link 
                  className={classes.link}
                  onClick={() => openWindow('https://pars.ups.com/pars/')}>
                  Track your PARS
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link 
                  className={classes.link}
                  onClick={() => openWindow('https://paps.ups.com/paps/')}>
                  Track your PAPS
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box mb={2}>
              <Typography variant='h4' style={{ fontWeight: 600 }}>Regulatory Resources</Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Link 
                  className={classes.link}
                  onClick={() => openWindow('https://www.cbsa-asfc.gc.ca/prog/aci-manif-ipec/menu-eng.html')}>
                  CBSA ACI eManifest
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link 
                  className={classes.link}
                  onClick={() => openWindow('https://www.cbp.gov/trade/automated')}>
                  CBP ACE eManifest
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box mb={2}>
              <Typography variant='h4' style={{ fontWeight: 600 }}>Trade Information</Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Link 
                  className={classes.link}
                  onClick={() => openWindow('https://www.ups.com/us/en/supplychain/resources/news-and-market-updates.page')}>
                  UPS Trade Broadcast
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default TradeResourcesView
