import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useFormContext, useWatch } from 'react-hook-form';
import { components, useServices, constants } from 'cng-web-lib'
import { Grid, Divider, Typography } from '@material-ui/core'
import Switch from 'src/components/Switch'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'

const {
  button: { CngButton },
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngTimeField,
      CngCodeMasterAutocompleteField,
      CngCountryAutocompleteField,
      CngSelectField,
      CngUomAutocompleteField,
      CngSwitchField
    },
  },
  CngGridItem,
} = components

const {
  filter: { LIKE, EQUAL, IN }
} = constants

const defaultValue = {
  id: "",
  importerDocType: "",
  importerRefNo: "",
  cbpBond: true,
  bondType: "",
  bondHolderDocType: "",
  bondHolderDocNo: "",
  bondTerm: "",
  bondTermRefNo: "",
  bondTermSuretyCode: "",
  agentCode: "",
  useDefault: false
}

function ACEISFFilerReferenceSection(props) {

  const { getValues, setValue, watch } = useFormContext()
  const aceIsfFileReference = useWatch({ name: 'aceIsfFileReference' });
  const cbpBondFlag = useWatch({ name: 'aceIsfFileReference.cbpBond' });
  const bondTerm = useWatch({ name: 'aceIsfFileReference.bondTerm' });
  const bondType = useWatch({ name: 'aceIsfFileReference.bondType' });
  const importerDocType = useWatch({ name: 'aceIsfFileReference.importerDocType' });
  const bondHolderDocType = useWatch({ name: 'aceIsfFileReference.bondHolderDocType' });
  const formData = new FormData()
  const fileForUserDetails = FileForUserGetUserDetails()

  useEffect(() => {
    if (!aceIsfFileReference) {
      setValue('aceIsfFileReference', defaultValue)
    }
  }, []);

  formData.append(
    'fileForUserDetails',
    fileForUserDetails
      ? JSON.stringify({
        fileForUserId: fileForUserDetails.fileForUserId,
        fileForUserPartyId: fileForUserDetails.fileForUserPartyId,
        fileForUserLoginId: fileForUserDetails.fileForUserLoginId
      })
      : '{}'
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <CngGridItem xs={12} sm={3}>
                  <CngSelectField
                    label='Importer Document Type'
                    name='aceIsfFileReference.importerDocType'
                    size='small'
                    items={[
                      { text: 'Employer Identification Number (IRS #)', value: 'EI' },
                      { text: 'CBP Number for Importer/Consignee', value: 'ANI' },
                      { text: 'Social Security Number', value: '34' },
                      { text: 'Passport Number', value: 'AEF' },
                    ]}
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3}>
                  <CngTextField
                    name='aceIsfFileReference.importerRefNo'
                    label="Importer Document Number"
                    inputProps={{ maxLength: (importerDocType === 'EI' ? 12 : importerDocType === '34' ? 11 : importerDocType === 'ANI' ? 12 : 15) }}
                    onChange={(e) => {
                      let word = e.target.value.toUpperCase()
                      let word2 = word.substring(4, 11)

                      if (importerDocType === 'EI' && word.length === 3 && !word.includes('-')) {
                        word = word.substring(0, 2) + '-' + word.substring(2, 10)
                      } else if (importerDocType === '34' && word.length === 4 && !word.includes('-')) {
                        word = word.substring(0, 3) + '-' + word.substring(3, 6)
                      } else if (importerDocType === '34' && word2.length === 3 && !word2.includes('-')) {
                        word = word.substring(0, 6) + '-' + word.substring(6, 10)
                      } else if (importerDocType === 'ANI' && word.length === 7 && !word.includes('-')) {
                        word = word.substring(0, 6) + '-' + word.substring(6, 11)
                      }

                      setValue('aceIsfFileReference.importerRefNo', word)
                    }}
                    placeholder={importerDocType === 'EI' ? 'NN-NNNNNNNXX' : importerDocType === '34' ? 'NNN-NN-NNNN' : importerDocType === 'ANI' ? 'YYDDPP-NNNNN' : ''}
                    size='small'
                  />
                </CngGridItem>
                <CngGridItem xs={12} sm={3}>
                  <Switch
                    fullWidth
                    name="aceIsfFileReference.cbpBond"
                    label="Have a Bond With CBP"
                    onChange={(event) => {
                      setValue('aceIsfFileReference.cbpBond', event.target.checked, { shouldDirty: true })
                      setValue('aceIsfFileReference.bondType', "", { shouldDirty: true })
                      setValue('aceIsfFileReference.bondHolderDocType', "", { shouldDirty: true })
                      setValue('aceIsfFileReference.bondHolderDocNo', "", { shouldDirty: true })
                      setValue('aceIsfFileReference.bondTerm', "", { shouldDirty: true })
                    }}
                  />
                </CngGridItem>
                {cbpBondFlag && <>
                  <CngGridItem xs={12} sm={3}>
                    <CngSelectField
                      label='Bond Type'
                      name='aceIsfFileReference.bondType'
                      size='small'
                      items={[
                        { text: 'Importer or Broker', value: '01' },
                        { text: 'Custodian of Bonded Merchandise', value: '02' },
                        { text: 'International Carrier', value: '03' },
                        { text: 'Foreign Trade Zone Operator', value: '04' },
                        { text: 'ISF Bond', value: '16' },
                      ]}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={3}>
                    <CngSelectField
                      label='Bond Holder Document Type'
                      name='aceIsfFileReference.bondHolderDocType'
                      size='small'
                      items={[
                        { text: 'Employer Identification Number (IRS #)', value: 'EI' },
                        { text: 'CBP Number for Importer/Consignee', value: 'ANI' },
                        { text: 'Social Security Number', value: '34' }
                      ]}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={3}>
                    <CngTextField
                      name='aceIsfFileReference.bondHolderDocNo'
                      label="Bond Holder Document Number"
                      size='small'
                      inputProps={{ maxLength: (bondHolderDocType === 'EI' ? 12 : bondHolderDocType === '34' ? 11 : bondHolderDocType === 'ANI' ? 12 : 15) }}
                      onChange={(e) => {
                        let word = e.target.value.toUpperCase()
                        let word2 = word.substring(4, 11)

                        if (bondHolderDocType === 'EI' && word.length === 3 && !word.includes('-')) {
                          word = word.substring(0, 2) + '-' + word.substring(2, 10)
                        } else if (bondHolderDocType === '34' && word.length === 4 && !word.includes('-')) {
                          word = word.substring(0, 3) + '-' + word.substring(3, 6)
                        } else if (bondHolderDocType === '34' && word2.length === 3 && !word2.includes('-')) {
                          word = word.substring(0, 6) + '-' + word.substring(6, 10)
                        } else if (bondHolderDocType === 'ANI' && word.length === 7 && !word.includes('-')) {
                          word = word.substring(0, 6) + '-' + word.substring(6, 11)
                        }

                        setValue('aceIsfFileReference.bondHolderDocNo', word)
                      }}
                      placeholder={bondHolderDocType === 'EI' ? 'NN-NNNNNNNXX' : bondHolderDocType === '34' ? 'NNN-NN-NNNN' : bondHolderDocType === 'ANI' ? 'YYDDPP-NNNNN' : ''}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={3}>
                    <CngSelectField
                      label='Bond Term'
                      name='aceIsfFileReference.bondTerm'
                      size='small'
                      items={[
                        { text: 'Continuous', value: '8' },
                        { text: 'Single Transaction', value: '9' }
                      ]}
                      onChange={(event) => {
                        setValue('aceIsfFileReference.bondTermRefNo', "", { shouldDirty: true })
                        setValue('aceIsfFileReference.bondTermSuretyCode', "", { shouldDirty: true })
                      }}
                    />
                  </CngGridItem>
                  {bondType === '16' && bondTerm === '9' && <>
                    <CngGridItem xs={12} sm={3}>
                      <CngTextField
                        name='aceIsfFileReference.bondTermRefNo'
                        label="Bond Reference Number"
                        inputProps={{ maxLength: 30 }}
                        size='small'
                      />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={3}>
                      <CngTextField
                        required
                        name='aceIsfFileReference.bondTermSuretyCode'
                        label="Surety Code"
                        inputProps={{ maxLength: 30 }}
                        size='small'
                      />
                    </CngGridItem>
                  </>}
                </>}
                <CngGridItem xs={12} sm={3}>
                  <CngTextField
                    name='aceIsfFileReference.agentCode'
                    label="Agent/Filer Code"
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      setValue('agentCode', e.target.value.toUpperCase())
                    }}
                    size='small'
                  />
                </CngGridItem>

                <CngGridItem xs={12} sm={3}>
                  <Switch
                    fullWidth
                    name="aceIsfFileReference.useDefault"
                    label="Use Default Filer Reference"
                    onChange={(event) => {
                      setValue('aceIsfFileReference.useDefault', event.target.checked, { shouldDirty: true })
                    }}
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ACEISFFilerReferenceSection;
