import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import { Divider, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngIconButton },
  form: {
    field: { CngSelectField, CngTextField }
  }
} = components

const defaultValue = {
  filerCode: '',
  portCode: ''
}

function SubLocationSection() {
  const { getValues, setValue, watch } = useFormContext()
  const subLocCodes = watch('subLocCodes') || []
  const lastIndex = useRef(subLocCodes.length)

  function handleAddSubLocation(data) {
    const subLocCodes = [
      ...getValues('subLocCodes'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('subLocCodes', subLocCodes)
  }

  function handleDeleteSubLocation(index) {
    const subLocCodes = [...getValues('subLocCodes')]
    subLocCodes.splice(index, 1)

    setValue('subLocCodes', subLocCodes)
  }

  return (
    <CollapsibleSection
      defaultExpanded={!_.isEmpty(subLocCodes)}
      title='Sub-Location'
    >
      <Grid container justify='flex-end' spacing={1}>
        {_.isEmpty(subLocCodes) ? (
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', 'plus']}
              onClick={() => handleAddSubLocation(defaultValue)}
              size='small'
              type='outlined'
            />
          </Grid>
        ) : (
          subLocCodes.map((location, index) => (
            <React.Fragment key={location._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container justify='flex-end' spacing={1}>
                  <Grid item xs={12} sm>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <CngTextField
                          label='Sub-Location Code'
                          name={`subLocCodes.${index}.subLocCode`}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CngSelectField
                          name={`subLocCodes.${index}.module`}
                          label='Module'
                          size='small'
                          items={[{ text: 'WACM', value: 'WACM' }]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <Grid container spacing={1}>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'plus']}
                          onClick={() => handleAddSubLocation(defaultValue)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'copy']}
                          onClick={() => handleAddSubLocation({ ...location, id: undefined })}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'trash']}
                          onClick={() => handleDeleteSubLocation(index)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {index + 1 < subLocCodes.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

export default SubLocationSection
