import { makeStyles } from '@material-ui/core'
import searchIconBlue from 'src/assets/img/app-icons/ic_search_blue.svg'
import searchIconGray from 'src/assets/img/app-icons/ic_search_gray.svg'

// CNG Custom Select Field Style --- Starts Here
export const cngFormControlStyles = makeStyles((theme) => ({
  root: {
    '& .ng-form-control-label': {
      color: `${theme.palette.component.textFormControl.placeholderText}`,

      '&-filled': {
        color: theme.palette.component.textFormControl.placeholderTextFilled,

        '&:hover': {
          color:
            theme.palette.component.textFormControl.placeholderTextFocusedHover
        }
      },
      '&-filled-focused': {
        color: theme.palette.component.textFormControl.placeholderTextFocused
      },

      '&-error': {
        color: theme.palette.component.textFormControl.placeholderTextError
      }
    },

    '& .ng-form-control': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`,
        borderColor: `${theme.palette.component.textFormControl.hoverBorder} !important`
      },
      '&:active': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      },
      '&:focus': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      }
    },

    '& .ng-input-text': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`,
        borderColor: `${theme.palette.component.textFormControl.hoverBorder} !important`
      },
      '&:active': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      },
      '&:focus': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      }
    },

    '& .Mui-focused:not(.ng-form-control-helper-label):not(.ng-form-control-label):not(.ng-form-control-filled-error)': {
      backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
    },

    '& .dropdown-arrow': {
      color: `${theme.palette.component.textFormControl.normalArrowFill} !important`
    },

    '& svg': {
      color: `${theme.palette.component.textFormControl.normalArrowFill} !important`
    },

    '& .ng-form-control-helper-label': {
      color: `${theme.palette.component.textFormControl.helperText}`,

      '&-error': {
        color: `${theme.palette.component.textFormControl.helperTextError}`
      }
    }
  },
  menuList: {
    backgroundColor: theme.palette.component.textFormControl.dropdownFill,

    '& li': {
      '&:hover': {
        backgroundColor:
          theme.palette.component.textFormControl.dropdownMenuHoverFill
      },

      '&[aria-selected="true"]': {
        backgroundColor:
          theme.palette.component.textFormControl.dropdownMenuSelectedFill
      }
    }
  },
  popover: {
    '&.ng-calendar-control-wrapper': {
      backgroundColor:
        theme.palette.component.textFormControl.calendarControlFill,

      '& .calendar-nav-button': {
        backgroundColor:
          theme.palette.component.textFormControl
            .calendarControlNavBtnNormalFill,

        '&:hover': {
          backgroundColor:
            theme.palette.component.textFormControl
              .calendarControlNavBtnHoverFill
        },

        '& svg': {
          color:
            theme.palette.component.textFormControl
              .calendarControlNavBtnIconFill
        }
      },

      '& .MuiPickersCalendarHeader-dayLabel': {
        color: theme.palette.component.textFormControl.calendarControlDayLabel
      }
    }
  }
}))
// CNG Custom Select Field Style --- Ends Here

// CNG Custom Checkbox Style --- Starts Here
export const cngCheckboxStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.component.cngCheckbox.hoverRippleFill
    },
    '& ~ .ng-form-control-checkbox-label': {
      color: theme.palette.component.cngCheckbox.forLabelText,
      fontSize: 14,
      fontWeight: 400
    }
  },
  icon: {
    borderRadius: 4,
    width: 20,
    height: 20,
    backgroundColor: theme.palette.component.cngCheckbox.normalFill,
    border: `1px ${theme.palette.component.cngCheckbox.normalBorder} solid`,
    '$root.Mui-focusVisible &': {
      outline: 'none',
      backgroundColor: theme.palette.component.cngCheckbox.hoverFill,
      borderColor: theme.palette.component.cngCheckbox.hoverBorder
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.component.cngCheckbox.hoverFill,
      borderColor: theme.palette.component.cngCheckbox.hoverBorder
    },
    'input:disabled ~ &': {
      backgroundColor: theme.palette.component.cngCheckbox.normalDisabledFill,
      borderColor: theme.palette.component.cngCheckbox.normalDisabledBorder
    },
    'input:checked ~ &': {
      backgroundColor: theme.palette.component.cngCheckbox.activeFill
    },
    'input:checked:disabled ~ &': {
      backgroundColor: theme.palette.component.cngCheckbox.activeDisabledFill
    },
    'input:checked:hover ~ &': {
      backgroundColor: theme.palette.component.cngCheckbox.activeFill
    }
  },
  checkedIcon: {
    backgroundColor: 'yellow',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23ffffff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.component.cngCheckbox.hoverFill
    },
    'input:checked:disabled ~ &': {
      opacity: 0.5
    }
  }
}))
// CNG Custom Checkbox Style --- Ends Here

// CNG Custom Chips Style --- Starts Here
export const cngChipsStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.component.chips.normalFill,

    '& .label': {
      color: theme.palette.component.chips.valueText
    },

    '& .avatar': {
      backgroundColor: theme.palette.component.chips.avatarFill,
      borderColor: theme.palette.component.chips.avatarBorder,
      color: theme.palette.component.chips.avatarText,

      '& svg': {
        color: theme.palette.component.chips.avatarIconFill
      }
    },

    '& .action-icon': {
      backgroundColor: theme.palette.component.chips.actionBtnNormalFill,
      color: theme.palette.component.chips.actionBtnIconFill,

      '&:hover': {
        backgroundColor: theme.palette.component.chips.actionBtnHoverFill
      }
    },

    '&.clickable': {
      backgroundColor: theme.palette.component.chips.normalFill,

      '&:hover': {
        backgroundColor: theme.palette.component.chips.clickableHoverFill
      }
    },

    '&.deletable': {
      backgroundColor: theme.palette.component.chips.normalFill,

      '& .action-icon': {
        backgroundColor: 'transparent',

        '&:hover': {
          backgroundColor: theme.palette.component.chips.normalFill
        }
      }
    },

    '&.outlined': {
      backgroundColor: theme.palette.component.chips.outlineChipFill,
      borderColor: theme.palette.component.chips.outlineChipBorder,

      '&.clickable': {
        '&:hover': {
          backgroundColor:
            theme.palette.component.chips.outlineClickableHoverFill
        }
      },

      '&.disabled': {
        backgroundColor: theme.palette.component.chips.outlineChipDisabledFill,
        borderColor: theme.palette.component.chips.outlineChipDisabledBorder
      }
    },

    '&.disabled': {
      backgroundColor: theme.palette.component.chips.disabledFill,

      '& .label': {
        color: theme.palette.component.chips.disabledText
      }
    }
  }
}))
// CNG Custom Chips Style --- Ends Here

// CNG Custom SnackBar Style --- Starts Here
export const cngSnackBarStyles = makeStyles((theme) => ({
  root: {
    color: `${theme.palette.component.snackBar.displayText} !important`,

    '& svg': {
      color: `${theme.palette.component.snackBar.iconFill} !important`
    },

    '& .ng-snackbar-item': {
      color: `${theme.palette.component.snackBar.displayText} !important`
    }
  }
}))
// CNG Custom SnackBar Style --- Ends Here

// CNG Custom Select Field Style --- Starts Here
export const cngTextFormControlStyles = makeStyles((theme) => ({
  root: {
    '& .ng-form-control-label': {
      color: `${theme.palette.component.textFormControl.placeholderText}`,

      '&-filled': {
        color: theme.palette.component.textFormControl.placeholderTextFilled,

        '&:hover': {
          color:
            theme.palette.component.textFormControl.placeholderTextFocusedHover
        }
      },
      '&-filled-focused': {
        color: theme.palette.component.textFormControl.placeholderTextFocused
      },

      '&-error': {
        color: theme.palette.component.textFormControl.placeholderTextError
      }
    },

    '& .ng-form-control': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,

      '&:not(.group-field):hover': {
        backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`,
        borderColor: `${theme.palette.component.textFormControl.hoverBorder} !important`
      },
      '&:active': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      },
      '&:focus': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      }
    },

    '& .ng-input-text': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`,
        borderColor: `${theme.palette.component.textFormControl.hoverBorder} !important`
      },
      '&:active': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      },
      '&:focus': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      }
    },

    '& .Mui-focused:not(.ng-form-control-helper-label):not(.ng-form-control-label):not(.ng-form-control-filled-error)': {
      backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
    },

    '&.autocomplete-filter': {
      backgroundColor: `${theme.palette.component.textFormControl.groupSelectNormalFill} !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.component.textFormControl.groupSelectHoverFill} !important`,
        borderWidth: `0 !important`
      },

      '& .Mui-focused': {
        backgroundColor: `${theme.palette.component.textFormControl.groupSelectActiveFill} !important`,
        borderWidth: `0 !important`
      }
    },

    '& .dropdown-arrow': {
      color: `${theme.palette.component.textFormControl.normalArrowFill} !important`
    },

    '& svg': {
      color: `${theme.palette.component.textFormControl.normalArrowFill} !important`
    },

    '& .ng-form-control-helper-label': {
      color: `${theme.palette.component.textFormControl.helperText}`,

      '&-error': {
        color: `${theme.palette.component.textFormControl.helperTextError}`
      }
    }
  },
  menuList: {
    backgroundColor: theme.palette.component.textFormControl.dropdownFill,

    '& li': {
      '&:hover': {
        backgroundColor:
          theme.palette.component.textFormControl.dropdownMenuHoverFill
      },

      '&[aria-selected="true"]': {
        backgroundColor:
          theme.palette.component.textFormControl.dropdownMenuSelectedFill
      }
    }
  },
  popover: {
    '&.ng-calendar-control-wrapper': {
      backgroundColor:
        theme.palette.component.textFormControl.calendarControlFill,

      '& .calendar-nav-button': {
        backgroundColor:
          theme.palette.component.textFormControl
            .calendarControlNavBtnNormalFill,

        '&:hover': {
          backgroundColor:
            theme.palette.component.textFormControl
              .calendarControlNavBtnHoverFill
        },

        '& svg': {
          color:
            theme.palette.component.textFormControl
              .calendarControlNavBtnIconFill
        }
      },

      '& .MuiPickersCalendarHeader-dayLabel': {
        color: theme.palette.component.textFormControl.calendarControlDayLabel
      }
    }
  }
}))
// CNG Custom Select Field Style --- Ends Here

// CNG Custom Select Field Style --- Starts Here
export const cngSelectStyles = makeStyles((theme) => ({
  selectDropdown: {
    backgroundColor: `${theme.palette.component.dropdown.normalFill} !important`,
    border: `1px ${theme.palette.component.dropdown.normalBorder} solid !important`,

    '&:hover': {
      backgroundColor: `${theme.palette.component.dropdown.hoverFill} !important`
    },
    '&:active': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '&:focus': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '& .Mui-focused': {
      backgroundColor: `${theme.palette.component.dropdown.activeFill} !important`,
      border: `1px ${theme.palette.component.dropdown.activeBorder} solid !important`
    },
    '& .dropdown-arrow': {
      color: `${theme.palette.component.dropdown.normalArrowFill} !important`
    },
    '& .ng-form-control-select-placeholder': {
      color: `${theme.palette.component.dropdown.placeholderText} !important`
    }
  }
}))
// CNG Custom Select Field Style --- Ends Here

// CNG Custom Slider Style --- Starts Here
export const cngSliderStyles = makeStyles((theme) => ({
  root: {
    height: 2,
    padding: '13px 0'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.component.slider.thumbNormalFill,
    borderColor: theme.palette.component.slider.thumbBorder,
    // marginTop: -14,
    // marginLeft: -14,
    // '&:focus, &:hover, &$active': {
    //   backgroundColor: 'red',
    // }

    '&:hover::before': {
      backgroundColor: theme.palette.component.slider.hoverRippleFill,
      borderColor: theme.palette.component.slider.hoverRippleBorder
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      backgroundColor: theme.palette.component.slider.valueFill,
      color: theme.palette.component.slider.valueText
    }
  },
  track: {
    height: 2,
    backgroundColor: theme.palette.component.slider.trackNormalFill
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: theme.palette.component.slider.railNormalFill
  },
  mark: {
    backgroundColor: theme.palette.component.slider.mark,
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: theme.palette.component.slider.markActive
  },
  disabled: {
    '& $rail': {
      backgroundColor: theme.palette.component.slider.railDisabledFill
    },
    '& $track': {
      backgroundColor: theme.palette.component.slider.trackDisabledFill
    },
    '&$thumb': {
      backgroundColor: theme.palette.component.slider.thumbDisabledFill,
      borderColor: theme.palette.component.slider.thumbDisabledBorder
    }
  }
}))
// CNG Custom Slider Style --- Ends Here

// Switch Button iOS Style --- Starts Here
export const cngSwitchStyles = makeStyles((theme) => ({
  root: {
    width: 46,
    height: 26,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&.checked': {
      '& + $track': {
        backgroundColor: theme.palette.component.switch.trackActiveFill
      },
      '&.disabled': {
        '& + $track': {
          borderColor: `transparent`,
          backgroundColor:
            theme.palette.component.switch.trackActiveDisabledFill
        },

        '& $thumb': {
          backgroundColor: theme.palette.component.switch.thumbDisabledFill
        }
      }
    },
    '&.disabled': {
      '& + $track': {
        border: `1px ${theme.palette.component.switch.trackNormalDisabledBorder} solid`,
        backgroundColor: theme.palette.component.switch.trackNormalDisabledFill
      },
      '& $thumb': {
        border: `1px ${theme.palette.component.switch.thumbDisabledBorder} solid`,
        backgroundColor: theme.palette.component.switch.thumbDisabledFill
      }
    }
  },
  thumb: {
    width: 24,
    height: 24,
    border: `1px ${theme.palette.component.switch.thumbBorder} solid`,
    backgroundColor: theme.palette.component.switch.thumbFill
  },
  track: {
    borderRadius: 26 / 2,
    borderColor: `transparent`,
    backgroundColor: theme.palette.component.switch.trackNormalFill,
    opacity: `1 !important`,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))
// Switch Button iOS Style --- Ends Here

// CNG Custom Radio Button Style --- Starts Here
export const cngRadioStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:not(:checked):focus': {
      backgroundColor: theme.palette.component.checkbox.unCheckHoverFill,
      border: `1px ${theme.palette.component.checkbox.unCheckHoverBorder}`
    },
    '&:checked:focus': {
      backgroundColor: theme.palette.component.checkbox.checkHoverFill,
      border: `1px ${theme.palette.component.checkbox.checkHoverBorder}`
    }
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    // boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    backgroundColor: '#eeeeee',
    transition: 'backgroundColor 0.3s ease-in, backgroundImage 0.3s ease-in',
    '$root.Mui-focusVisible &': {
      outline: '2px auto #5e81f4',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      // backgroundColor: '#1C1D211F'
      backgroundColor: theme.palette.component.checkbox.hoverFill
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      backgroundColor: '#F0F0F367'
    }
  },
  checkedIcon: {
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    backgroundColor: '#5e81f4',
    transition: 'backgroundColor 0.3s ease-in, backgroundImage 0.3s ease-in',

    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: 'rgba(80, 108, 201, 1)',
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)'
    }
  }
}))
// CNG Custom Radio Button Style --- Ends Here

// CNG Linear Progress Indicator Style --- Starts Here
export const cngLinearProgStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.component.linearProgress.railFill
  },
  bar: {
    backgroundColor: theme.palette.component.linearProgress.progressBarFill
  }
}))
// CNG Linear Progress Indicator Style --- Ends Here

// CNG Dialog Style --- Starts Here
export const cngDialogStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.component.dialog.fill,
    '& .dialog-header': {
      color: theme.palette.component.dialog.titleText,
      borderBottom: `1px ${theme.palette.component.dialog.headerDivider} solid !important`,

      '& svg': {
        color: theme.palette.component.dialog.closeIconFill
      }
    },
    '& .dialog-body': {
      color: theme.palette.component.dialog.bodyText
    },
    '& .dialog-footer': {}
  }
}))
// CNG Dialog Style --- Ends Here

// CNG Alert Banner Style --- Starts Here
export const cngAlertStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.component.bannerAlert.bannerFill} !important`,

    '& .ng-preceeding-icon': {},
    '& .ng-alert-message': {
      color: `${theme.palette.component.bannerAlert.displayText} !important`
    },
    '& .ng-button-text': {
      color: `${theme.palette.component.bannerAlert.actionTextLink} !important`
    },
    '& .ng-alert-action-icon': {
      '& svg': {
        color: `${theme.palette.component.bannerAlert.actionIconFill} !important`
      }
    }
  }
}))
// CNG Alert Banner Style --- Ends Here

// CNG Custom Auto-Complete Field Style --- Starts Here
export const cngAutoCompleteTextStyles = makeStyles((theme) => ({
  root: {
    '& > input[type="search"], & > input[type="text"]': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`
      }
    },
    '& > fieldset': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.component.textFormControl.hoverFill} !important`,
        borderColor: `${theme.palette.component.textFormControl.hoverBorder} !important`
      },
      '&:active': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      },
      '&:focus': {
        backgroundColor: `${theme.palette.component.textFormControl.activeFill} !important`,
        border: `1px ${theme.palette.component.textFormControl.activeBorder} solid !important`
      }
    }
  }
}))
// CNG Custom Auto-Complete Field Style --- Ends Here

// CNG Custom Auto-Complete Filter Dropdown Field Style --- Starts Here
export const cngGroupFieldStyles = makeStyles((theme) => ({
  root: {
    '&.beside': {
      backgroundColor: `${theme.palette.component.textFormControl.normalFill} !important`,
      border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`,
      borderRadius: 8,
      padding: 1,

      '& .ng-form-control': {
        borderWidth: `0 !important`
      },
      '& fieldset': {
        borderWidth: `0 !important`,
        boxShadow: `none !important`
      }
    }
  }
}))
// CNG Custom Auto-Complete Filter Dropdown Field Style --- Ends Here

// CNG Numeric stepper Style --- Starts Here
export const cngNumericStepperStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'nowrap',
    '& > .MuiButton-outlined': {
      '&.Mui-disabled': {
        border: `1px ${theme.palette.component.numericStepper.normalBorder} solid`
      }
    },
    '& > .MuiButton-outlinedPrimary': {
      borderColor: theme.palette.component.textFormControl.normalBorder,

      '&:hover': {
        // border: `1px ${theme.palette.component.textFormControl.normalBorder} solid !important`
      }
    },
    '& > .MuiTextField-root': {
      '& > .ng-form-control': {
        '&.ng-form-control-filled': {
          borderColor: theme.palette.component.numericStepper.normalBorder,
          '&:hover': {
            borderColor: `${theme.palette.component.numericStepper.hoverBorder} !important`
          },
          '& > .MuiInputBase-input': {
            color: theme.palette.component.numericStepper.valueText
          }
        }
      }
    }
  }
}))
// CNG Numeric stepper Style --- Ends Here

// CNG List Style --- Starts Here
export const cngListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'inherit',
    display: 'flex',
    flexDirection: 'column',

    '& svg': {
      color: `${theme.palette.component.cngList.textFormControl.adornmentIcon} !important`
    },
    '& input': {
      backgroundImage: `url(${searchIconGray})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '10px center',
      backgroundSize: '20px 20px',
      paddingLeft: '42px !important',

      '&:focus': {
        backgroundImage: `url(${searchIconBlue})`
      }
    }
  },
  filterContainer: {
    height: '57px'
  },
  listContainer: {
    height: 'calc(100vh - 57px)',
    paddingTop: '16px'
  },
  listSection: {
    backgroundColor: 'inherit',
    height: '100%',
    flex: 1,
    padding: 0
  }
}))
// CNG List Style --- Ends Here

// Code Maintenance Style --- Starts Here
export const cngCodeMaintenanceStyles = makeStyles((theme) => ({
  root: {},
  codeListContainer: {
    borderRight: `1px solid ${theme.palette.component.paper.borderColor}`,
    paddingRight: '0 !important'
  },
  maintenanceContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: 370,
    height: 'auto'
  }
}))
// Code Maintenance Style --- Ends Here

// CNG Button Style --- Starts Here
export const cngButtonStyles = makeStyles((theme) => ({
  root: {
    '&.ng-button-outline': {
      backgroundColor: theme.palette.component.cngButton.outline.normalFill,
      borderColor: theme.palette.component.cngButton.outline.border,

      '&.neutral': {
        color: theme.palette.component.cngButton.outline.positiveFaceColor
      },
      '&.negative': {
        color: theme.palette.component.cngButton.outline.negativeFaceColor
      },
      '&:disabed': {
        backgroundColor: theme.palette.component.cngButton.outline.disabledFill,
        color: theme.palette.component.cngButton.outline.disabledFaceColor
      },
      '&:hover': {
        backgroundColor: theme.palette.component.cngButton.outline.hoverFill
      },
      '&:active': {
        backgroundColor: theme.palette.component.cngButton.outline.activeFill
      },
      '&:focus': {
        backgroundColor: theme.palette.component.cngButton.outline.activeFill
      },
      '&:disabled': {
        backgroundColor: theme.palette.component.cngButton.outline.disabledFill,
        color: theme.palette.component.cngButton.outline.disabledFaceColor
      }
    },
    '&.ng-button-func': {
      '&.primary': {
        backgroundColor:
          theme.palette.component.cngButton.function.primaryNormalFill,
        borderColor: theme.palette.component.cngButton.function.outlineBorder,
        borderStyle: 'solid',
        borderWidth: '1px',
        // boxShadow: '0 0 1px 0 rgba(94, 129, 244, 0.32), 0 2px 2px -2px rgba(94, 129, 244, 0.25) !important',
        color: theme.palette.component.cngButton.function.primaryFaceColor,

        '&:hover': {
          backgroundColor:
            theme.palette.component.cngButton.function.primaryHoverFill
        },
        '&:active': {
          backgroundColor:
            theme.palette.component.cngButton.function.primaryActiveFill
        },
        '&:focus': {
          backgroundColor:
            theme.palette.component.cngButton.function.primaryActiveFill
        },
        '&:disabled': {
          backgroundColor:
            theme.palette.component.cngButton.function.primaryDisabledFill,
          boxShadow: 'none !important',
          color: theme.palette.component.cngButton.function.disabledFaceColor
        }
      },
      '&.secondary': {
        backgroundColor:
          theme.palette.component.cngButton.function.secondaryNormalFill,
        borderColor: theme.palette.component.cngButton.function.outlineBorder,
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow:
          '0 0 1px 0 rgba(94, 129, 244, 0.32), 0 2px 2px -2px rgba(94, 129, 244, 0.25) !important',
        color: theme.palette.component.cngButton.function.secondaryFaceColor,

        '&:hover': {
          backgroundColor:
            theme.palette.component.cngButton.function.secondaryHoverFill
        },
        '&:active': {
          backgroundColor:
            theme.palette.component.cngButton.function.secondaryActiveFill
        },
        '&:focus': {
          backgroundColor:
            theme.palette.component.cngButton.function.secondaryActiveFill
        },
        '&:disabled': {
          backgroundColor:
            theme.palette.component.cngButton.function.secondaryDisabledFill,
          boxShadow: 'none !important',
          color: theme.palette.component.cngButton.function.disabledFaceColor
        }
      },
      '&.outline': {
        backgroundColor:
          theme.palette.component.cngButton.function.outlineNormalFill,
        borderColor: theme.palette.component.cngButton.function.outlineBorder,
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow:
          '0 0 1px 0 rgba(94, 129, 244, 0.32), 0 2px 2px -2px rgba(94, 129, 244, 0.25) !important',
        color: theme.palette.component.cngButton.function.outlineFaceColor,

        '&:hover': {
          backgroundColor:
            theme.palette.component.cngButton.function.outlineHoverFill
        },
        '&:active': {
          backgroundColor:
            theme.palette.component.cngButton.function.outlineActiveFill
        },
        '&:focus': {
          backgroundColor:
            theme.palette.component.cngButton.function.outlineActiveFill
        },
        '&:disabled': {
          backgroundColor:
            theme.palette.component.cngButton.function.outlineDisabledFill,
          boxShadow: 'none !important',
          color: theme.palette.component.cngButton.function.disabledFaceColor
        }
      },
      '&.ic-sm': {
        borderRadius: '8px !important',

        '& .fa-sm': {
          fontSize: '24px',
          padding: '4px'
        }
      }
    }
  }
}))
// CNG Button Style --- Ends Here
