import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    scacCode: Yup.string().when('module', {
      is: (module) => module === 'ACIHW' || module === 'EHBL' || module === 'ACIAIR',
      then: Yup.string().matches(/^[a-z0-9\-]+$/i,
        'SCAC: SCAC / Carrier Code is in invalid format. Only alpha characters and - allowed.'
      ),
      otherwise: Yup.string().matches(/^[a-z0-9]+$/i,
        'SCAC: SCAC / Carrier Code is in invalid format. Only alpha characters allowed.'
      )
    })
      .max(4, 'SCAC: SCAC / Carrier Code cannot be more than 4 characters.')
      .required('SCAC: SCAC / Carrier Code is required.'),
    module: Yup.string().required('SCAC: Module is required.')
  })
}

export default makeValidationSchema
