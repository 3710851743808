import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Snackbar,
  TextField,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Yup } from 'cng-web-lib'
import classNames from 'classnames'
import clsx from 'clsx'
import { cngFormControlStyles, cngCheckboxStyles } from 'src/views/staticHomeView/theme/forms'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const useStyles = makeStyles(() => ({
  root: { width: '100%' },
  underline: {
    borderRadius: '4px',
    borderColor: '#EEEEEE',
    borderWidth: '1px',
    borderStyle: 'solid',
    backgroundColor: '#5E81F405 !important',

    '&:hover': {
      backgroundColor: '#5E81F40A !important'
    },
    '&&&:before, &&:after': {
      borderBottom: 'none'
    }
  },
  submitBtn: {
    padding: '10px 45px'
  }
}))

const initialValues = {
  fullName: '',
  email: '',
  message: ''
}

const validationSchema = Yup.object({
  fullName: Yup.string().max(255).required('Please enter your name'),
  email: Yup.string().email().required('Email is required'),
  message: Yup.string().min(10).required('Please enter a message')
})

const alertInfo = {
  success: {
    type: 'success',
    message: 'Message sent!'
  },
  error: {
    type: 'error',
    message: 'An error occurred while trying to send your messsage.'
  }
}

function ContactForm({
  className,
  onSubmitSuccess,
  googleReCaptchaProps,
  ...rest
}) {
  const classes = useStyles()
  const theme = useTheme()
  const textFormControlCSS = cngFormControlStyles()
  const checkboxCSS = cngCheckboxStyles()
  const [isCaptchaVerified, setCaptchaVerified] = React.useState(false)
  const [token, setToken] = React.useState('')

  const [alertType, setAlertType] = React.useState(null)
  const [alertMsg, setAlertMsg] = React.useState('Message sent!')

  React.useEffect(() => {
    async function doVerify() {
      const token = await googleReCaptchaProps.executeRecaptcha('homepage')
      setToken(token)
    }

    doVerify()
  }, [])

  function onSubmit(values, { setErrors, setStatus, setSubmitting }) {
    // Add data loader for server-side processing
    document.body.classList.add(classNames('please-wait'))

    setTimeout(() => {
      setStatus({ success: true })
      setErrors({ submit: 'Not allowed' })

      setSubmitting(false)

      // remove data loader after server-side processing
      document.body.className = ''
      setAlertType('success')
    }, 1000)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.fullName && errors.fullName)}
            fullWidth
            helperText={touched.fullName && errors.fullName}
            label='Full name'
            margin='dense'
            name='fullName'
            onBlur={handleBlur}
            onChange={handleChange}
            size='small'
            value={values.fullName}
            variant='filled'
            className={classNames(
              'ng-form-control-textinput',
              textFormControlCSS.root
            )}
            InputProps={{
              classes: {
                root: 'ng-form-control',
                underline: 'ng-form-control-filled',
                focused: 'ng-form-control-filled-focused',
                error: 'ng-form-control-filled-error'
              }
            }}
            InputLabelProps={{
              classes: {
                root: 'ng-form-control-label required',
                focused: 'ng-form-control-label-filled-focused',
                error: 'ng-form-control-label-error'
              }
            }}
            FormHelperTextProps={{
              classes: {
                root: 'ng-form-control-helper-label',
                error: 'ng-form-control-helper-label-error'
              }
            }}
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label='Email'
            margin='dense'
            name='email'
            onBlur={handleBlur}
            onChange={handleChange}
            size='small'
            value={values.email}
            variant='filled'
            className={classNames(
              'ng-form-control-textinput',
              textFormControlCSS.root
            )}
            InputProps={{
              classes: {
                root: 'ng-form-control',
                underline: 'ng-form-control-filled',
                focused: 'ng-form-control-filled-focused',
                error: 'ng-form-control-filled-error'
              }
            }}
            InputLabelProps={{
              classes: {
                root: 'ng-form-control-label required',
                focused: 'ng-form-control-label-filled-focused',
                error: 'ng-form-control-label-error'
              }
            }}
            FormHelperTextProps={{
              classes: {
                root: 'ng-form-control-helper-label',
                error: 'ng-form-control-helper-label-error'
              }
            }}
          />
          <TextField
            error={Boolean(touched.message && errors.message)}
            fullWidth
            multiline
            rows={5}
            rowsMax={7}
            helperText={touched.message && errors.message}
            label='Message'
            margin='dense'
            name='message'
            onBlur={handleBlur}
            onChange={handleChange}
            size='small'
            value={values.message}
            variant='filled'
            className={classNames(
              'ng-form-control-textinput',
              textFormControlCSS.root
            )}
            InputProps={{
              classes: {
                root: 'ng-form-control',
                underline: 'ng-form-control-filled',
                focused: 'ng-form-control-filled-focused',
                error: 'ng-form-control-filled-error'
              }
            }}
            InputLabelProps={{
              classes: {
                root: 'ng-form-control-label required',
                focused: 'ng-form-control-label-filled-focused',
                error: 'ng-form-control-label-error'
              }
            }}
            FormHelperTextProps={{
              classes: {
                root: 'ng-form-control-helper-label',
                error: 'ng-form-control-helper-label-error'
              }
            }}
          />

          <Snackbar
            open={alertType}
            autoHideDuration={6000}
            onClose={() => setAlertType(null)}
          >
            <Alert onClose={() => setAlertType(null)} severity={alertType}>
              {alertMsg}
            </Alert>
          </Snackbar>

          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button
              disabled={isSubmitting && !isCaptchaVerified}
              //fullWidth={useMediaQuery(theme.breakpoints.down('sm'))}
              type='submit'
              variant='contained'
              classes={{
                root: classNames('ng-button-filled-primary', classes.submitBtn)
              }}
            >
              Send
            </Button>
            {errors.submit && (
              <Box mt={1}>
                <FormHelperText error style={{ textAlign: 'center' }}>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Formik>
  )
}

ContactForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
}

ContactForm.defaultProps = {
  onSubmitSuccess: () => {}
}

export default withGoogleReCaptcha(ContactForm)
