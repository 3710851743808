import React from 'react';
import { useEffect } from 'react';


//Zoho Sales Iq Script:
const useScript = (url, widgetCode) => {
    useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute("type", "text/javascript");

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";
    $zoho.salesiq.ready = function(embedinfo)
    {
	   $zoho.salesiq.floatbutton.coin.hidetooltip();
       $zoho.salesiq.customfield.add(
       {
          "name":"User ID",
          "hint":"User ID(Required)",
          "required":"true",
          "visibility":"both",
       });
    }`
    
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
    document.body.removeChild(script);
    }
}, [url]);
};

export default function ZohoLiveChat() {
    return (
        <React.Fragment>
            {useScript('https://salesiq.zoho.com/widget', '6f946a06368b06d5a6ee034c7134dffc94096bdfdfefe45d24d4cc1244eb24581a2010ab7b6727677d37b27582c0e9c4')}
        </React.Fragment>
    );
}