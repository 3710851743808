import React from 'react'
import {
  Box, Container, Grid, Typography, Divider,
  Link, List, ListItem, ListItemIcon, ListItemText,
  makeStyles, useMediaQuery
} from '@material-ui/core'
import { faEnvelope, faPhone, faLocationDot, faGlobe } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactUsBanner from 'src/assets/fciAssets/TruckSunsetCaption_contact.png'

const useStyles = makeStyles(() => ({
  page: {
    backgroundColor: '#ffffff',
    color: '#121212',
    paddingTop: '87px',
    '& .MuiTypography-body1': {
      fontSize: 16
    }
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#dd0028'
  },
  contactUsBanner: {
    width: '100%',
    maxWidth: '800px',
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function ContactUsView() {
  const classes = useStyles()
  const showBanner = useMediaQuery('(min-height:824px)')

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Box className={classes.page} style={{ paddingTop: '87px' }}>
      <Box py={6}>
        <Container>
          <Grid container spacing={2}>
            {showBanner &&
              <Grid item xs={12} justify='center' style={{ display: 'flex' }}>
                <img src={ContactUsBanner} className={classes.contactUsBanner} />
              </Grid>
            }

            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700, textAlign: 'center' }}>
                Fineline Crossing Support
              </Typography>
              <Box my={2}>
                <Box className={classes.divider} mx='auto' />
              </Box>
              <Typography style={{ textAlign: 'center' }}>
                Need help? Get in touch with us.
              </Typography>
            </Grid>

            <Grid item xs={12} >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center'>
                    <List disablePadding>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faPhone} />
                        </ListItemIcon>
                        <ListItemText primary='(905) 624-5959' primaryTypographyProps={{ variant: 'body1' }} />
                      </ListItem>

                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                        </ListItemIcon>
                        <Link className={classes.link} onClick={() => openWindow('mailto:support@xpertdispatch.com')}>
                          <ListItemText primary='support@xpertdispatch.com' primaryTypographyProps={{ variant: 'body1' }} />
                        </Link>
                      </ListItem>

                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faGlobe} />
                        </ListItemIcon>
                        <Link className={classes.link} onClick={() => openWindow('http://www.xpertdispatch.com/')}>
                          <ListItemText primary='www.xpertdispatch.com' primaryTypographyProps={{ variant: 'body1' }} />
                        </Link>
                      </ListItem>

                      <Divider />

                      <ListItem dense disableGutters>
                        <ListItemText
                          primary='Schedule a meeting to see us in person at:'
                          primaryTypographyProps={{ variant: 'body1' }} />
                      </ListItem>

                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faLocationDot} />
                        </ListItemIcon>
                        <ListItemText
                          primary='110-2570 Matheson Blvd E, Mississauga, ON, L4W 4Z3, Canada'
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default ContactUsView
