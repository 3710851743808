import React, { useState } from 'react'
import propTypes from 'prop-types'
import {
  GoogleMap as GMap,
  useJsApiLoader,
  Marker,
  Polyline,
  InfoWindow
} from '@react-google-maps/api'

// const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

// TODO: Remove the API from component
const MAPS_API_KEY = 'AIzaSyDZkrl_6r7dQe04VWfyThoPFsCp9IYNiHc'

function GoogleMap({ mapType, coordinates }) {
  const [center, setCenter] = useState({
    lat: 1.288258354433791,
    lng: 103.78197312355043
  })
  const [markerMap, setMarkerMap] = useState({})
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [infoOpen, setInfoOpen] = useState(false)

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: MAPS_API_KEY
  })

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds()
    coordinates.map((location) => {
      bounds.extend(location.coords)
      return location.id
    })
    map.fitBounds(bounds)
  }

  const handleOnLoad = (map) => {
    fitBounds(map)
  }

  const zoomMap = () => {
    return mapType === 'marker' ? 17 : 3
  }

  const handleMarkerLoad = (marker, location) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [location.id]: marker }
    })
  }

  const handleMarkerClick = (event, location) => {
    setSelectedMarker(location)
    if (infoOpen) {
      setInfoOpen(false)
    }
    setInfoOpen(true)
  }

  const renderMap = () => {
    const containerStyle = {
      height: '100%',
      width: '100%'
    }
    const mapOptions = {
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP
      }
    }
    const zoom = zoomMap()

    const arrowSymbol = {
      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    }
    const circlSymbol = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 5,
      fillColor: '#fff',
      fillOpacity: 1.0,
      strokeColor: '#7CE7AC',
      strokeWeight: 3
    }
    const lineOptions = {
      strokeColor: '#8996AF',
      strokeOpacity: 1.0,
      strokeWeight: 2,
      icons: [
        {
          icon: circlSymbol,
          offset: '0%'
        },
        {
          icon: arrowSymbol,
          offset: '25%'
        },
        {
          icon: arrowSymbol,
          offset: '50%'
        },
        {
          icon: arrowSymbol,
          offset: '75%'
        },
        {
          icon: circlSymbol,
          offset: '100%'
        }
      ]
    }

    return (
      <div className='map-container'>
        <GMap
          mapContainerStyle={containerStyle}
          options={mapOptions}
          center={center}
          zoom={zoom}
          onLoad={handleOnLoad}
        >
          {mapType === 'direction' ? (
            <Polyline
              path={coordinates.map((location) => location.coords)}
              options={lineOptions}
            />
          ) : (
            coordinates.map((location) => (
              <Marker
                key={location.id}
                position={location.coords}
                onLoad={(marker) => handleMarkerLoad(marker, location)}
                onClick={(event) => handleMarkerClick(event, location)}
                title={location.info ? location.info.name : ''}
              />
            ))
          )}
          {infoOpen && selectedMarker && selectedMarker.info && (
            <InfoWindow
              anchor={markerMap[selectedMarker.id]}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div className='info-window'>
                <h3 className='name'>{selectedMarker.info.name}</h3>
                <div className='content'>{selectedMarker.info.content}</div>
              </div>
            </InfoWindow>
          )}
        </GMap>
      </div>
    )
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : null
}

GoogleMap.propTypes = {
  mapType: propTypes.string.isRequired
}

GoogleMap.defaultProps = {}

export default GoogleMap
