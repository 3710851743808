import React from 'react'
import {
  faClock,
  faEnvelope,
  faFax,
  faPhoneAlt
} from '@fortawesome/pro-light-svg-icons'
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ContactUsView() {
  return (
    <Container maxWidth='md'>
      <Box py={3}>
        <Box mb={2}>
          <Typography
            variant='h4'
            color='secondary'
            style={{ fontWeight: 600 }}
          >
            Contact UPS Supply Chain Solutions:
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card variant='outlined'>
              <Box p={2}>
                <Box mb={1}>
                  <Typography variant='h5' style={{ fontWeight: 700 }}>
                    ACE and Trade Documentation Support
                  </Typography>
                </Box>
                <List disablePadding>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faPhoneAlt} />
                    </ListItemIcon>
                    <ListItemText primary='(360) 332-5222' />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faFax} />
                    </ListItemIcon>
                    <ListItemText primary='(800) 371-7796' />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                    </ListItemIcon>
                    <ListItemText>
                      <Link
                        href='mailto:ACESupportWest@ups.com'
                        variant='body2'
                      >
                        ACESupportWest@ups.com
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faClock} />
                    </ListItemIcon>
                    <ListItemText primary='24/7 coverage' />
                  </ListItem>
                </List>
                <Divider />
                <Box mt={2}>
                  <Box mb={1}>
                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                      Written Correspondence
                    </Typography>
                  </Box>
                  <Typography variant='body2'>
                    UPS Supply Chain Solutions
                  </Typography>
                  <Typography variant='body2'>Attn: e-Manifest Team</Typography>
                  <Typography variant='caption' style={{ lineHeight: 1 }}>
                    2150 Peace Portal Drive
                    <br />
                    Blaine, WA 98230
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card variant='outlined'>
              <Box p={2}>
                <Box mb={1}>
                  <Typography variant='h5' style={{ fontWeight: 700 }}>
                    ACI Support
                  </Typography>
                </Box>
                <List disablePadding>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faPhoneAlt} />
                    </ListItemIcon>
                    <ListItemText
                      primary='(519) 972-9800'
                      secondary='Please ask to be directed to the e-Manifest team'
                      secondaryTypographyProps={{
                        variant: 'caption',
                        style: { lineHeight: 1 }
                      }}
                    />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faFax} />
                    </ListItemIcon>
                    <ListItemText primary='(519) 972-9880' />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                    </ListItemIcon>
                    <ListItemText>
                      <Link href='mailto:ACISupport@ups.com' variant='body2'>
                        ACISupport@ups.com
                      </Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faClock} />
                    </ListItemIcon>
                    <ListItemText primary='24/7 coverage' />
                  </ListItem>
                </List>
                <Divider />
                <Box mt={2}>
                  <Box mb={1}>
                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                      Written Correspondence
                    </Typography>
                  </Box>
                  <Typography variant='body2'>UPS-SCS</Typography>
                  <Typography variant='body2'>Attn: e-Manifest Team</Typography>
                  <Typography variant='caption' style={{ lineHeight: 1 }}>
                    5325 Rhodes Drive
                    <br />
                    Windsor, Ontario N8N 2M1
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card variant='outlined'>
              <Box p={2}>
                <Box mb={1}>
                  <Typography variant='h5' style={{ fontWeight: 700 }}>
                    UPS SCS Southern Border
                  </Typography>
                </Box>
                <List disablePadding>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faPhoneAlt} />
                    </ListItemIcon>
                    <ListItemText primary='915-298-3309' />
                  </ListItem>
                  <ListItem dense disableGutters>
                    <ListItemIcon>
                      <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                    </ListItemIcon>
                    <ListItemText>
                      <Link
                        href='mailto:upssbshipmentinitiation@ups.com'
                        variant='body2'
                      >
                        upssbshipmentinitiation@ups.com
                      </Link>
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default ContactUsView
