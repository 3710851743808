import React, { useEffect, useMemo, useState } from 'react'
import { components, constants, useServices, useWhiteLabelServiceSettings } from 'cng-web-lib'
import UserProfileApiUrls from '../../apiUrls/UserProfileApiUrls'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from '../../common/FileForUserCommon'
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core'
import TinyChip from '../../components/aciacehighway/TinyChip'
import Alert from '../../components/Alert'
import CngBackdrop from '../../components/cngcomponents/CngBackDrop'
import GeneralSection from './GeneralSection'
import SCACCarrierSection from './SCACCarrierSection'
import ClientCodeSection from './ClientCodeSection'
import StatusNotificationSection from './StatusNotificationSection'
import UserConfigurationSection from './UserConfigurationSection'
import SFTPConfigurationSection from './SFTPConfigurationSection'
import ACEHighwayInsuranceSection from './ACEHighwayInsuranceSection'
import ACEISFFilerReferenceSection from './ACEISFFilerReferenceSection'
import AdminSection from './AdminSection'
import PartyCoverSheetDetailsSection from './PartyCSDetails'
import ValidationSchema from './ValidationSchema'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngButton },
  form: {
    field: { CngCheckboxField }
  },
  table: { useFetchCodeMaintenanceLookup },
  CngTabs
} = components

const {
  CodeMaintenanceType,
  filter: { EQUAL }
} = constants

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor:
      theme.palette.background?.sectionOddBg || theme.palette.grey[200],
    padding: 16
  },
  body: {
    backgroundColor: theme.palette.background.paper,
    padding: 16,
    position: 'relative'
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff80',
    zIndex: 2
  },
  footer: {
    padding: 16
  }
}))

function UserProfile(props) {
  const { showNotification } = props

  const [isSuperParty, setIsSuperParty] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { securedSendRequest } = useServices()
  const classes = useStyles()
  const methods = useForm({ defaultValues: undefined })
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("custom-layout");

  const tabs = useMemo(() => {
    const result = [
      {
        tabName: 'General',
        tabContent: (
          <GeneralSection
            isSuperParty={isSuperParty}
            showNotification={showNotification}
            whiteLabelServiceSettings={whiteLabelServiceSettings}
          />
        )
      },
      {
        tabName: 'SCAC / Carrier Code',
        tabContent: <SCACCarrierSection />
      },
      {
        tabName: 'Client Code',
        tabContent: <ClientCodeSection />
      },
      {
        tabName: 'Status Notification',
        tabContent: <StatusNotificationSection />
      },
      {
        tabName: 'Mail Box Configuration',
        tabContent: <UserConfigurationSection />
      },
      {
        tabName: 'SFTP Configuration',
        tabContent: (
          <SFTPConfigurationSection
            showNotification={showNotification}
          />
        )
      },
      {
        tabName: 'ACE Highway Insurance',
        tabContent: <ACEHighwayInsuranceSection />
      },
      {
        tabName: 'ACE ISF My Filer Reference',
        tabContent: <ACEISFFilerReferenceSection showNotification={showNotification} />
      },
      {
        tabName: 'CoverSheet Details',
        tabContent: (
          <PartyCoverSheetDetailsSection
            showNotification={showNotification}
          />
        )
      },
    ]

    if (isSuperParty) {
      result.push({
        tabName: 'Admin',
        tabContent: (
          <AdminSection
            isSuperParty={isSuperParty}
            showNotification={showNotification}
          />
        )
      })
    }
    return result
  }, [isSuperParty])

  useEffect(() => {
    setIsLoading(true)

    const fileForUserDetails = FileForUserGetUserDetails()

    securedSendRequest.execute(
      'POST',
      UserProfileApiUrls.GET,
      {
        fileForUserId: fileForUserDetails?.fileForUserId || null,
        fileForUserPartyId: fileForUserDetails?.fileForUserPartyId || null,
        fileForUserLoginId: fileForUserDetails?.fileForUserLoginId || null
      },
      (response) => methods.reset(toClientDataFormat(response.data)),
      (error) => console.error(error),
      () => setIsLoading(false)
    )
  }, [])

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }
      ])
    ]).then(([billingSuperParty]) => {
      if (Object.keys(billingSuperParty).includes(GetLoggedInUserPartyName())) {

        setIsSuperParty(true)
      }
    })
  }, [])

  if (isLoading) {
    return <CngBackdrop loading />
  }

  if (_.isEmpty(methods.getValues())) {
    return (
      <Alert fullWidth severity='error'>
        User not found.
      </Alert>
    )
  }

  const faxNumber = methods.watch('faxNumber')
  const loginId = methods.watch('loginId')
  const name1 = methods.watch('name1')
  const name3 = methods.watch('name3')
  const telephoneNumber = methods.watch('telephoneNumber')

  async function handleSubmit(data) {
    try {
      setIsSubmitting(true)
      const result = await ValidationSchema.validate(transformClientData(data), { abortEarly: false })

      const fileForUserDetails = FileForUserGetUserDetails()

      securedSendRequest.execute('POST', UserProfileApiUrls.POST,
        {
          ...toServerDataFormat(result),
          fileForUserId: fileForUserDetails?.fileForUserId || null,
          fileForUserPartyId: fileForUserDetails?.fileForUserPartyId || null,
          fileForUserLoginId: fileForUserDetails?.fileForUserLoginId || null
        },
        (response) => {
          const { userConfig, sftpConfig } = response.data
          const errorMessages = []

          userConfig.forEach((config) => {
            if (config.errorMessages) {
              errorMessages.push(...config.errorMessages)
            }
          })

          sftpConfig.forEach((config) => {
            if (config.errorMessages) {
              errorMessages.push(...config.errorMessages)
            }
          })

          if (!_.isEmpty(errorMessages)) {
            showNotification('error', errorMessages)
          } else {
            sessionStorage.setItem('nangUserProfile', JSON.stringify(response.data))
            showNotification('success', 'User Profile Update Successful')
          }
        },
        (error) => console.error(error),
        () => setIsSubmitting(false)
      )
    } catch (error) {
      showNotification('error', error.errors)
      setIsSubmitting(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <Card>
        <Box className={classes.header}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm='auto'>
              <Avatar />
            </Grid>
            <Grid item xs={12} sm>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display='flex' style={{ gap: 8 }}>
                    <Typography style={{ fontWeight: 600 }}>
                      {[name1, name3].join(' ')}
                    </Typography>
                    <TinyChip label={loginId} size='small' variant='outlined' />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box display='flex' style={{ gap: 8 }}>
                        <Typography variant='body2'>Fax no:</Typography>
                        <Typography variant='body2'>{faxNumber}</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Divider orientation='vertical' />
                    </Grid>
                    <Grid item>
                      <Box display='flex' style={{ gap: 8 }}>
                        <Typography variant='body2'>Contact no:</Typography>
                        <Typography variant='body2'>
                          {telephoneNumber}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.body}>
          {isSubmitting && (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          )}
          <CngTabs
            tabs={tabs}
          />
        </Box>
        <Box className={classes.footer}>
          <Grid container justify='flex-end'>
            <Grid item xs={12} sm='auto'>
              <CngCheckboxField
                label={
                  <Typography color='textSecondary' component='span' variant='caption'>
                    <Typography color='error' component='span' variant='inherit'>*</Typography>
                    I have read and agree to the{' '}
                    <Link href='https://globaletrade.services/privacy-policy'>
                      privacy policy
                    </Link>
                  </Typography>
                }
                name='userAgreement'
              />
            </Grid>
            <Grid item xs='auto'>
              <CngButton
                disabled={isSubmitting}
                onClick={methods.handleSubmit(handleSubmit)}
                size='medium'
              >
                Save all changes
              </CngButton>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </FormProvider>
  )
}

function toClientDataFormat(serverData) {
  let localData = { ...serverData }

  localData['corpid'] = _.isEmpty(serverData.corpid)
    ? { corpid: '', serviceBureauFlag: false }
    : { ...serverData.corpid[0] }

  localData.userSpecialInstructions.forEach((instruction, index) => {
    instruction['_id'] = index
  })

  localData.userHTSOverrides.forEach((htsOverride, index) => {
    htsOverride['_id'] = index
  })

  localData.filerPort.forEach((port, index) => {
    port['_id'] = index
  })

  localData.subLocCodes.forEach((location, index) => {
    location['_id'] = index
  })

  localData['userEhblAutoCCNs'] = _.isEmpty(serverData.userEhblAutoCCNs)
    ? { autoCcn: false, currentSequence: '' }
    : { ...serverData.userEhblAutoCCNs[0] }

  localData['userAutoTripNos'] = _.isEmpty(serverData.userAutoTripNos)
    ? { autoTripNo: false, module: [] }
    : {
      autoTripNo: serverData.userAutoTripNos[0].autoTripNo == 'true',
      module: !serverData.userAutoTripNos[0].module ? [] : serverData.userAutoTripNos[0].module.split(',')
    }

  localData['userAutoScns'] = _.isEmpty(serverData.userAutoScns)
    ? { autoScn: false, module: [] }
    : {
      autoScn: serverData.userAutoScns[0].autoScn == 'true',
      module: !serverData.userAutoScns[0].module ? [] : serverData.userAutoScns[0].module.split(',')
    }

  localData['mailId'] = _.isEmpty(serverData.mailId)
    ? { email: '' }
    : { ...serverData.mailId[0] }

  localData['upsSacClientId'] = _.isEmpty(serverData.upsSacClientId)
    ? { upsSacClientId: '' }
    : { ...serverData.upsSacClientId[0] }

  localData.scacs.forEach((scac, index) => {
    scac['_id'] = index
  })

  localData.notis.forEach((notification, index) => {
    notification['_id'] = index
  })

  localData.userConfig.forEach((config, index) => {
    config['_id'] = index
  })

  localData.sftpConfig.forEach((config, index) => {
    config['_id'] = index
  })

  localData.userInsuranceDetails.forEach((insurance, index) => {
    insurance['_id'] = index
    insurance['insuranceFlag'] = insurance.insuranceFlag == 'true'
  })

  localData['userAgreement'] = serverData.userAgreement == 'true'

  return localData
}

function transformClientData(data) {
  let result = { ...data }

  if (!result.corpid.corpid && !Boolean(result.corpid.serviceBureauFlag)) {
    result['corpid'] = null
  }

  if (!result.userEhblAutoCCNs.currentSequence && !Boolean(result.userEhblAutoCCNs.autoCcn)) {
    result['userEhblAutoCCNs'] = null
  }

  if (!result.userAutoTripNos.module && !Boolean(result.userAutoTripNos.autoTripNo)) {
    result['userAutoTripNos'] = null
  }

  if (!result.userAutoScns.module && !Boolean(result.userAutoScns.autoScn)) {
    result['userAutoScns'] = null
  }

  if (!result.mailId.email) {
    result['mailId'] = null
  }

  if (!result.upsSacClientId.upsSacClientId) {
    result['upsSacClientId'] = null
  }

  return result
}

function toServerDataFormat(localData) {
  let serverData = { ...localData }

  serverData['corpid'] = !localData.corpid ? [] :
    [{
      ...localData.corpid,
      serviceBureauFlag: localData.corpid.serviceBureauFlag ? 'true' : 'false'
    }]

  serverData['userEhblAutoCCNs'] = !localData.userEhblAutoCCNs ? [] :
    [{
      ...localData.userEhblAutoCCNs,
      autoCcn: localData.userEhblAutoCCNs.autoCcn ? 'true' : 'false'
    }]

  serverData['userAutoTripNos'] = !localData.userAutoTripNos ? [] :
    [{ ...localData.userAutoTripNos }]

  serverData['userAutoScns'] = !localData.userAutoScns ? [] :
    [{ ...localData.userAutoScns }]

  serverData['mailId'] = !localData.mailId ? [] :
    [{
      ...localData.mailId,
      email: localData.mailId.email
    }]
    serverData['upsSacClientId'] = !localData.upsSacClientId ? [] :
    [{
      ...localData.upsSacClientId,
      upsSacClientId: localData.upsSacClientId.upsSacClientId
    }]

  serverData['userInsuranceDetails'].forEach((insurance) => {
    insurance['insuranceFlag'] = insurance.insuranceFlag ? 'true' : 'false'
  })

  serverData['userAgreement'] = localData.userAgreement ? 'true' : 'false'

  return serverData
}

export default UserProfile
