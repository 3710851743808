import Page from 'src/views/staticHomeView/ContactView/Page'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import ContactUs from 'src/views/homepage/CustomTopBar/ContactUs'

const useStyles = makeStyles(() => ({
  root: {}
}))

function ContactUsView() {
  const classes = useStyles()

  return (
    <Page className={classes.root} title='Home'>
        <ContactUs/>
    </Page>
  )
}

export default ContactUsView
