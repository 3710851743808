import React, { useRef, useState } from 'react'
import ClientCodeFormProperties from './ClientCodeFormProperties'
import { Card } from '@material-ui/core'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'

function ClientCodeSection() {
  const [dialog, setDialog] = useState({ open: false, clientCode: null })
  const { getValues, setValue, watch } = useFormContext()
  const userClientCodes = watch('userClientCodes') || []
  const lastIndex = useRef(userClientCodes.length)

  const columns = [
    {
      title: 'Client Code',
      field: 'clientCode',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Carrier Code',
      field: 'carrierCode',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Module',
      field: 'module',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleAddClientCode(data) {
    const userClientCodes = [
      ...getValues('userClientCodes'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('userClientCodes', userClientCodes)
    setDialog({ open: false, clientCode: null })
  }

  function handleDeleteClientCode(data) {
    const userClientCodes = [...getValues('userClientCodes')].filter(
      (clientCode) =>
        clientCode.id ? clientCode.id !== data.id : clientCode._id !== data._id
    )

    setValue('userClientCodes', userClientCodes)
  }

  function handleEditClientCode(data) {
    const userClientCodes = [...getValues('userClientCodes')]
    const index = userClientCodes.findIndex((clientCode) =>
      clientCode.id ? clientCode.id === data.id : clientCode._id === data._id
    )

    userClientCodes[index] = data

    setValue('userClientCodes', userClientCodes)
    setDialog({ open: false, clientCode: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          actions={[
            {
              buttonProps: { size: 'medium', color: 'secondary' },
              icon: ['fal', 'plus-circle'],
              label: 'Add Client Code',
              onClick: () => setDialog({ open: true, clientCode: null })
            }
          ]}
          columns={columns}
          data={userClientCodes}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) =>
                setDialog({ open: true, clientCode: rowData }),
              tooltip: () => 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: handleDeleteClientCode,
              tooltip: () => 'Delete'
            }
          ]}
        />
      </Card>
      <DialogForm
        formProperties={ClientCodeFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, clientCode: null })}
        onSubmit={
          dialog.clientCode ? handleEditClientCode : handleAddClientCode
        }
        title={`${dialog.clientCode ? 'Edit' : 'Add'} Client Code`}
        value={dialog.clientCode}
      />
    </>
  )
}

export default ClientCodeSection
