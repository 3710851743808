import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import { Divider, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngIconButton },
  form: {
    field: { CngSelectField, CngSwitchField }
  }
} = components

const defaultValue = {
  htsOverrideFlag: false,
  module: 'SBCI'
}

function HTSOverridesSection() {
  const { getValues, setValue, watch } = useFormContext()
  const userHTSOverrides = watch('userHTSOverrides') || []
  const lastIndex = useRef(userHTSOverrides.length)

  function handleAddHTSOverride(data) {
    const userHTSOverrides = [
      ...getValues('userHTSOverrides'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('userHTSOverrides', userHTSOverrides)
  }

  function handleDeleteHTSOverride(index) {
    const userHTSOverrides = [...getValues('userHTSOverrides')]
    userHTSOverrides.splice(index, 1)

    setValue('userHTSOverrides', userHTSOverrides)
  }

  return (
    <CollapsibleSection
      defaultExpanded={!_.isEmpty(userHTSOverrides)}
      title='Override Harmonized Tariff Schedule on'
    >
      <Grid container justify='flex-end' spacing={1}>
        {_.isEmpty(userHTSOverrides) ? (
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', 'plus']}
              onClick={() => handleAddHTSOverride(defaultValue)}
              size='small'
              type='outlined'
            />
          </Grid>
        ) : (
          userHTSOverrides.map((htsOverride, index) => (
            <React.Fragment key={htsOverride._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container justify='flex-end' spacing={1}>
                  <Grid item xs={12} sm>
                    <Grid alignItems='center' container spacing={1}>
                      <Grid item xs='auto'>
                        <CngSwitchField
                          name={`userHTSOverrides.${index}.htsOverrideFlag`}
                        />
                      </Grid>
                      <Grid item xs>
                        <CngSelectField
                          name={`userHTSOverrides.${index}.module`}
                          label='Module'
                          size='small'
                          items={[{ text: 'SBCI', value: 'SBCI' }]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <Grid container spacing={1}>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'plus']}
                          onClick={() => handleAddHTSOverride(defaultValue)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'copy']}
                          onClick={() => handleAddHTSOverride({ ...htsOverride, id: undefined })}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'trash']}
                          onClick={() => handleDeleteHTSOverride(index)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {index + 1 < userHTSOverrides.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

export default HTSOverridesSection
