import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    module: Yup.string(),
    disableFilterByUserFlag: Yup.string()
  })
}

export default makeValidationSchema
