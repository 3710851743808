import React, { useRef, useState } from 'react'
import SFTPConfigurationFormProperties from './SFTPConfigurationFormProperties'
import { Card } from '@material-ui/core'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'

function SFTPConfigurationSection(props) {
  const { showNotification } = props

  const [dialog, setDialog] = useState({ open: false, config: null })
  const { getValues, setValue, watch } = useFormContext()
  const sftpConfig = watch('sftpConfig') || []
  const lastIndex = useRef(sftpConfig.length)

  const columns = [
    {
      title: 'SFTP Name',
      field: 'h2hUserID',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Module',
      field: 'module',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleAddSFTPConfiguration(data) {
    const clonedSftpConfig = [...getValues('sftpConfig')]

    setValue('sftpConfig', [
      ...clonedSftpConfig,
      { ...data, _id: lastIndex.current++ }
    ])
    setDialog({ open: false, config: null })
  }

  function handleDeleteSFTPConfiguration(data) {
    const sftpConfig = [...getValues('sftpConfig')].filter((config) =>
      config.id ? config.id !== data.id : config._id !== data._id
    )

    setValue('sftpConfig', sftpConfig)
  }

  function handleEditSFTPConfiguration(data) {
    const sftpConfig = [...getValues('sftpConfig')]
    const index = sftpConfig.findIndex((config) =>
      config.id ? config.id === data.id : config._id === data._id
    )

    sftpConfig[index] = data

    setValue('sftpConfig', sftpConfig)
    setDialog({ open: false, config: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          actions={[
            {
              buttonProps: { size: 'medium', color: 'secondary' },
              icon: ['fal', 'plus-circle'],
              label: 'Add SFTP Configuration',
              onClick: () => setDialog({ open: true, config: null })
            }
          ]}
          columns={columns}
          data={sftpConfig}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) => setDialog({ open: true, config: rowData }),
              tooltip: () => 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: handleDeleteSFTPConfiguration,
              tooltip: () => 'Delete'
            }
          ]}
        />
      </Card>
      <DialogForm
        formProperties={SFTPConfigurationFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, config: null })}
        onSubmit={
          dialog.config
            ? handleEditSFTPConfiguration
            : handleAddSFTPConfiguration
        }
        title={`${dialog.config ? 'Edit' : 'Add'} SFTP Configuration`}
        value={dialog.config}
      />
    </>
  )
}

export default SFTPConfigurationSection
