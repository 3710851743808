import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    insurancePolicyNo: Yup.string().max(50, 'Insurance: Policy No cannot be more than 50 characters').required('Insurance: Policy No is required.'),
    insuranceCompany: Yup.string().max(60, 'Insurance: Insurance Company cannot be more than 60 characters').required('Insurance: Insurance Company is required.'),
    liabilityAmount: Yup.string()
      .max(12, 'Insurance: Liability Amount cannot be more than 12 characters')
      .matches(
        /^[0-9]+$/i,
        'Insurance: Liability Amount is in invalid format. Only numeric values allowed.'
      )
      .required('Insurance:Liability Amount is required.'),
    yearOfIssuance: Yup.string()
      .matches(
        /^[0-9]+$/i,
        'Insurance: Year Of Issuance is in invalid format. Only numeric values allowed.'
      )
      .max(4, 'Insurance: Year Of Issuance cannot be more than 12 characters')
      .test({
        message: 'Insurance: Year Of Issuance minimum: 1900 & maximum: 3000.',
        test: (value) => parseInt(value) >= 1900 && parseInt(value) <= 3000
      })
      .required('Insurance: Year Of Issuance is required.'),
    insuranceFlag: Yup.boolean()
  })
}

export default makeValidationSchema
