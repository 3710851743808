import React,{useRef, useState} from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import { Card, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'
import PartyConfigFormProperties from '../AdminSection/PartyConfigFormProperties'
import { showErrorNotification } from 'src/components/na/tables/materialtable/utils/MaterialTableUtils'

const {
    form: {
        field: { CngCheckboxField, CngTextField }
    }
} = components

function AdminSection(props) {

    const [dialog, setDialog] = useState({ open: false, scac: null })
    const { getValues, setValue, watch } = useFormContext()
    const userPartyConfigs = watch('userPartyConfigs') || []
    const lastIndex = useRef(userPartyConfigs.length)

    const columns = [
        {
            title: 'Module',
            field: 'module',
            filterConfig: { type: 'textfield' }
        },
        {
            title: 'Disable Filter By User Flag',
            field: 'disableFilterByUserFlag',
            filterConfig: { type: 'textfield' }
        }
    ]

    function handleAddPartyConfig(data) {

        let duplicateFlag = false;
        let userPartyConfigData = getValues('userPartyConfigs');
        for (var key in userPartyConfigData) {
            var obj = userPartyConfigData[key];
            if(data.module == obj.module){
                duplicateFlag = true;
            }
        }

        if(duplicateFlag){
            props.showNotification("error", "Duplicate Module")
        }else{
            const userPartyConfigs = [...getValues('userPartyConfigs'), { ...data, _id: lastIndex.current++ }]

            setValue('userPartyConfigs', userPartyConfigs)
            setDialog({ open: false, userPartyConfigs: null })
        }
    }

    function handleDeletePartyConfig(data) {
        const userPartyConfigs = [...getValues('userPartyConfigs')].filter((userPartyConfig) =>
        userPartyConfig.id ? userPartyConfig.id !== data.id : userPartyConfig._id !== data._id
        )

        setValue('userPartyConfigs', userPartyConfigs)
    }

    function handleEditPartyConfig(data) {

        let duplicateFlag = false;
        let userPartyConfigData = getValues('userPartyConfigs');
        

        const userPartyConfigs = [...getValues('userPartyConfigs')]
        const index = userPartyConfigs.findIndex((userPartyConfig) =>
            userPartyConfig.id ? userPartyConfig.id === data.id : userPartyConfig._id === data._id
        )

        userPartyConfigs[index] = data

        for (var key in userPartyConfigData) {
            if(index!= key){
                var obj = userPartyConfigData[key];
                if(data.module == obj.module){
                    duplicateFlag = true;
                }
            }
        }

        if(duplicateFlag){
            props.showNotification("error", "Duplicate Module")
        }else{
            setValue('userPartyConfigs', userPartyConfigs)
            setDialog({ open: false, userPartyConfigs: null })
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CngSection title='Company'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CngTextField label='Corp ID' name='corpid.corpid' size='small' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CngTextField label='Child Corp ID' name='corpid.childCorpid' size='small' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CngCheckboxField
                                label='Service Bureau'
                                name='corpid.serviceBureauFlag'
                            />
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>
            <Grid item xs={12}>
                <CngSection title='Email Notification'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <CngTextField
                                label='Email Address'
                                name='mailId.email'
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>
            <Grid item xs={12}>
                <CngSection title='User Party Config(Please configure module at Party Config)'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card variant='outlined'>
                                <LocalTable
                                    actions={[
                                        {
                                            buttonProps: { size: 'medium', color: 'secondary' },
                                            icon: ['fal', 'plus-circle'],
                                            label: 'Add Party Config',
                                            onClick: () => setDialog({ open: true, userPartyConfigs: null })
                                        }
                                    ]}
                                    columns={columns}
                                    data={userPartyConfigs}
                                    rowActions={[
                                        {
                                            icon: ['fal', 'pen'],
                                            onClick: (rowData) => setDialog({ open: true, userPartyConfigs: rowData }),
                                            tooltip: () => 'Edit'
                                        },
                                        {
                                            icon: ['fal', 'trash'],
                                            onClick: handleDeletePartyConfig,
                                            tooltip: () => 'Delete'
                                        }
                                    ]}
                                />
                            </Card>
                            <DialogForm
                                formProperties={PartyConfigFormProperties}
                                fullWidth
                                maxWidth='md'
                                open={dialog.open}
                                onClose={() => setDialog({ open: false, userPartyConfigs: null })}
                                onSubmit={dialog.userPartyConfigs ? handleEditPartyConfig : handleAddPartyConfig}
                                title={`${dialog.userPartyConfigs ? 'Edit' : 'Add'} Party Config`}
                                value={dialog.userPartyConfigs}
                            />
                        </Grid>
                    </Grid>
                </CngSection>
            </Grid>
        </Grid>
    )
}
export default AdminSection