import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box, ButtonBase, Card, Collapse, Container, Grid,
  List, ListItem, ListItemIcon, ListItemText,
  Typography, makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faEnvelope, faFax, faPhone } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles(() => ({
  background1: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/Background1.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: 400
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#ffc400',
    color: '#121212'
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#ffc400'
  },
  list: {
    listStyle: 'none',
    '& > li': {
      marginLeft: 16,
      marginBottom: 8,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 8,
        width: 4,
        height: 4,
        backgroundColor: '#ffc400',
        borderRadius: '50%'
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  page: {
    backgroundColor: '#ffffff',
    color: '#121212',
    '& .MuiTypography-body1': {
      fontSize: 16
    }
  }
}))

function FAQView() {
  const classes = useStyles()

  function openWindow(url) {
    window.open(url, '_blank', 'noreferrer')
  }

  return (
    <Box className={classes.page}>
      <Box className={classes.background1}>
        <Container>
          <Grid container spacing>
            <Grid item xs={12} md={6}>
              <Box py={10}>
                <Typography variant='h1' style={{ fontWeight: 700 }}>Support</Typography>
                <Box mt={2} mb={1}>
                  <Box className={classes.divider} />
                </Box>
                <Typography>
                  Do you need assistance? From ACE/ACI questions to trade
                  document support, our team is ready to help.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pb={6}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700, textAlign: 'center' }}>Frequently Asked Questions</Typography>
              <Box my={2}>
                <Box className={classes.divider} mx='auto' />
              </Box>
              <Typography style={{ textAlign: 'center' }}>You've got questions, we've got answers.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FAQ question='Who can use UPS BorderReady?'>
                    <ul className={classes.list}>
                      <li>
                        Truck carrier companies that ply the Canada-U.S. or
                        Mexico-U.S. routes (ACE) and the U.S.-Canada routes (ACI)
                      </li>
                      <li>
                        Service providers and trade chain partners that require
                        cargo movement via highway carriers into the U.S. or
                        Canada
                      </li>
                      <li>
                        Importers/Exporters needing to prepare trade
                        documentation
                      </li>
                      <li>
                        Importers/Exporters wanting to send Commercial Invoice
                        details electronically to UPS
                      </li>
                    </ul>
                  </FAQ>
                </Grid>
                <Grid item xs={12}>
                  <FAQ question='What can you do with UPS BorderReady?'>
                    <ul className={classes.list}>
                      <li>
                        Prepare and submit conveyance and shipment information
                        to U.S. and Canada customs
                      </li>
                      <li>
                        Interface systems to allow data from customer in-house
                        dispatch system to flow to U.S. and Canada customs
                      </li>
                      <li>
                        Prepare trade documentation for both U.S. and Canada
                        customs compliance
                      </li>
                    </ul>
                  </FAQ>
                </Grid>
                <Grid item xs={12}>
                  <FAQ question='What are the benefits?'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography style={{ fontWeight: 700 }}>Data Integration</Typography>
                        </Box>
                        <ul className={classes.list}>
                          <li>
                            Allows you to interface with existing in-house
                            dispatch systems, customers' systems, other service
                            providers' systems or regulatory bodies' systems
                          </li>
                          <li>
                            Reduces time consuming paperwork and data re-entry
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography style={{ fontWeight: 700 }}>Cost Savings</Typography>
                        </Box>
                        <ul className={classes.list}>
                          <li>
                            Reduction in data entry errors as common data will
                            be shared across different documents
                          </li>
                          <li>Data share between ACE and ACI information</li>
                          <li>
                            Data share between ACE/ACI and Commercial Invoice
                            information
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography style={{ fontWeight: 700 }}>Low Start-Up Cost</Typography>
                        </Box>
                        <ul className={classes.list}>
                          <li>
                            A PC with Internet access is all that is required
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography style={{ fontWeight: 700 }}>Time savings</Typography>
                        </Box>
                        <ul className={classes.list}>
                          <li>Faster access to information</li>
                          <li>No duplication of data entry</li>
                          <li>
                            Instantaneous responses since information is
                            transmitted and received in real time
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </FAQ>
                </Grid>
                <Grid item xs={12}>
                  <FAQ question='What are the technical requirements?'>
                    <ul className={classes.list}>
                      <li>
                        PC with Pentium<sup>&reg;</sup> 2-class or above
                      </li>
                      <li>Minimum 64MB RAM; at least 128MB RAM recommended</li>
                      <li>
                        Modem with Internet access; minimum 56kbps bandwidth;
                        broadband of at least 256kbps recommended
                      </li>
                      <li>
                        Microsoft<sup>&reg;</sup> Internet Explorer
                        <sup>&reg;</sup> 6.0 or later versions
                      </li>
                      <li>
                        Adobe<sup>&reg;</sup> Acrobat<sup>&reg;</sup> 4.0 (or
                        higher) for viewing and printing of PDF files
                      </li>
                    </ul>
                  </FAQ>
                </Grid>
                <Grid item xs={12}>
                  <FAQ question='What are the system interfaces?'>
                    <ul className={classes.list}>
                      <li>
                        Web browser to URL{' '}
                        <Link onClick={() => openWindow('https://borderready.ups.com')}>
                          https://borderready.ups.com
                        </Link>
                      </li>
                    </ul>
                  </FAQ>
                </Grid>
                <Grid item xs={12}>
                  <FAQ question='What trade documents are available for the U.S. and Canada?'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography style={{ fontWeight: 700 }}>Canada</Typography>
                        </Box>
                        <ul className={classes.list}>
                          <li>Canada Customs Invoice (CCI)</li>
                          <li>
                            UNITED STATES-MEXICO-CANADA AGREEMENT(USMCA-CUSMA)
                          </li>
                          <li>Export Declaration (B13A)</li>
                          <li>
                            Import Declaration (Canadian Food Inspection
                            Agency-CFIA)
                          </li>
                          <li>
                            Request for Documentation Review (Canadian Food
                            Inspection Agency- CFIA)
                          </li>
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={1}>
                          <Typography style={{ fontWeight: 700 }}>USA</Typography>
                        </Box>
                        <ul className={classes.list}>
                          <li>Commercial Invoice</li>
                          <li>
                            Pro Forma Invoice (Food and Drug Administration-FDA,
                            Bio-Terrorism Act- BTA)
                          </li>
                          <li>
                            UNITED STATES-MEXICO-CANADA AGREEMENT(USMCA-CUSMA)
                          </li>
                          <li>
                            Toxic Substance Control Act (TSCA)- Import
                            Certification Form
                          </li>
                          <li>
                            U.S. Animal and Plant Health Inspection Service
                            (USAPHIS 17-29)
                          </li>
                          <li>
                            U.S. Environment Protection Agency (EPA 3520-1, EPA
                            3520-21) Importation of Motor Vehicles and Motor
                            Engines Subject to Federal Air Pollution Regulations
                          </li>
                          <li>
                            Federal Communications Commission (FCC-740) Radio
                            Frequency Devices Capable of Causing Harmful
                            Interference
                          </li>
                          <li>
                            Food and Drug Administration (FDA-2877) Electronic
                            Products Subject to Radiation Control Standards
                          </li>
                          <li>
                            Department of Transportation (DOT-HS-7) Federal
                            Motor Vehicle Safety, Bumper and Theft Prevention
                            Standards
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </FAQ>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={6}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700, textAlign: 'center' }}>Contact UPS Supply Chain Solutions</Typography>
              <Box my={2}>
                <Box className={classes.divider} mx='auto' />
              </Box>
              <Typography style={{ textAlign: 'center' }}>
                Use the phone numbers and email below to get in touch with the
                help center that best fits your needs.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Typography variant='h4' style={{ fontWeight: 700 }}>ACE and ACI Support</Typography>
                  </Box>
                  <Box mb={3}>
                    <List disablePadding>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faPhone} />
                        </ListItemIcon>
                        <ListItemText
                          primary='(519) 972-9800'
                          primaryTypographyProps={{ variant: 'body1' }}
                          secondary={
                            <Typography color='#121212'>
                              <Typography component='span' style={{ fontWeight: 700 }}>
                                Important note:
                              </Typography>
                              &nbsp;Please ask to be directed to the e-manifest team.
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faFax} />
                        </ListItemIcon>
                        <ListItemText
                          primary='(519) 972-9800'
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                        </ListItemIcon>
                        <ListItemText
                          primary='UPSSCSeManifestTeam@ups.com'
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faClock} />
                        </ListItemIcon>
                        <ListItemText
                          primary='24/7 coverage'
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                    </List>
                  </Box>
                  <Box>
                    <Box mb={1}>
                      <Typography>WRITTEN CORRESPONDENCE</Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography>UPS-SCS</Typography>
                    </Box>
                    <Typography>
                      Attn: e-Manifest Team
                      <br />
                      5325 Rhodes Drive
                      <br />
                      Windsor, Ontario N8N 2M1
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Typography variant='h4' style={{ fontWeight: 700 }}>UPS SCS Southern Border</Typography>
                  </Box>
                  <Box mb={3}>
                    <List disablePadding>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faPhone} />
                        </ListItemIcon>
                        <ListItemText
                          primary='915-298-3309'
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                      <ListItem dense disableGutters>
                        <ListItemIcon>
                          <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                        </ListItemIcon>
                        <ListItemText
                          primary='upssbshipmentinitiation@ups.com'
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

function FAQ(props) {
  const { question, children } = props

  const [expanded, setExpanded] = useState(false)

  return (
    <Card>
      <Box padding={2}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography style={{ fontWeight: 700 }}>{question}</Typography>
          </Grid>
          <Grid item xs='auto'>
            <ButtonBase onClick={() => setExpanded((prev) => !prev)}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                width={24}
                height={24}
                borderRadius='50%'
              >
                <FontAwesomeIcon icon={['fal', expanded ? 'chevron-up' : 'chevron-down']} />
              </Box>
            </ButtonBase>
          </Grid>
        </Grid>
        <Collapse in={expanded}>
          <Box pt={3}>{children}</Box>
        </Collapse>
      </Box>
    </Card>
  )
}

export default FAQView
