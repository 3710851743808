import React, { useRef, useState } from 'react'
import StatusNotificationFormProperties from './StatusNotificationFormProperties'
import { Card, Grid } from '@material-ui/core'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'
import Alert from '../../../components/Alert'
import StatusNotificationProvider from './StatusNotificationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function StatusNotificationSection() {
  const [dialog, setDialog] = useState({ open: false, notification: null })
  const { getValues, setValue, watch } = useFormContext()
  const notis = watch('notis') || []
  const lastIndex = useRef(notis.length)

  const columns = [
    {
      title: 'Destination',
      field: 'destination',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Status Notification Type',
      field: 'notifyType',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Registered Email Address',
      field: 'email',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Fax No.',
      field: 'faxNum',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Mobile No.',
      field: 'mobileNum',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleAddStatusNotification(data) {
    const notis = [...getValues('notis'), { ...data, _id: lastIndex.current++ }]

    setValue('notis', notis)
    setDialog({ open: false, notification: null })
  }

  function handleDeleteStatusNotification(data) {
    const notis = [...getValues('notis')].filter((notification) =>
      notification.id
        ? notification.id !== data.id
        : notification._id !== data._id
    )

    setValue('notis', notis)
  }

  function handleEditStatusNotification(data) {
    const notis = [...getValues('notis')]
    const index = notis.findIndex((notification) =>
      notification.id
        ? notification.id === data.id
        : notification._id === data._id
    )

    notis[index] = data

    setValue('notis', notis)
    setDialog({ open: false, notification: null })
  }

  return (
    <StatusNotificationProvider>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert
            fullWidth
            severity='info'
            icon={<FontAwesomeIcon icon={['fal', 'info-circle']} />}
          >
            The email address information shown here is only service for CBP,
            CBSA and NBCI status notification returning only. If status
            notification selected is empty, system will auto retrieve email
            address from user registration system and default it to ALL. If you
            need to update the email address for other purpose such as billing
            or news broadcasting, please contact our 24/7 ACE support.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined'>
            <LocalTable
              actions={[
                {
                  buttonProps: { size: 'medium', color: 'secondary' },
                  icon: ['fal', 'plus-circle'],
                  label: 'Add Status Notification',
                  onClick: () => setDialog({ open: true, notification: null })
                }
              ]}
              columns={columns}
              data={notis}
              rowActions={[
                {
                  icon: ['fal', 'pen'],
                  onClick: (rowData) =>
                    setDialog({ open: true, notification: rowData }),
                  tooltip: () => 'Edit'
                },
                {
                  icon: ['fal', 'trash'],
                  onClick: handleDeleteStatusNotification,
                  tooltip: () => 'Delete'
                }
              ]}
            />
          </Card>
        </Grid>
      </Grid>
      <DialogForm
        formProperties={StatusNotificationFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, notification: null })}
        onSubmit={
          dialog.notification
            ? handleEditStatusNotification
            : handleAddStatusNotification
        }
        title={`${dialog.notification ? 'Edit' : 'Add'} Status Notification`}
        value={dialog.notification}
      />
    </StatusNotificationProvider>
  )
}

export default StatusNotificationSection
