import Page from 'src/views/staticHomeView/ContactView/Page'
import React from 'react'
import { Box, Button, Container, Grid, Typography, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  button: {
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 10,
    marginBottom: 10
  }
}))

function PrivacyPolicyView({ viewOnly }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Page title='Home'>
      <Container>
        <Box py={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h1' style={{ fontWeight: 800 }}>
                  End User Web Agreement
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant='body2'>
                  PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY AND
                  INDICATE BELOW THAT YOU AND YOUR USERS AGREE TO BE BOUND BY
                  THESE TERMS OF SERVICE. BY ACCEPTING THESE TERMS OF SERVICE,
                  YOU HAVE ENTERED INTO A LEGALLY BINDING AGREEMENT WITH UPS
                  SUPPLY CHAIN SOLUTIONS, INC. ("UPS SCS") ON BEHALF OF THE
                  COMPANY. "Company" means the entity that is referenced in the
                  Registration Form. These Terms of Service are effective on the
                  date you indicate your acceptance in the manner provided
                  below.
                </Typography>
              </Box>
              <Box>
                <Typography variant='body2'>
                  For and in consideration of the promises, covenants, and
                  warranties contained herein and other good and valuable
                  consideration, the receipt and sufficiency of which the
                  parties acknowledge, the parties agree as follows:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  1. Access to Application
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (a) Subject to compliance with each of the terms of these
                    Terms of Service, UPS SCS grants to Company the right to
                    access and use UPS BorderReady™ (including all upgrades and
                    modifications to same) (the "Application") on a limited,
                    revocable, non-exclusive, non-assignable, and
                    non-transferable basis solely in connection with [the
                    submission and receipt of certain information and forms for
                    both U.S. Customs and Border Protection (CBP) and Canadian
                    Border Services Agency (CBSA) bound shipments] and for no
                    other purpose. Company warrants that it will not access or
                    use the Application in a way in which, in UPS SCS's sole
                    judgment, adversely affects the performance or function of
                    the Application or interferes with the ability of authorized
                    parties to access the Application.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (b) The Application is scheduled to be available twenty-four
                    hours a day, seven days a week other than when unavailable
                    for maintenance; however, UPS SCS does not guarantee the
                    availability of the Application or that access will be
                    uninterrupted or error-free. UPS SCS reserves the right to
                    interrupt, limit, or suspend the Application from time to
                    time for purposes of maintenance, upgrades, modifications,
                    or similar reasons. Company understands and agrees that UPS
                    SCS may upgrade or otherwise modify the Application at any
                    time in its discretion. Company agrees that UPS SCS shall
                    not be held responsible or liable for any damages arising
                    from any interruption, suspension, or termination of the
                    Application, regardless of the cause.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (c) The Application is a proprietary product of UPS SCS
                    consisting of software programs, information about UPS SCS
                    and its licensors, customers, vendors, and business
                    partners, and other content ("Information"), and is
                    protected under copyright, trade secret, and other laws. All
                    right, title, and interest in and to the Application and
                    Information, including all associated intellectual property
                    rights, are and shall remain with UPS SCS. These Terms of
                    Service do not convey to Company any interest in or to the
                    Application or Information or any associated intellectual
                    property rights, but only a limited right of use revocable
                    in accordance with the terms of these Terms of Service.
                    Company agrees to use the Application only in strict
                    compliance with applicable laws, rulings and regulations and
                    shall take no actions which would cause UPS SCS or its
                    licensors to be in violation of any laws, rulings or
                    regulations applicable to it. Under no circumstances may the
                    Application be used, or the headquarters of Company be
                    located, in Cuba, Iran, Sudan or any other country subject
                    to embargo or sanctions by the United States Department of
                    the Treasury's Office of Foreign Assets Control. Company
                    assumes all costs associated with its access to and use of
                    the Application.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (d) UPS SCS may terminate these Terms of Service or
                    Company's access to the Application at any time, in its sole
                    discretion.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  2. Modification of the Terms of Service
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    UPS SCS reserves the right to modify these Terms of Service
                    in its sole discretion at any time by posting a revised
                    version of these Terms of Service on the Application or
                    otherwise making it available for Company's review. Any
                    modifications to these Terms of Service, including without
                    limitation any modifications to the warranty disclaimers or
                    limitations of liability, will supersede the prior
                    provisions for all access to and use of the Application
                    occurring after the revised version has been posted to the
                    Application as described above, and the continued use of the
                    Application after that posting constitutes Company's
                    agreement to the revision.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  3. Restrictions on Use
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    In addition to other restrictions set forth in these Terms
                    of Service, Company agrees that:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (a) Company will not input or upload to the Application any
                    viruses, Trojan horses, worms, time bombs, or other computer
                    programming routines that are intended to damage, interfere
                    with, intercept, or expropriate the Application or any
                    Information, or that infringe the intellectual property
                    rights of any third party.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (b) Company may not use or access the Application in any way
                    that, in UPS SCS's judgment, adversely affects the
                    performance or function of the Application or interferes
                    with the ability of authorized parties to access the
                    Application.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (c) Company may not frame or utilize framing techniques to
                    enclose any portion or aspect of the Application or the
                    Information without the express written consent of UPS SCS.
                    Company may not make available on any Web site or otherwise
                    reproduce, distribute, copy, store, use, or sell the
                    Information for commercial gain without the express written
                    consent of UPS SCS. This is a personal service, thus
                    Company's right to use the Application and Information is
                    non-assignable.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (d) Any use in connection with the Application of automated
                    inquiry devices, robots, or repetitive data gathering and
                    extraction tools, routines, scripts, or other mechanisms
                    with similar functionality is expressly prohibited.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (e) Company may not modify, adapt or translate the
                    Application or accompanying written materials. Company also
                    may not alter or remove copyright and other proprietary
                    notices that appear on or in the Application or accompanying
                    written materials. Company shall not, or shall not have a
                    third party, reverse engineer, decompile, disassemble, or
                    translate the Application software.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  4. Privacy; Consent to Release Company Information
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (a) UPS SCS takes the protection of personal data very
                    seriously. Use of the Application by Company is governed by
                    the UPS SCS Privacy Statement
                    [http://www.ups-scs.com/privacy.html] which is incorporated
                    by reference into these Terms of Service.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (b) Company hereby authorizes and appoints UPS SCS to share
                    records referred to in 19 C.F.R., Parts 111 and 163,
                    including any documents, data, or information pertaining to
                    Company's business ("Company Information"), with any or all
                    of the subsidiaries and/or divisions of United Parcel
                    Service, Inc. (collectively, "UPSI"). In addition, Company
                    and UPS SCS agree that UPS SCS and UPSI may engage a third
                    party, or third parties, to render services associated with
                    electronic storage and processing of Company Information
                    that is provided or otherwise made available through or in
                    connection with the Application. Furthermore, Company hereby
                    provides UPS SCS and UPSI consent to release Company
                    Information to any such third party for the purpose of
                    scanning, maintaining, electronically storing, or any other
                    purpose associated with the services that such third party
                    renders in connection with the Application.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  5. Use of Passwords
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    UPS SCS will provide to Company a confidential password(s)
                    that will allow Company to access the Application. Company
                    is solely responsible for maintaining the confidentiality of
                    its passwords and for all activities conducted through
                    Company's account using such passwords. Company acknowledges
                    and agrees that UPS SCS has no responsibility for verifying
                    the identity of anyone using Company's passwords, and may
                    provide access to Information to anyone using Company's
                    passwords. Upon the dismissal of any individual who has a
                    Company password, or if Company has reason to believe that
                    the secrecy of its passwords has been compromised, Company
                    must immediately notify UPS SCS by e-mailing UPS SCS at
                    [acesupportwest@ups.com]. Company may not permit any third
                    party to access the Application and Information using its
                    passwords and may not use anyone else's account or password
                    at any time whether or not Company has the permission of the
                    password holder. Company shall remain liable in all respects
                    for any third party use of Company's passwords and access to
                    the Application and Information. Company acknowledges and
                    agrees that anyone using Company's passwords will be able to
                    access all of the Information, and that the Information
                    cannot be segregated.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  6. Accuracy of Information
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    By registering to use the Application, Company agrees: (1)
                    to provide accurate, current and complete information as may
                    be prompted by any registration forms and as is necessary
                    for maintenance and administration of Company's account; (2)
                    to maintain the security of Company's passwords and user ID;
                    (3) to provide accurate, current and complete customs
                    information as may be prompted by any forms contained within
                    the Application; (4) to maintain and promptly update
                    information supplied on any registration form, customs form
                    or any other information Company provides to UPS SCS; and
                    (5) to be fully responsible for the use of Company's account
                    and the information provided by Company.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  7. No Warranties
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    UPS SCS MAKES NO REPRESENTATIONS ABOUT THE RESULTS TO BE
                    OBTAINED FROM USING THE APPLICATION OR THE INFORMATION. THE
                    USE OF SAME IS AT COMPANY'S OWN RISK. THE APPLICATION AND
                    THE INFORMATION ARE PROVIDED ON AN "AS IS" BASIS. UPS SCS,
                    ITS LICENSORS, AND ITS SUPPLIERS, TO THE FULLEST EXTENT
                    PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR
                    IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
                    TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                    NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. UPS
                    SCS AND ITS AFFILIATES, LICENSORS, AND SUPPLIERS MAKE NO
                    REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY,
                    COMPLETENESS, SECURITY, OR TIMELINESS OF THE APPLICATION OR
                    THE INFORMATION. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS
                    ON IMPLIED WARRANTIES, SO THE LIMITATIONS AND EXCLUSIONS IN
                    THIS SECTION MAY NOT APPLY TO COMPANY. COMPANY AGREES AND
                    ACKNOWLEDGES THAT THE LIMITATIONS AND EXCLUSIONS OF
                    LIABILITY AND WARRANTIES PROVIDED IN THESE TERMS OF SERVICE
                    ARE FAIR AND REASONABLE.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  8. Limitation of Liability
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    IN NO EVENT SHALL UPS SCS, ITS AFFILIATES, ITS LICENSORS, OR
                    ITS SUPPLIERS BE LIABLE TO COMPANY FOR ANY INCIDENTAL,
                    INDIRECT, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES
                    (INCLUDING LOST PROFITS OR DAMAGES RESULTING FROM LOST DATA
                    OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OF OR
                    INABILITY TO USE THE APPLICATION OR THE INFORMATION OR FROM
                    ANY OTHER ACT OR OMISSION IN CONNECTION WITH THESE TERMS OF
                    SERVICE, WHETHER ARISING IN CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), WARRANTY, STRICT LIABILITY, STATUTORY
                    LIABILITY, OR ANY OTHER LEGAL THEORY, AND REGARDLESS OF
                    WHETHER UPS SCS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                    DAMAGES. THIS LIMITATION OF LIABILITY SHALL BE APPLICABLE
                    ONLY TO THE EXTENT PERMITTED BY LAW IN THE EVENT OF THE
                    GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF UPS SCS OR IN THE
                    EVENT OF PERSONAL INJURY OR DEATH.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    IN NO EVENT SHALL UPS SCS'S LIABILITY FOR ANY DAMAGES
                    (DIRECT OR OTHERWISE), PENALTIES OR LOSS, REGARDLESS OF THE
                    FORM OF ACTION OR CLAIM, WHETHER IN CONTRACT, TORT
                    (INCLUDING NEGLIGENCE), DELICT OR OTHERWISE EXCEED US$100,
                    ANY CLAIM FOR DAMAGES OR PENALTIES IN EXCESS OF SUCH AMOUNT
                    BEING WAIVED BY COMPANY.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    COMPANY SHALL NOT BRING ANY CLAIM OR CAUSE OF ACTION ARISING
                    OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE
                    APPLICATION MORE THAN SIX (6) MONTHS AFTER THE CLAIM OR
                    CAUSE OF ACTION ACCRUED.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  9. Risks Inherent in the Use of the Internet
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    Company is aware that the risk of unauthorized access to the
                    Information is increased by making it available and
                    transmitting it via the Internet and acknowledges that
                    unauthorized access to Information, through misuse of
                    Company's password or breach of UPS SCS security or
                    otherwise, may place Company's [shipments and information at
                    greater risk of theft or subject them to other illegal or
                    harmful activity]. UPS SCS does not warrant or guarantee
                    that unauthorized access to Information made available
                    through the Application will be prevented, and shall not be
                    liable for any consequences or damages arising therefrom.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  10. Indemnification
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    Company agrees to indemnify and hold harmless UPS SCS, its
                    affiliates, its licensors, and its suppliers, and the
                    officers, directors, and employees of each of them from and
                    against any and all damages, costs, losses, liabilities,
                    fines, penalties, and expenses (including, but not limited
                    to, attorneys' fees and court costs) incurred as a result of
                    claims, demands, suits, or causes of action arising from or
                    related to any claim arising out of (i) any use by Company
                    of the Application or the content therein, (ii) Company's
                    provision of any registration or customs information, (iii)
                    any breach by Company of these Terms of Service or
                    associated policies, or (iv) UPS SCS making the Information
                    available through the Application. Company further agrees to
                    defend UPS SCS from any such claims, demands, suits, or
                    causes of action.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  11. Choice of Law, Choice of Language, and Dispute Resolution
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (a) To the fullest extent permitted by law, these Terms of
                    Service will be governed by the laws of the State of
                    Georgia, United States of America, excluding (i) its
                    conflicts of laws principles; (ii) the United Nations
                    Convention on Contracts for the International Sale of Goods;
                    (iii) the 1974 Convention on the Limitation Period in the
                    International Sale of Goods; (iv) the Protocol amending the
                    1974 Convention, done at Vienna April 11, 1980; and (v) the
                    Uniform Computer Information Transactions Act (UCITA).
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (b) To the fullest extent permitted by law and consistent
                    with valid entry into a binding agreement, the controlling
                    language of these Terms of Service is English and all
                    correspondence and communications between Company and UPS
                    SCS under these Terms of Service shall be in the English
                    language. Any translation Company has received has been
                    provided solely for Company's convenience.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (c) Any controversy or claim arising out of, related to, or
                    in connection with these Terms of Service (including any
                    amendment or modification thereof), or the breach,
                    termination, interpretation, construction, or validity
                    thereof, including but not limited to any claim for damages
                    or rescission or for fraud in the inducement to enter into
                    the Agreement (the "Dispute"), that a party asserts against
                    the other, shall be fully and finally settled and determined
                    by binding arbitration in accordance with the then-current
                    version of the commercial arbitration rules of the American
                    Arbitration Association ("AAA") and judgment upon the award
                    rendered by the arbitral tribunal may be entered in any
                    court having jurisdiction thereof. The arbitration tribunal
                    shall consist of a single arbitrator mutually agreed upon by
                    the parties, or in the absence of such agreement within
                    thirty (30) calendar days from the first referral of the
                    dispute to the AAA, designated by the AAA. The arbitration
                    shall be held in Atlanta, Georgia U.S.A., unless all parties
                    to the arbitration agree otherwise in writing. All
                    arbitration proceedings and submissions, and the arbitration
                    award, shall be in the English language. A party may,
                    without inconsistency with this agreement to arbitrate, seek
                    from any court having jurisdiction any interim measures or
                    provisional remedies pending the establishment that the
                    arbitral tribunal's final award has been satisfied. The
                    parties agree that the award made by the arbitrators shall
                    be final and binding on the parties, and they waive any
                    right to appeal the arbitral award, to the extent that an
                    appeal may be lawfully waived.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h4' style={{ fontWeight: 600 }}>
                  12. General
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (a) If any provision or portion of a provision of these
                    Terms of Service is determined to be invalid or
                    unenforceable under any circumstances, the provision or
                    portion shall be deemed omitted with respect to those
                    circumstances and the remaining provisions of these Terms of
                    Service shall remain in full force and effect.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (b) No waiver of any breach of any provision of the Terms of
                    Service shall constitute a waiver of any prior, concurrent,
                    or subsequent breach of the same or any other provision
                    hereof, and no waiver shall be effective unless made in
                    writing and signed by an authorized representative of the
                    waiving party.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (c) UPS SCS shall not be liable or responsible for any delay
                    or failure in performance if such delay or failure is due to
                    causes beyond its reasonable control, including but not
                    limited to, telecommunications failures.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (d) Company may preserve these Terms of Service in written
                    form by printing them for Company's records, and Company
                    waives any requirement that Company's acceptance of these
                    Terms of Service be evidenced by a signature on a paper
                    document.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (e) The Terms of Service is an integrated agreement and
                    contains the entire agreement and understanding between the
                    parties regarding its subject matter. All prior or
                    contemporaneous promises, representations, agreements and
                    communications regarding the subject matter of the Terms of
                    Service are merged into and superseded by these Terms of
                    Service.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (f) To the fullest extent permitted by law, if Company is
                    not dealing as a consumer but Company is located in a member
                    state of the European Union, Company waives all notices,
                    acknowledgements and confirmations relative to contracting
                    by electronic means which may be required under Articles
                    10(1), 10(2), 11(1) and 11(2) of EU Directive 2000/31/EC as
                    implemented in Company's jurisdiction in respect of
                    Company's use of the Application.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    (g) Company acknowledges, consistent with the UPS SCS Terms
                    and Conditions of Service
                    [http://www.ups.com/content/us/en/resources/index.html?WT.svl=PriNav],
                    which are incorporated herein by this reference, that
                    Company has the duty and is solely liable for maintaining
                    all records required under the Customs and other laws of the
                    United States. This Agreement in no way requires UPS SCS or
                    UPSI to, and neither UPS SCS not UPSI accept an obligation
                    to, act as a "recordkeeper" or "recordkeeping agent" for
                    Company.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {!viewOnly &&
          <Box display='flex' justifyContent='end'>
            <Button className={classes.button} color='primary'
              onClick={() => history.push('/ups-register')}
              size='large' variant='contained'>
              Accept
            </Button>
            <Button className={classes.button} color='secondary'
              onClick={() => history.push('/ups')}
              size='large' variant='contained'>
              Decline
            </Button>
          </Box>
        }
      </Container>
    </Page>
  )
}

export default PrivacyPolicyView
