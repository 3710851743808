import React from 'react'
import { Box, Card, Container, Grid, Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ACEProductInfo from 'src/assets/rafAssets/ACEProductInfo.jpg'
import ACIProductInfo from 'src/assets/rafAssets/ACIProductInfo.jpg'

const useStyles = makeStyles(() => ({
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#c19b2e'
  },
  section: {
    paddingTop: '20px',
    paddingBottom: '50px'
  },
  oddSection: {
    backgroundColor: '#f2f2f2',
    paddingTop: '20px',
    paddingBottom: '75px'
  },
  solutionCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  typographyHeader: {
    marginBottom: 1
  },
  list: {
    listStyle: 'none',
    '& > li': {
      marginLeft: 16,
      marginBottom: 8,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 8,
        width: 4,
        height: 4,
        backgroundColor: '#c19b2e',
        borderRadius: '50%'
      }
    }
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#c19b2e',
  }
}))


function ProductInfoView() {
  const classes = useStyles()

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Box style={{ paddingTop: '40px', backgroundColor: '#fff' }}>
      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: '130px' }}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h1' style={{ fontWeight: 'bold' }}>About Us</Typography>
                <Box my={2} className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='body1'>
                  The TradeSmartWeb eManifest portal enables highway carriers and other service providers to conveniently connect and submit ACI manifest information directly to CBSA.
                  View responses in real-time so you are the first to know that the cargo you are carrying is cleared to go to the border.
                  The TradeSmartWeb portal allows for data integration and efficient data-entry with streamlined screen flows and stringent validation to ensure your manifest information is correct and cleared by customs allowing on-time delivery of your cargo.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} className={classes.oddSection}>
          <Container>
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography variant='h1' style={{ fontWeight: 'bold' }}>Our Solution</Typography>
                <Box my={2} className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Card variant='outlined' className={classes.solutionCard} >
                      <Box p={2}>
                        <Box display='flex' justifyContent='center' paddingBottom={2}>
                          <img src={ACEProductInfo} className={classes.image} />
                        </Box>

                        <Typography variant='h5' className={classes.typographyHeader}>ACE (Automated Commercial Environment)</Typography>
                        <Typography variant='body2'>
                          The Automated Commercial Environment (ACE) is the US Customs and Border Protection trade processing system used by CBP to facilitate legitimate trade and strengthen border security.
                          Highway carriers are required to send cargo and conveyance details in electronic format to US Customs before reaching the US border.
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card variant='outlined' className={classes.solutionCard} >
                      <Box p={2}>
                        <Box display='flex' justifyContent='center' paddingBottom={2}>
                          <img src={ACIProductInfo} className={classes.image} />
                        </Box>

                        <Typography variant='h5' className={classes.typographyHeader}>ACI (Advance Commercial Information)</Typography>
                        <Typography variant='body2'>
                          The ACI (Advance Commercial Information) program requires electronic pre-arrival of cargo and conveyance information to identify health, safety and security threats related to commercial cargo before arrival in Canada.
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.typographyHeader}>Who is the portal for:</Typography>
                    <Typography variant='body2'>Truck carrier companies that ply the Canada-US or Mexico-US routes.</Typography>
                    <Typography variant='body2'>Service providers and trade chain partners that require cargo movement via highway carriers into the US.</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.typographyHeader}>What can you do with this portal:</Typography>
                    <Typography variant='body2'>Prepare and submit conveyance and shipment information to US CBP and Canada CBSA.</Typography>
                    <Typography variant='body2'>Interface systems to allow data from customer in-house dispatch system to flow to US and Canada Customs.</Typography>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12} className={classes.section}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h1' style={{ fontWeight: 'bold' }}>Why Choose Us</Typography>
                <Box my={2} className={classes.divider} />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h5' className={classes.typographyHeader}>Data Integration:</Typography>
                    <ul className={classes.list}>
                      <li>Allows you to interface with existing in-house dispatch systems, customers' systems, other service providers' systems or regulatory bodies' systems.</li>
                      <li>Reduces time consuming paperwork and data re-entry.</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h5' className={classes.typographyHeader}>Cost Savings:</Typography>
                    <ul className={classes.list}>
                      <li>Reduction in data entry errors as common data will be shared across different documents.</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h5' className={classes.typographyHeader}>Low Start-Up Cost:</Typography>
                    <ul className={classes.list}>
                      <li>A PC with Internet access is all that is required.</li>
                    </ul>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h5' className={classes.typographyHeader}>Time Savings:</Typography>
                    <ul className={classes.list}>
                      <li>Faster access to information.</li>
                      <li>No duplication of data entry.</li>
                      <li>Instantaneous responses since information is transmitted and received in real time.</li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box bgcolor='#f2f2f2' borderRadius={2} p={2}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} md={6} lg={4}>
                      <Box mb={3}>
                        <Typography variant='h4' style={{ fontWeight: 700 }}>Data Security</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Information text={'Only authorized users who have been assigned with a user ID and password can access the portal.'} />
                        </Grid>
                        <Grid item xs={12}>
                          <Information text={'Data is encrypted and secured during HTTPS transport.'} />
                        </Grid>
                        <Grid item xs={12}>
                          <Information text={'Hardware encryptors and dedicated circuit are employed for interchange with Customs.'} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Box mb={3}>
                        <Typography variant='h4' style={{ fontWeight: 700 }}>Technical Requirements</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Information text={'PC with Pentium 2-class or above.'} />
                        </Grid>
                        <Grid item xs={12}>
                          <Information text={'Minimum 64MB RAM. However at least 128MB RAM recommended.'} />
                        </Grid>
                        <Grid item xs={12}>
                          <Information text={'Modem with internet access with minimum 56kbps bandwidth. However, broadband of at least 256kbps recommended.'} />
                        </Grid>
                        <Grid item xs={12}>
                          <Information text={'Microsoft Internet Explorer 6.0 or later versions.'} />
                        </Grid>
                        <Grid item xs={12}>
                          <Information text={'Adobe Acrobat 4.0 (or higher) for viewing and printing of PDF files.'} />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Box mb={3}>
                        <Typography variant='h4' style={{ fontWeight: 700 }}>System Interfaces</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Information>
                            Web browser to URL:{' '}
                            <Link onClick={() => openWindow('https://farrow.com/')}>https://farrow.com/</Link>
                          </Information>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>

      </Grid>
    </Box >
  )
}

function Information({ text, children }) {
  const classes = useStyles()

  return (
    <Box display='flex' style={{ gap: 16 }}>
      <Box className={classes.check}>
        <FontAwesomeIcon icon={['fa', 'check']} />
      </Box>
      <Box flexGrow={1}>{text || children}</Box>
    </Box>
  )
}

export default ProductInfoView
