import React, { useEffect } from 'react'
import { components } from 'cng-web-lib'
import { Box, Card, Grid, Typography } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import _ from 'lodash'

const {
  form: {
    field: { CngSwitchField, CngTextField }
  }
} = components

function AutoGenerateEHBLCCN() {
  const { setValue, watch } = useFormContext()

  const autoCcn = watch('userEhblAutoCCNs.autoCcn')

  useEffect(() => {
    setValue("userEhblAutoCCNs.partyId", FileForUserGetPartyId())
  }, []);

  return (
    <Card variant='outlined'>
      <Box px={2} py={1}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              Auto-Generate eHBL CCN
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngTextField
              disabled={!autoCcn}
              label='Current Sequence'
              name='userEhblAutoCCNs.currentSequence'
              size='small'
              type='numeric'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box display='flex' justifyContent='flex-end'>
              <CngSwitchField
                name='userEhblAutoCCNs.autoCcn'
                onChange={(event) => {
                  setValue('userEhblAutoCCNs.autoCcn', event.target.checked)

                  if (!event.target.checked) {
                    setValue('userEhblAutoCCNs.currentSequence', '')
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default AutoGenerateEHBLCCN
