import React from 'react'
import { components } from 'cng-web-lib'
import { Grid } from '@material-ui/core'
import ContactValidationSchema from './ContactValidationSchema'

const {
  form: { field: { CngTextField } },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  name: '',
  telephone: '',
  email: '',
  comments: '',
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: () => ContactValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <CngGridItem xs={12} shouldHideMap={shouldHideMap?.name}>
            <CngTextField name='name' label='Name' disabled={disabled} required />
          </CngGridItem>
          <CngGridItem xs={12} shouldHideMap={shouldHideMap?.telephone}>
            <CngTextField name='telephone' label='Telephone' disabled={disabled} required />
          </CngGridItem>
          <CngGridItem xs={12} shouldHideMap={shouldHideMap?.email}>
            <CngTextField name='email' label='Email' disabled={disabled} required />
          </CngGridItem>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <CngGridItem xs={12} shouldHideMap={shouldHideMap?.comments}>
          <CngTextField multiline rows={6} name='comments' label='Question or Comments' required />
        </CngGridItem>
      </Grid>
    </Grid>
  )
}

const FormProperties = Object.freeze({ formikProps: FORMIK_PROPS, Fields: Fields })

export default FormProperties
