import { Yup } from 'cng-web-lib'
import SCACMakeValidationSchema from './SCACCarrierSection/SCACMakeValidationSchema'
import StatusNotificationMakeValidationSchema from './StatusNotificationSection/StatusNotificationMakeValidationSchema'
import UserConfigurationMakeValidationSchema from './UserConfigurationSection/UserConfigurationMakeValidationSchema'

let regexEI = "^\\d{2}-\\d{7}[a-zA-Z0-9]{2}$"
let regex34 = "^\\d{3}\\-\\d{2}\\-\\d{4}$"
let regexANI = "^\\d{6}\\-\\d{5}$"
let regexAEF = "^[a-zA-Z0-9]+$"
let regexNumeric = '^[0-9]*$'

const ValidationSchema = Yup.object({
  corpid: Yup.object({
    corpid: Yup.string(),
    serviceBureauFlag: Yup.boolean()
  }).nullable(),
  filerPort: Yup.array().of(
    Yup.object({
      filerCode: Yup.string().max(3, 'Filer Port: Filer Code cannot be more than 3 characters.').required('Filer Port: Filer Code is required.'),
      portCode: Yup.string().max(4, 'Filer Port: Port Code cannot be more than 4 characters.').required('Filer Port: Port Code is required.')
    })
  ),
  subLocCodes: Yup.array().of(
    Yup.object({
      module: Yup.string().required('Sub-Location Code: Module is required.')
    })
  ),
  userEhblAutoCCNs: Yup.object({
    currentSequence: Yup.string()
      .max(8)
      .matches(
        /^[0-9]+$/i,
        'Ehbl Auto CCN: Current Sequence is in invalid format. Only numeric values allowed.'
      )
      .required('Ehbl Auto CCN: Current Sequence is required.')
  }).nullable(),
  userAutoTripNos: Yup.object({
    module: Yup.string().when('autoTripNo', {
      is: true,
      then: Yup.string().required('Auto Trip Number: Please select at least one Module.'),
      otherwise: Yup.string().nullable()
    })
  }).nullable(),
  userAutoScns: Yup.object({
    module: Yup.string().when('autoScn', {
      is: true,
      then: Yup.string().required('Auto SCN/CCN: Please select at least one Module.'),
      otherwise: Yup.string().nullable()
    })
  }).nullable(),
  userPartyIdentifier: Yup.array().of(
    Yup.object({
      partyIdentifierCode: Yup.string().required('Party Identification: Identification Type is required.'),
      partyIdentifier: Yup.string().max(33, 'Party Identification: Identification Number cannot be more than 33 characters.').required('Party Identification: Identification Number is required.')
    })
  ),
  partyCSDetails: Yup.array().of(
    Yup.object({
      companyName: Yup.string().nullable(),
      companyAddress: Yup.string().nullable(),
      companyPhoneNumber: Yup.string().nullable(),
      companyFax: Yup.string().nullable(),
    })
  ),
  mailId: Yup.object({
    email: Yup.string()
      .email('Email Notificaton: Invalid email address.')
      .required('Email Notification: Email is required.')
  }).nullable(),
  userSpecialInstructions: Yup.array().of(
    Yup.object({
      specialInstruction: Yup.string().max(350, 'Special Instruction: Instruction cannot be more than 350 character.').required('Special Instruction: Instruction is required.')
    })
  ),
  scacs: Yup.array().of(SCACMakeValidationSchema()),
  notis: Yup.array().of(StatusNotificationMakeValidationSchema()),
  userConfig: Yup.array().of(UserConfigurationMakeValidationSchema()),
  userAgreement: Yup.boolean().oneOf(
    [true],
    'Please agree to privacy policy to save.'
  ),
  aceIsfFileReference: Yup.object({
    importerDocType: Yup.string().nullable(),
    importerRefNo:
      Yup.string().when('importerDocType', {
        is: (importerDocType) => (importerDocType === 'EI'),
        then: Yup.string().nullable().matches(regexEI, "Incorrect Importer Document Number format: NN-NNNNNNNXX")
      })
        .when('importerDocType', {
          is: (importerDocType) => (importerDocType === '34'),
          then: Yup.string().nullable().matches(regex34, "Incorrect Importer Document Number format: NNN-NN-NNNN")
        })
        .when('importerDocType', {
          is: (importerDocType) => (importerDocType === 'ANI'),
          then: Yup.string().nullable().matches(regexANI, "Incorrect Importer Document Number format: YYDDPP-NNNNN")
        })
        .when('importerDocType', {
          is: (importerDocType) => (importerDocType === 'AEF'),
          then: Yup.string().nullable().matches(regexAEF, "Incorrect Importer Document Number format: XXXXXXXXXXXXXXX")
        }).nullable(),
    cbpBond: Yup.string().nullable(),
    bondType: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then: Yup.string().nullable()
    }).nullable(),
    bondHolderDocType: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then: Yup.string().nullable(),
    }).nullable(),
    bondHolderDocNo: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then:
        Yup.string().when('bondHolderDocType', {
          is: (bondHolderDocType) => (bondHolderDocType === 'EI'),
          then: Yup.string().nullable().matches(regexEI, "Incorrect Bond Holder Document Number format: NN-NNNNNNNXX")
        })
          .when('bondHolderDocType', {
            is: (bondHolderDocType) => (bondHolderDocType === '34'),
            then: Yup.string().nullable().matches(regex34, "Incorrect Bond Holder Document Number format: NNN-NN-NNNN")
          })
          .when('bondHolderDocType', {
            is: (bondHolderDocType) => (bondHolderDocType === 'ANI'),
            then: Yup.string().nullable().matches(regexANI, "Incorrect Bond Holder Document Number format: YYDDPP-NNNNN")
          }).nullable(),
    }).nullable(),
    bondTerm: Yup.string().when('cbpBond', {
      is: (cbpBond) => cbpBond === "true",
      then: Yup.string().nullable(),
    }).nullable(),
    bondTermRefNo: Yup.string().when('bondType', {
      is: (bondType) => bondType === "16",
      then: Yup.string().when('bondTerm', {
        is: (bondTerm) => bondTerm === "9",
        then: Yup.string().nullable(),
      })
    }).nullable(),
    bondTermSuretyCode: Yup.string().when('bondType', {
      is: (bondType) => bondType === "16",
      then: Yup.string().when('bondTerm', {
        is: (bondTerm) => bondTerm === "9",
        then: Yup.string().nullable(),
      })
    }).nullable(),
    agentCode: Yup.string().nullable()
  }).nullable(),
})

export default ValidationSchema
