import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import { Divider, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngIconButton },
  form: {
    field: { CngTextField }
  }
} = components

const defaultValue = {
  specialInstruction: ''
}

function SpecialInstructionSection() {
  const { getValues, setValue, watch } = useFormContext()
  const userSpecialInstructions = watch('userSpecialInstructions') || []
  const lastIndex = useRef(userSpecialInstructions.length)

  function handleAddInstruction(data) {
    const userSpecialInstructions = [
      ...getValues('userSpecialInstructions'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('userSpecialInstructions', userSpecialInstructions)
  }

  function handleDeleteInstruction(index) {
    const userSpecialInstructions = [...getValues('userSpecialInstructions')]
    userSpecialInstructions.splice(index, 1)

    setValue('userSpecialInstructions', userSpecialInstructions)
  }

  return (
    <CngSection title='Special Instruction'>
      <Grid container justify='flex-end' spacing={1}>
        {_.isEmpty(userSpecialInstructions) ? (
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', 'plus']}
              onClick={() => handleAddInstruction(defaultValue)}
              size='small'
              type='outlined'
            />
          </Grid>
        ) : (
          userSpecialInstructions.map((instruction, index) => (
            <React.Fragment key={instruction._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container justify='flex-end' spacing={1}>
                  <Grid item xs={12} sm>
                    <CngTextField
                      label='Instruction'
                      name={`userSpecialInstructions.${index}.specialInstruction`}
                      isRequired
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <Grid container spacing={1}>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'plus']}
                          onClick={() => handleAddInstruction(defaultValue)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'copy']}
                          onClick={() =>
                            handleAddInstruction({ ...instruction, id: undefined })
                          }
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'trash']}
                          onClick={() => handleDeleteInstruction(index)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {index + 1 < userSpecialInstructions.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

export default SpecialInstructionSection
