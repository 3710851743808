import { Yup } from 'cng-web-lib'

const ContactValidationSchema = Yup.object({
  name: Yup.string().required('Required'),
  telephone: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  comments: Yup.string().required('Required'),
})

export default ContactValidationSchema
