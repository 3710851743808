import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    h2hUserID: Yup.string()
      .max(10, 'SFTP: SFTP ID cannot be more than 10 characters.')
      .matches(
        /^[a-z0-9]+$/i,
        'SFTP: SFTP ID is in invalid format. Only alphanumeric values allowed.'
      )
      .required('SFTP: SFTP ID is required.'),
    module: Yup.string().required('SFTP: Module is required.')
  })
}

export default makeValidationSchema
