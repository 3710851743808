import { Box, Container, Grid, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

import React from 'react'
import { useHistory } from 'react-router-dom'
import { components } from 'cng-web-lib'

const {
  button: { CngButton }
} = components

const useStyles = makeStyles(() => ({
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#ffc400'
  },
  button: {
    borderRadius: 999,
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none',
    '&.ng-button-filled-primary': {
      backgroundColor: '#ffc400',
      boxShadow: 'none',
      color: '#121212',
      '&:hover': {
        backgroundColor: '#ffd033',
        boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
          0px 6px 12px -2px rgba(0, 0, 0, 0.09),
          0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
      },
      '&:focus': {
        backgroundColor: '#ffa200'
      }
    },
    '&.ng-button-filled-secondary': {
      backgroundColor: 'transparent',
      color: '#0662bb',
      border: 'none',
      boxShadow: 'inset 0 0 0 2px #0662bb',
      '&:hover': {
        backgroundColor: '#0662bb26'
      },
      '&:focus': {
        backgroundColor: '#0662bb4d'
      }
    }
  }
}))

function Hero1() {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Container>
      <Grid container spacing>
        <Grid item xs={12} md={6}>
          <Box py={xsDown ? 6 : 10}>
            <Typography variant='h1' style={{ fontWeight: 700 }}>
              Logistics Simplifies Cross-Border Paperwork
            </Typography>
            <Box mt={2} mb={1}>
              <Box className={classes.divider} />
            </Box>
            <Box mb={2}>
              <Typography>
                Increased security means increased paperwork, especially when
                moving truck freight across the U.S.-Canadian border. Web-based
                GeTS simplifies and speeds preparation and filing of eManifests,
                commercial invoices and government forms, including NAFTA
                documentation.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm='auto'>
                <CngButton
                  className={classes.button}
                  color='secondary'
                  onClick={() => history.push('/ups-end-user-web-agreement')}
                >
                  Register
                </CngButton>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <CngButton
                  className={classes.button}
                  onClick={() => history.push('/login')}
                >
                  Login
                </CngButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Hero1
