import React from 'react'
import { Box } from '@material-ui/core'
import { MainContent, SubContent } from './Content'
import Page from 'src/views/staticHomeView/ContactView/Page'

function FCIHomeView() {
  sessionStorage.clear()
  sessionStorage.setItem('homePagePath', '/fci')

  return (
    <Page title='Home' style={{ paddingTop: '87px' }}>
      <Box >
        <MainContent />
      </Box>

      <Box style={{ backgroundColor: '#f2f2f2' }}>
        <SubContent />
      </Box>
    </Page>
  )
}

export default FCIHomeView
