import React, { useRef, useState } from 'react'
import UserConfigurationFormProperties from './UserConfigurationFormProperties'
import { Card } from '@material-ui/core'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'

function UserConfigurationSection() {
  const [dialog, setDialog] = useState({ open: false, config: null })
  const { getValues, setValue, watch } = useFormContext()
  const userConfig = watch('userConfig') || []
  const lastIndex = useRef(userConfig.length)

  const columns = [
    {
      title: 'Mail Box ID',
      field: 'mailBoxID',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Module',
      field: 'module',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleAddUserConfig(data) {
    const userConfig = [
      ...getValues('userConfig'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('userConfig', userConfig)
    setDialog({ open: false, config: null })
  }

  function handleDeleteUserConfig(data) {
    const userConfig = [...getValues('userConfig')].filter((config) =>
      config.id ? config.id !== data.id : config._id !== data._id
    )

    setValue('userConfig', userConfig)
  }

  function handleEditUserConfig(data) {
    const userConfig = [...getValues('userConfig')]
    const index = userConfig.findIndex((config) =>
      config.id ? config.id === data.id : config._id === data._id
    )

    userConfig[index] = data

    setValue('userConfig', userConfig)
    setDialog({ open: false, config: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          actions={[
            {
              buttonProps: { size: 'medium', color: 'secondary' },
              icon: ['fal', 'plus-circle'],
              label: 'Add Mail Box Configuration',
              onClick: () => setDialog({ open: true, config: null })
            }
          ]}
          columns={columns}
          data={userConfig}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) => setDialog({ open: true, config: rowData }),
              tooltip: () => 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: handleDeleteUserConfig,
              tooltip: () => 'Delete'
            }
          ]}
        />
      </Card>
      <DialogForm
        formProperties={UserConfigurationFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, config: null })}
        onSubmit={dialog.config ? handleEditUserConfig : handleAddUserConfig}
        title={`${dialog.config ? 'Edit' : 'Add'} Mail Box Configuration`}
        value={dialog.config}
      />
    </>
  )
}

export default UserConfigurationSection
