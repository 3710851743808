import React from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import { Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngIconButton },
  form: {
    field: { CngSelectField, CngTextField }
  }
} = components

const defaultValue = {
  partyIdentifierCode: '167',
  partyIdentifier: ''
}

function PartyIdentificationSection() {
  const { setValue, watch } = useFormContext()
  const userPartyIdentifier = watch('userPartyIdentifier') || []
  
  return (
    <CngSection title='Party Identification'>
      <Grid alignItems='center' container justify='flex-end' spacing={1}>
        {_.isEmpty(userPartyIdentifier) ? (
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', 'plus']}
              onClick={() => setValue('userPartyIdentifier', [defaultValue])}
              size='small'
              type='outlined'
            />
          </Grid>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm>
              <Grid alignItems='center' container justify='flex-end' spacing={1}>
                <Grid item xs={12} sm={6}>
                  <CngSelectField
                    label='Identification Type'
                    name='userPartyIdentifier.0.partyIdentifierCode'
                    size='small'
                    items={[
                      { text: 'EIN', value: '167' },
                      { text: 'SSN', value: '161' },
                      { text: 'Customs Assigned Number', value: '160' }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CngTextField
                    label='Identification Number'
                    name='userPartyIdentifier.0.partyIdentifier'
                    size='small'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm='auto'>
              <Grid container spacing={1}>
                <Grid item>
                  <CngIconButton
                    icon={['fal', 'trash']}
                    onClick={() => setValue('userPartyIdentifier', [])}
                    size='small'
                    type='outlined'
                  />
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </CngSection>
  )
}

export default PartyIdentificationSection
