import makeValidationSchema from './StatusNotificationMakeValidationSchema'
import { components } from 'cng-web-lib'
import React, { useCallback, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'
import { StatusNotificationContext } from './StatusNotificationContext'

const {
  form: {
    field: { CngTextField, CngSelectField }
  }
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  notifyType: 'ALL',
  email: '',
  faxNum: '',
  destination: 'CBP'
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ index }) {
  const { getOptions } = useContext(StatusNotificationContext)
  const { setValue, watch } = useFormContext()

  const getFieldName = useCallback((field) => {
    return typeof index === 'number' ? `notis.${index}.${field}` : field
  }, [])

  const destination = watch(getFieldName('destination'))

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CngSelectField
          label='Destination'
          name={getFieldName('destination')}
          isRequired
          size='small'
          onChange={() => {
            setValue(getFieldName('notifyType'), 'ALL')
            setValue(getFieldName('faxNum'), null)
          }}
          items={[
            { text: 'CBP', value: 'CBP' },
            { text: 'CBSA', value: 'CBSA' },
            { text: 'NBCI', value: 'NBCI' },
            { text: 'ACEOCN', value: 'ACEOCN' },
            { text: 'ACEISF', value: 'ACEISF' }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <CngSelectField
          disabled={!destination}
          label='Status Notification Type'
          name={getFieldName('notifyType')}
          isRequired
          size='small'
          items={getOptions(destination)}
        />
      </Grid>
      <Grid item xs={12}>
        <CngTextField
          helperText=""
          name={getFieldName('email')}
          label='Email Address'
          size='small'
          isRequired
        />
      </Grid>
      {destination != 'NBCI' &&
        <Grid item xs={12}>
          <CngTextField
            name={getFieldName('faxNum')}
            label='Fax Number'
            size='small'
        />
        </Grid>
      }
      {destination != 'NBCI' &&
        <Grid item xs={12}>
          <CngTextField
            name={getFieldName('mobileNum')}
            label='Mobile Number'
            size='small'
          />
        </Grid>
      }
    </Grid>
  )
}

const StatusNotificationFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default StatusNotificationFormProperties
