import React from 'react'
import { components } from 'cng-web-lib'
import RegisterValidationSchema from './RegisterValidationSchema'
import { Grid } from '@material-ui/core'

const {
  form: {
    field: { CngSelectField, CngTextField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  service: '',
  companyName: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  usScacCode: '',
  canadianCarrierCode: '',
  contactName: '',
  contactPhoneNumber: '',
  contactEmailAddress: '',
  billingContactName: '',
  billingContactPhoneNumber: '',
  billingContactEmailAddress: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: () => RegisterValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} shouldHideMap={shouldHideMap?.service}>
        <CngSelectField
          required
          name='service'
          label='Service'
          disabled={disabled}
          items={[
            { text: 'ACE', value: 'ace' },
            { text: 'ACI', value: 'aci' },
            { text: 'TD-US', value: 'td-us' },
            { text: 'TD-CA', value: 'td-ca' },
            { text: 'All', value: 'all' }
          ]}
          helperText='TD = Trade Documentation'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHideMap={shouldHideMap?.companyName}>
        <CngTextField
          name='companyName'
          label='Company name'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHideMap={shouldHideMap?.address}>
        <CngTextField
          name='address'
          label='Address'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHideMap={shouldHideMap?.city}>
        <CngTextField
          name='city'
          label='City'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHideMap={shouldHideMap?.state}>
        <CngTextField
          name='state'
          label='Province/State'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} lg={3} shouldHideMap={shouldHideMap?.postalCode}>
        <CngTextField
          name='postalCode'
          label='Postal code'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHideMap={shouldHideMap?.usScacCode}>
        <CngTextField
          name='usScacCode'
          label='US SCAC Code'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHideMap={shouldHideMap?.canadianCarrierCode}>
        <CngTextField
          name='canadianCarrierCode'
          label='Canadian Carrier Code'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHideMap={shouldHideMap?.contactName}>
        <CngTextField
          name='contactName'
          label='Contact name'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHideMap={shouldHideMap?.contactPhoneNumber}>
        <CngTextField
          name='contactPhoneNumber'
          label='Contact phone number'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHideMap={shouldHideMap?.contactEmailAddress}>
        <CngTextField
          name='contactEmailAddress'
          label='Contact email address'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHideMap={shouldHideMap?.billingContactName}>
        <CngTextField
          name='billingContactName'
          label='Billing contact name'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHideMap={shouldHideMap?.billingContactPhoneNumber}>
        <CngTextField
          name='billingContactPhoneNumber'
          label='Billing contact phone number'
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} md={4} shouldHideMap={shouldHideMap?.billingContactEmailAddress}>
        <CngTextField
          name='billingContactEmailAddress'
          label='Billing contact email address'
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
  )
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default FormProperties
