import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    backgroundColor: theme.palette.background.sectionOddBg,
    paddingTop: 128,
    paddingBottom: 128
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}))

function Testimonials({ className, ...rest }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth='md'>
        <Typography
          variant='h2'
          align='center'
          color='textPrimary'
          className={classes.title}
        >
          &quot;Devias builds some of the best templates you can find for React.
          <br />
          They will save you time.&quot;
        </Typography>
        <Box mt={6} display='flex' justifyContent='center' alignItems='center'>
          <Avatar src='/static/home/olivier.png' />
          <Box ml={2}>
            <Typography variant='body1' color='textPrimary'>
              Olivier Tassinari
              <Typography
                color='textSecondary'
                display='inline'
                component='span'
              >
                , co-creator of @MaterialUI
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  )
}

Testimonials.propTypes = {
  className: PropTypes.string
}

export default Testimonials
