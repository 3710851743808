import React, { useRef, useState } from 'react'
import SCACFormProperties from './SCACFormProperties'
import { Card } from '@material-ui/core'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'

function SCACCarrierSection() {
  const [dialog, setDialog] = useState({ open: false, scac: null })
  const { getValues, setValue, watch } = useFormContext()
  const scacs = watch('scacs') || []
  const lastIndex = useRef(scacs.length)

  const columns = [
    {
      title: 'SCAC / Carrier Code',
      field: 'scacCode',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Module',
      field: 'module',
      filterConfig: { type: 'textfield' }
    }
  ]

  function handleAddSCAC(data) {
    const scacs = [...getValues('scacs'), { ...data, _id: lastIndex.current++ }]

    setValue('scacs', scacs)
    setDialog({ open: false, scac: null })
  }

  function handleDeleteSCAC(data) {
    const scacs = [...getValues('scacs')].filter((scac) =>
      scac.id ? scac.id !== data.id : scac._id !== data._id
    )

    setValue('scacs', scacs)
  }

  function handleEditSCAC(data) {
    const scacs = [...getValues('scacs')]
    const index = scacs.findIndex((scac) =>
      scac.id ? scac.id === data.id : scac._id === data._id
    )

    scacs[index] = data

    setValue('scacs', scacs)
    setDialog({ open: false, scac: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          actions={[
            {
              buttonProps: { size: 'medium', color: 'secondary' },
              icon: ['fal', 'plus-circle'],
              label: 'Add SCAC / Carrier Code',
              onClick: () => setDialog({ open: true, scac: null })
            }
          ]}
          columns={columns}
          data={scacs}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) => setDialog({ open: true, scac: rowData }),
              tooltip: () => 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: handleDeleteSCAC,
              tooltip: () => 'Delete'
            }
          ]}
        />
      </Card>
      <DialogForm
        formProperties={SCACFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, scac: null })}
        onSubmit={dialog.scac ? handleEditSCAC : handleAddSCAC}
        title={`${dialog.scac ? 'Edit' : 'Add'} SCAC / Carrier Code`}
        value={dialog.scac}
      />
    </>
  )
}

export default SCACCarrierSection
