import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Typography,
    makeStyles
  } from '@material-ui/core'
  
  import PropTypes from 'prop-types'
  import React from 'react'
  import clsx from 'clsx'
  
  const useStyles = makeStyles((theme) => ({
    root: {
      // backgroundColor: theme.palette.background.default,
      backgroundColor: theme.palette.background.sectionAltBg,
      paddingTop: 128,
      paddingBottom: 128
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  }))
  
  function Features1({ className, ...rest }) {
    const classes = useStyles()
  
    return (
      <div className={clsx(classes.root, className)} {...rest}>
        <Container maxWidth='lg'>
          {/* <Typography
            variant='h1'
            color='secondary'
            align='center'
          >
            small Text
          </Typography> */}
          <Typography variant='h1' align='center' color='textPrimary'>
          GeTS Benefits
          </Typography>
          <Box mt={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {/* <Box display='flex' className={`align-left`}> */}
                  {/* <Avatar className={classes.avatar}>01</Avatar> */}
                  {/* <Box ml={2}> */}
                    <Typography variant='h4' color='textPrimary'>
                    <b>Data integration : </b>Interface with existing in-house dispatch systems, as well as systems of customers, service providers and regulatory bodies
                    </Typography>
                    {/* <Typography variant='body1' color='textPrimary'>
                      description about the feature
                    </Typography> */}
                  {/* </Box> */}
                {/* </Box> */}
              </Grid>
              <Grid item xs={12} md={12}>
                <Box display='flex' className={`align-left`}>
                  {/* <Avatar className={classes.avatar}>02</Avatar> */}
                  {/* <Box ml={2}> */}
                    <Typography variant='h4' color='textPrimary'>
                    <b>Cost savings :</b> Reduce data-entry errors by sharing common data between ACE and ACI, and ACE/ACI and commercial invoice information
                    </Typography>
                    {/* <Typography variant='body1' color='textPrimary'>
                      description about the feature
                    </Typography> */}
                  {/* </Box> */}
                </Box>
              </Grid>   
              <Grid item xs={12} md={12}>
                <Box display='flex' className={`align-left`}>
                  {/* <Avatar className={classes.avatar}>03</Avatar> */}
                  {/* <Box ml={2}> */}
                    <Typography variant='h4' color='textPrimary'>
                    <b>Time savings :</b> Access information faster, eliminate data-entry duplication and receive real-time responses to submissions
                    </Typography>
                    {/* <Typography variant='body1' color='textPrimary'>
                      description about the feature
                    </Typography> */}
                  {/* </Box> */}
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box display='flex' className={`align-left`}>
                  {/* <Avatar className={classes.avatar}>03</Avatar> */}
                  {/* <Box ml={2}> */}
                    <Typography variant='h4' color='textPrimary'>
                    <b>Low start-up costs :</b> Requires only a PC with an Internet connection, no installation or purchase of software or IT maintenance necessary
                    </Typography>
                    {/* <Typography variant='body1' color='textPrimary'>
                      description about the feature
                    </Typography> */}
                  {/* </Box> */}
                </Box>
              </Grid>
            </Grid>
            
          </Box>
        </Container>
      </div>
    )
  }
  
  Features1.propTypes = {
    className: PropTypes.string
  }
  
  export default Features1
  