import React, { useRef } from 'react'
import { components } from 'cng-web-lib'
import CollapsibleSection from '../../../components/ui/CollapsibleSection'
import { Divider, Grid } from '@material-ui/core'
import { useFormContext } from 'react-hook-form'
import _ from 'lodash'

const {
  button: { CngIconButton },
  form: {
    field: { CngTextField }
  }
} = components

const defaultValue = {
  filerCode: '',
  portCode: ''
}

function FilerPortSection() {
  const { getValues, setValue, watch } = useFormContext()
  const filerPort = watch('filerPort') || []
  const lastIndex = useRef(filerPort.length)

  function handleAddFilerPort(data) {
    const filerPort = [
      ...getValues('filerPort'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('filerPort', filerPort)
  }

  function handleDeleteFilerPort(index) {
    const filerPort = [...getValues('filerPort')]
    filerPort.splice(index, 1)

    setValue('filerPort', filerPort)
  }

  return (
    <CollapsibleSection
      defaultExpanded={!_.isEmpty(filerPort)}
      title='Filer Port'
    >
      <Grid container justify='flex-end' spacing={1}>
        {_.isEmpty(filerPort) ? (
          <Grid item xs='auto'>
            <CngIconButton
              icon={['fal', 'plus']}
              onClick={() => handleAddFilerPort(defaultValue)}
              size='small'
              type='outlined'
            />
          </Grid>
        ) : (
          filerPort.map((port, index) => (
            <React.Fragment key={port._id}>
              <Grid item xs={12}>
                <Grid alignItems='center' container justify='flex-end' spacing={1}>
                  <Grid item xs={12} sm>
                    <Grid alignItems='center' container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <CngTextField
                          label='Filer Code'
                          name={`filerPort.${index}.filerCode`}
                          size='small'
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CngTextField
                          label='Port Code'
                          name={`filerPort.${index}.portCode`}
                          size='small'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm='auto'>
                    <Grid container spacing={1}>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'plus']}
                          onClick={() => handleAddFilerPort(defaultValue)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'copy']}
                          onClick={() => handleAddFilerPort({ ...port, id: undefined })}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                      <Grid item>
                        <CngIconButton
                          icon={['fal', 'trash']}
                          onClick={() => handleDeleteFilerPort(index)}
                          size='small'
                          type='outlined'
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {index + 1 < filerPort.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </React.Fragment>
          ))
        )}
      </Grid>
    </CollapsibleSection>
  )
}

export default FilerPortSection
