import React from 'react'
import { components, constants } from 'cng-web-lib'
import {
  Box, Card, Container, Divider, Grid, Link,
  List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles
} from '@material-ui/core'
import { faEnvelope, faFax, faPhoneAlt, faBuildings, faGlobe } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const useStyles = makeStyles(() => ({
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#c19b2e'
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

function ContactUsView() {
  const classes = useStyles()

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Box style={{ paddingTop: '20px' }}>
      <Container>
        <Box mb={5}>
          <Typography variant='h1' style={{ fontWeight: 600 }}>System Support</Typography>
          <Box my={2} className={classes.divider} />
          <Typography style={{ marginBottom: 8 }}>For system related query, feedback or problem reporting, please contact:</Typography>

          <Card variant='outlined'>
            <Box p={2}>
              <Box mb={1}>
                <Typography variant='h5' style={{ fontWeight: 700 }}>Russell A.Farrow</Typography>
                <Typography variant='subtitle2'>AYR Regional Centre</Typography>
              </Box>

              <List disablePadding>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faBuildings} /></ListItemIcon>
                  <ListItemText primary='Addresss: P.O. Box 1177 106 Earl Thompson Road, Ayr, Ontario N0B 1E0' />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faPhoneAlt} /></ListItemIcon>
                  <ListItemText primary='Telephone: (519) 740-9335' />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faFax} /></ListItemIcon>
                  <ListItemText primary='Fax: 519-740-7935' />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faEnvelope} /></ListItemIcon>
                  <ListItemText>
                    <Link className={classes.link} onClick={() => openWindow('mailto:emanifest@farrow.com')} variant='body2'>Email: emanifest@farrow.com</Link>
                  </ListItemText>
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faGlobe} /></ListItemIcon>
                  <ListItemText>
                    <Link className={classes.link} onClick={() => openWindow('https://farrow.com/')} variant='body2'>Website: https://farrow.com/</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          </Card>
        </Box>

        <Box mb={8}>
          <Typography variant='h1' style={{ fontWeight: 600 }}>Product Information</Typography>
          <Box my={2} className={classes.divider} />
          <Typography style={{ marginBottom: 8 }}>For more information on TradeSmartWeb eManifest, please contact:</Typography>

          <Card variant='outlined'>
            <Box p={2}>
              <Box mb={1}>
                <Typography variant='h5' style={{ fontWeight: 700 }}>Russell A.Farrow</Typography>
                <Typography variant='subtitle2'>AYR Regional Centre</Typography>
              </Box>

              <List disablePadding>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faBuildings} /></ListItemIcon>
                  <ListItemText primary='Addresss: P.O. Box 1177 106 Earl Thompson Road, Ayr, Ontario N0B 1E0' />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faPhoneAlt} /></ListItemIcon>
                  <ListItemText primary='Telephone: (519) 740-9335 Ext 282' />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faEnvelope} /></ListItemIcon>
                  <ListItemText>
                    <Link className={classes.link} onClick={() => openWindow('mailto:emanifest@farrow.com')} variant='body2'>Email: emanifest@farrow.com</Link>
                  </ListItemText>
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemIcon><FontAwesomeIcon fixedWidth icon={faGlobe} /></ListItemIcon>
                  <ListItemText>
                    <Link className={classes.link} onClick={() => openWindow('https://farrow.com/canadian-imports-tradesmartweb-emanifest-register')} variant='body2'>Website: https://farrow.com/canadian-imports-tradesmartweb-emanifest-register</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Box>
          </Card>
        </Box>
      </Container>
    </Box >
  )
}

export default ContactUsView
