import { Yup } from 'cng-web-lib'

const RegisterValidationSchema = Yup.object({
  service: Yup.string().required('Required'),
  companyName: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  usScacCode: Yup.string(),
  canadianCarrierCode: Yup.string(),
  contactName: Yup.string().required('Required'),
  contactPhoneNumber: Yup.string().required('Required'),
  contactEmailAddress: Yup.string().required('Required'),
  billingContactName: Yup.string().required('Required'),
  billingContactPhoneNumber: Yup.string().required('Required'),
  billingContactEmailAddress: Yup.string().required('Required')
})

export default RegisterValidationSchema
