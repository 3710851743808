import React from 'react'
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import FullServiceImage from 'src/assets/rbiAssets/solution1.png'
import FaxtoBrokerImage from 'src/assets/rbiAssets/solution2.png'
import CompleteCareImage from 'src/assets/rbiAssets/solution3.png'

function MainContent() {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Grid container justify='center' alignItems='center' style={{ minHeight: '350px', textAlign: 'center' }}>
      <Grid item xs={12} md={6}>
        <Box py={xsDown ? 6 : 10}>
          <Typography variant='h3' style={{ fontWeight: 500 }}>EMANIFEST</Typography>
          <Typography style={{ fontSize: '40pt', fontWeight: 700, color: '#064FA2' }}>eManifest... No More Waiting</Typography>
          <Box mb={2}>
            <Typography style={{ marginBottom: 8 }}>
              RBI Canada offers a secure and personalized service to submit electronic manifest to CBP and CBSA.
              We were among the first companies to begin data transmission in 2006.
              Our experience and dedication is uncompromising.
            </Typography>
            <Typography>
              Our expertise enables us to quickly review your transactions and provide unrivaled customer service. We can take care of all your North American clearance needs.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

function SubContent() {
  return (
    <Container>
      <Box py={6} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant='h1' style={{ fontWeight: 700 }}>We offer different solutions, such as:</Typography>
        </Grid>

        <Grid container spacing={5} style={{ marginTop: '10px' }}>
          <Grid item sm={12} md={4}>
            <Feature icon={FullServiceImage} title='Full-Service'
              description='Set up an email, fax or other data transmission method and you are ready to go. No more waiting for a confirmation before leaving. 
                As soon as you send us the information, your driver can take the road. 
                You will receive confirmation of the acceptance of your manifest via email, SMS or a verbal confirmation from our staff.'
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Feature icon={FaxtoBrokerImage} title='Fax-to-Broker'
              description={`The procedure is the same as for the 'Full Service' and in addition, we also send the necessary documents to the designated broker for customs clearance. 
                With confirmation of acceptance of the manifest, you will also receive, when certified, the entry/transaction number by email/SMS.`}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Feature icon={CompleteCareImage} title='Complete Care'
              description='We can assist in processes during After Hours, weekends or holidays without disrupting your weekday operation conducted by your dispatch. 
                Talk to our experts and we can quickly put together an effective solution. 
                Access information faster, eliminate data-entry duplication and receive real-time responses to submissions.'
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

function Feature(props) {
  const { icon, title, description } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img src={icon} style={{ maxWidth: '125px', maxHeight: '125px' }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h3' style={{ fontWeight: 700 }}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{description}</Typography>
      </Grid>
    </Grid>
  )
}

export { MainContent, SubContent } 
