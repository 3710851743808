import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";
function fetchUser(setUser,securedSendRequest,fileForUserDetails,setLoading) { 

  function onSuccess(response) {
    setUser(response.data)

    if(setLoading!=null && setLoading!=undefined){
      setLoading(false)
    }
  }

  function onError(error) {
    console.log(error);
  }

  function onComplete(){

  }

  let inputFileForUserId = null;
  let inputFileForUserPartyId = null;
  let inputFileForUserLoginId = null;
  if(fileForUserDetails!=null && fileForUserDetails!=undefined){
    inputFileForUserId = fileForUserDetails.fileForUserId;
    inputFileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    inputFileForUserLoginId = fileForUserDetails.fileForUserLoginId;
  }
  
  const config = {}
  const data = {
    fileForUserId: inputFileForUserId,
    fileForUserPartyId: inputFileForUserPartyId,
    fileForUserLoginId: inputFileForUserLoginId
  }
  securedSendRequest.execute('POST',UserProfileApiUrls.GET,data, onSuccess, onError, onComplete,config,null);
  /*const instance = axios.create({
    withCredentials: false,
    headers: {
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("userToken")),
    }
  });
  await instance.get(UserProfileApiUrls.GET).then((result) => {
    const data = Object.assign({}, result.data);
    setUser(result.data)
  });*/

}

function fetchAceHWManifestDefaultValueFromUserProfile(setValue,securedSendRequest) { 

  function onSuccess(response) {
    const data = Object.assign({}, response.data);
    if(response.data.userInsuranceDetails){
      let userInsuranceList = [];
      response.data.userInsuranceDetails.forEach((datum) => {
        if (datum.insuranceFlag && datum.insuranceFlag === "true") {
          datum.id = null;
          datum.createdBy = null;
          datum.createdDate = null;
          datum.updatedBy = null;
          datum.updatedDate = null;
          datum.version = null;
          datum.refId = null;
          userInsuranceList.push(datum);
        }
      })
      if(userInsuranceList.length !== 0){
        setValue("insuranceInfo",userInsuranceList);
      }
      console.log("userInsuranceList: ",userInsuranceList)
    }
    
    console.log("response.data: ",response.data)
  }

  function onError(error) {
    console.log(error);
  }

  function onComplete(){

  }
  const config = {}

  securedSendRequest.execute('POST',UserProfileApiUrls.GET, {}, onSuccess, onError, onComplete,config,null);

  /*const instance = axios.create({
    withCredentials: false,
    headers: {
      'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("userToken")),
    }
  });
  instance.get(UserProfileApiUrls.GET).then((result) => {
    const data = Object.assign({}, result.data);
    if(result.data.userInsuranceDetails){
      let userInsuranceList = [];
      result.data.userInsuranceDetails.forEach((datum) => {
        if(datum.insuranceFlag){
          userInsuranceList.push(datum);
        }
      })
      setValue("insuranceInfo",userInsuranceList);
      console.log("userInsuranceList: ",userInsuranceList)
    }
    
    console.log("result.data: ",result.data)
  });*/
}

function updateUser(userData,callbackSuccess,callbackFail,securedSendRequest) {

  function onSuccess(response) {
    callbackSuccess(response.data)
  }

  function onError(error) {
    console.log(error);
    callbackFail()
  }

  function onComplete(){

  }
  const config = {}

  securedSendRequest.execute('POST',UserProfileApiUrls.POST, userData, onSuccess, onError, onComplete,config,null);
}

function uploadSignFile(formData,callbackSuccess,callbackFail,securedSendRequest) {

  function onSuccess(response) {
    callbackSuccess(response.data)
  }

  function onError(error) {
    console.log(error)
    callbackFail()
  }

  function onComplete(){

  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }

  securedSendRequest.execute('POST',UserProfileApiUrls.UPLOAD_SIGN_FILE, formData, onSuccess, onError, onComplete,config,null);

  //console.log("uploadSignFile: ",formData)
  /*axios.interceptors.request.use(function (config) {
    if(localStorage.getItem("userToken") != null || localStorage.getItem("userToken") != undefined)
    {
      const token = JSON.parse(localStorage.getItem("userToken"));
      config.headers.Authorization =  "Bearer "+ token;
    }
      else {
        console.log("Session expired!")
        config.headers.Authorization =  null;
       }
    return config;
  });

  axios.post(UserProfileApiUrls.UPLOAD_SIGN_FILE, formData,{
  headers: {
      'Content-Type': 'multipart/form-data',
  }
  }).then((response) => {
    // Success
    //sweetalert("File has been uploaded successfully","" ,"success");
    callbackSuccess(response.data)
  }).catch((error) => {
    //sweetalert("Error while uploading file","","error")
    console.log(error)
    callbackFail()
  })*/ 
}

export { fetchUser,fetchAceHWManifestDefaultValueFromUserProfile,updateUser,uploadSignFile };
