import React from 'react'
import { useHistory } from 'react-router-dom'
import { components } from 'cng-web-lib'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { MainContent, SubContent } from './Content'
import Page from 'src/views/staticHomeView/ContactView/Page'
import DriverImage from 'src/assets/rbiAssets/img1.png'

const { button: { CngButton } } = components

const useStyles = makeStyles(() => ({
  background1: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/rbiAssets/background.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  background2: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/rbiAssets/background2.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#fff'
  },
  contactButton: {
    backgroundColor: '#fff',
    borderColor: '#d25d13',
    borderWidth: 2,
    borderRadius: 999,
    borderStyle: 'solid',
    boxShadow: 'none',
    color: '#d25d13',
    fontSize: 16,
    lineHeight: 1.2,
    minHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#d25d13',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    }
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#064fa2'
  },
  driverImage: {
    width: '100%',
    borderRadius: 16,
    ['@media(max-width: 960px)']: {
      maxWidth: '445px',
      maxHeight: '297px'
    }
  }
}))

function RBIHomeView() {
  const classes = useStyles()
  const history = useHistory()
  sessionStorage.clear()
  sessionStorage.setItem('homePagePath', '/rbi')

  return (
    <Page title='Home' style={{ paddingTop: '33px' }}>
      <Box className={classes.background1}>
        <MainContent />
      </Box>

      <Box className={classes.background2}>
        <SubContent />
      </Box>

      <Box pt={6} pb={6}>
        <Container>
          <Grid alignItems='center' container>
            <Grid item xs={12} md={6}>
              <Box display='flex' justifyContent='center' paddingRight={10} paddingBottom={2}>
                <img src={DriverImage} className={classes.driverImage} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h1' style={{ fontWeight: 700 }}>Your Direct Line</Typography>
              <Box my={2}>
                <Box className={classes.divider} />
              </Box>
              <Typography>
                Crossing the border can be complicated. Rely on experts in the field to ensure you maximize your cross-border transactions (Canada/USA).
              </Typography>
              <Box mt={1}>
                <CngButton className={classes.contactButton} onClick={() => history.push('/rbi-contact-us')}>Contact Us</CngButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  )
}

export default RBIHomeView
