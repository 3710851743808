import { Yup } from 'cng-web-lib'
import _ from 'lodash'

function makeValidationSchema() {
  return Yup.object({
    destination: Yup.string().required('Status Notfication: Destination is required.'),
    notifyType: Yup.string().required('Status Notification: SN Type is required.'),
    email: Yup.string()
      .required('Status Notification: Email is required.')
      .test('valid-email', 'Status Notification: Invalid email address.', function (value) {
        let isValid = true

        const emails = value.replaceAll(' ', '').split(',')
        const invalidEmails = []

        emails.forEach((email) => {
          if (!Yup.string().email().isValidSync(email)) {
            isValid = false
            invalidEmails.push(email)
          }
        })

        if (!isValid && !_.isEmpty(invalidEmails)) {
          return this.createError({
            message: `Status Notification: Invalid email address - ${invalidEmails.join(', ')}`
          })
        }

        return isValid
      })
      .test({
        message: 'Status Notification: Cannot have more than 1 emails in one record.',
        test: (value) => !(value.replaceAll(' ', '').split(',').length > 1)
      })
    ,
    faxNum: Yup.string().max(25, "Fax Number maximum length is 25").nullable(),
    mobileNum: Yup.string().max(25, "Mobile Number maximum length is 25").nullable()
  })
}

export default makeValidationSchema
