import React from 'react'
import { components, constants } from 'cng-web-lib'
import {
  Box, Card, Container, Divider, Grid, Link,
  List, ListItem, ListItemIcon, ListItemText, Typography, makeStyles
} from '@material-ui/core'
import { faEnvelope, faFax, faPhoneAlt, faCommentSms } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormProperties from './ContactFormProperties'


const useStyles = makeStyles(() => ({
  contactUs: {
    minHeight: 550,
    height: '100%',
    paddingTop: '33px',
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/rbiAssets/contactus.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#fff'
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#064fa2'
  },
  button: {
    color: '#fff',
    backgroundColor: '#d25d13',
    borderRadius: '8px',
    boxShadow: 'none',
    fontSize: 16,
    minHeight: 'unset',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#A8490D',
      boxShadow: `0px 3px 7px rgba(0, 0, 0, 0.08),
        0px 6px 12px -2px rgba(0, 0, 0, 0.09),
        0px 15px 32px -3px rgba(0, 0, 0, 0.1)`
    }
  },
  link: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const {
  form: { CngForm },
  button: { CngButton },
} = components
const { FormState } = constants

function ContactUsView() {
  const classes = useStyles()

  function openWindow(newPath) {
    window.open(newPath, '_blank', 'noreferrer')
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.contactUs}>
          <Container maxWidth='md' >
            <Grid item xs={12}>
              <Box mb={5}>
                <Typography variant='h1' style={{ fontWeight: 600, paddingTop: '20px' }}>Your Direct Line</Typography>

                <Box my={2} className={classes.divider} />

                <Typography style={{ marginBottom: 8 }}>
                  Customs management is complex. Rely on the experts in their field to maximize your import and export operations.
                </Typography>
                <b>RBI is your direct line to peace of mind.</b>
              </Box>
            </Grid>

            <Box>
              <Box mb={2}>
                <Typography variant='h1' color='secondary' style={{ fontWeight: 600 }}>Contact Us</Typography>
              </Box>

              <Box my={2} className={classes.divider} />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Card variant='outlined'>
                    <Box p={2}>
                      <Box mb={1}>
                        <Typography variant='h5' style={{ fontWeight: 700 }}>RBI Customer Service:</Typography>
                      </Box>
                      <List disablePadding>
                        <ListItem dense disableGutters>
                          <ListItemIcon><FontAwesomeIcon fixedWidth icon={faPhoneAlt} /></ListItemIcon>
                          <ListItemText primary='Mobile / SMS: (514) 494-2518' />
                        </ListItem>
                        <ListItem dense disableGutters>
                          <ListItemIcon><FontAwesomeIcon fixedWidth icon={faFax} /></ListItemIcon>
                          <ListItemText primary='Fax: (855) 724-0046' />
                        </ListItem>
                      </List>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Card variant='outlined'>
                    <Box p={2}>
                      <Box mb={1}>
                        <Typography variant='h5' style={{ fontWeight: 700 }}>
                          Centralize Documents Processing:
                          <div>
                            <Typography variant='caption'>ACE or ACI manifest</Typography>
                          </div>
                        </Typography>
                      </Box>
                      <List disablePadding>
                        <ListItem dense disableGutters>
                          <ListItemIcon><FontAwesomeIcon fixedWidth icon={faFax} /></ListItemIcon>
                          <ListItemText primary='Fax: (855) 724-0046' />
                        </ListItem>
                        <ListItem dense disableGutters>
                          <ListItemIcon>
                            <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                          </ListItemIcon>
                          <ListItemText>
                            <Link className={classes.link} onClick={() => openWindow('mailto:ace@rbicanada.com')} variant='body2'>
                              Email: ace@rbicanada.com
                            </Link>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Box>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={4} style={{ marginBottom: '10px' }}>
                  <Card variant='outlined'>
                    <Box p={2}>
                      <Box mb={1}>
                        <Typography variant='h5' style={{ fontWeight: 700 }}>US/Canadian Customs Clearance Info:</Typography>
                      </Box>
                      <List disablePadding>
                        <ListItem dense disableGutters>
                          <ListItemIcon><FontAwesomeIcon fixedWidth icon={faEnvelope} /></ListItemIcon>
                          <ListItemText>
                            <Link className={classes.link} onClick={() => openWindow('mailto:info@rbicanada.com')} variant='body2'>
                              Email: info@rbicanada.com
                            </Link>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box >
      </Grid>

      <Grid item xs={12} style={{ color: '#fff', background: '#002857' }}>
        <Container maxWidth='md' >
          <Box py={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box mb={4}>
                  <Typography variant='h1' style={{ fontWeight: 800, textAlign: 'center' }}>Send Us A Message</Typography>
                </Box>
                <Box>
                  <CngForm
                    formikProps={FormProperties.formikProps}
                    formState={FormState.COMPLETED}
                    renderBodySection={(labelMap, shouldHideMap) => (
                      <Card>
                        <Box padding={2}>
                          <FormProperties.Fields shouldHideMap={shouldHideMap} />
                        </Box>
                      </Card>
                    )}
                    renderButtonSection={() => (
                      <CngButton className={classes.button} type='submit' fullWidth>Submit</CngButton>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ContactUsView
