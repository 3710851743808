import { Yup } from 'cng-web-lib'

function makeValidationSchema() {
  return Yup.object({
    clientCode: Yup.string()
    .when('module', {
      is: (value) => value != 'AES',
      then: Yup.string().required('Client Code: Client Code is required.'),
      otherwise: Yup.string().nullable()
    }).nullable(),
    module: Yup.string().required('Client Code: Module is required.')
  })
}

export default makeValidationSchema
