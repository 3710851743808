import Contact from '../ContactView/Contact'
import CTA from './CTA'
import FAQS from './FAQS'
import FeaturesUPS from './FeaturesUPS'
import HeroUPS from './HeroUPS'
import FeaturesGets from './FeaturesGets'
import HeroGets from './HeroGets'
import Page from 'src/views/staticHomeView/ContactView/Page'
import React from 'react'
import Testimonials from './Testimonials'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {}
}))

function HomeView() {
  const classes = useStyles()

  sessionStorage.clear();
  return (
    <Page className={classes.root} title='Home'>
      <HeroGets />
      <FeaturesGets />
      {/*
      <HeroUPS />
      <FeaturesUPS />
      */}
      {/* <Contact /> */}
      {/* <Testimonials />
      <CTA />
      <FAQS /> */}
    </Page>
  )
}

export default HomeView
