import React, {useEffect, useState} from 'react'
import { components } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import HTSOverridesSection from './HTSOverridesSection'
import FilerPortSection from './FilerPortSection'
import SubLocationSection from './SubLocationSection'
import AutoGenerateEHBLCCN from './AutoGenerateEHBLCCN'
import AutoGenerateTripNumber from './AutoGenerateTripNumber'
import AutoGenerateShipmentAndCargoControlNumber from './AutoGenerateShipmentAndCargoControlNumber'
import PartyIdentificationSection from './PartyIdentificationSection'
import SignatureSection from './SignatureSection'
import SpecialInstructionSection from './SpecialInstructionSection'
import { Grid } from '@material-ui/core'
import _ from 'lodash'

const {
  form: {
    field: { CngCheckboxField, CngTextField }
  }
} = components

function GeneralSection(props) {
  const { isSuperParty, showNotification, whiteLabelServiceSettings } = props
  const [upsUser, setUpsUser] = useState(false);

  useEffect(() => {
    console.log("whiteLabelServiceSettings: " + JSON.stringify(whiteLabelServiceSettings));
    if (whiteLabelServiceSettings && whiteLabelServiceSettings.length > 0) {
      const topBarId = whiteLabelServiceSettings[0].custom.customTopBarId;
      console.log("topBarId: " + topBarId)
      setUpsUser(topBarId && topBarId.trim() === "UpsCustomTopBar");
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CngSection title='Integrations'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HTSOverridesSection />
            </Grid>
            <Grid item xs={12}>
              <FilerPortSection />
            </Grid>
            <Grid item xs={12}>
              <SubLocationSection />
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <CngSection title='Auto-Generation'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutoGenerateEHBLCCN />
            </Grid>
            <Grid item xs={12}>
              <AutoGenerateTripNumber />
            </Grid>
            <Grid item xs={12}>
              <AutoGenerateShipmentAndCargoControlNumber />
            </Grid>
          </Grid>
        </CngSection>
      </Grid>
      <Grid item xs={12}>
        <PartyIdentificationSection />
      </Grid>
      <Grid item xs={12}>
        <SignatureSection showNotification={showNotification} />
      </Grid>
      {(isSuperParty || upsUser) && <Grid item xs={12}>
        <CngSection title='UPS Code'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CngTextField
                label='UPS SCS Client ID'
                name='upsSacClientId.upsSacClientId'
                size='small'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CngTextField
                label='Business Unit Code'
                name='upsSacClientId.upsBusinessUnitCode'
                size='small'
              />
            </Grid>
          </Grid>
        </CngSection>
      </Grid>}
      {isSuperParty && (
        <Grid item xs={12}>
          <SpecialInstructionSection />
        </Grid>
      )}
    </Grid>
  )
}

export default GeneralSection
