import React from 'react'
import { components } from 'cng-web-lib'
import { Box, Card, Grid, Typography } from '@material-ui/core'

const {
  form: {
    field: { CngSelectField, CngSwitchField }
  }
} = components

function AutoGenerateShipmentAndCargoControlNumber() {

  return (
    <Card variant='outlined'>
      <Box px={2} py={1}>
        <Grid alignItems='center' container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant='body2' style={{ fontWeight: 600 }}>
              Auto-Generate SCN / CCN 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CngSelectField
              label='Module'
              multiple
              name='userAutoScns.module'
              size='small'
              items={[
                { text: 'ACE Highway', value: 'ACEHWY' },
                { text: 'ACI Highway', value: 'ACIHWY' }
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              <CngSwitchField
                label={
                  <Typography variant='body2' style={{ fontWeight: 600 }}>
                    Enable Auto Control Number
                  </Typography>
                }
                labelPlacement='start'
                name='userAutoScns.autoScn'
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default AutoGenerateShipmentAndCargoControlNumber
