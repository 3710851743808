import {
  Avatar,
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import USAFlag from '../../../../assets/img/us.svg'
import CanadaFlag from '../../../../assets/img/ca.svg'

const useStyles = makeStyles(() => ({
  background1: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/static/images/Background1.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: 400
  },
  check: {
    width: 24,
    height: 24,
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: '#ffc400',
    color: '#121212'
  },
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#ffc400'
  },
  list: {
    listStyle: 'none',
    '& > li': {
      marginLeft: 16,
      marginBottom: 8,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: -16,
        top: 8,
        width: 4,
        height: 4,
        backgroundColor: '#ffc400',
        borderRadius: '50%'
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  page: {
    backgroundColor: '#ffffff',
    color: '#121212'
  }
}))

function ProductInfoView() {
  const classes = useStyles()

  function openWindow(url) {
    window.open(url, '_blank', 'noreferrer')
  }

  const upsBorderReadyFeatures = [
    'Template features to minimize rekeying of repetitive data',
    'Quick preparation and response with e-mail notification for milestones',
    'Benefit from no software purchase or installation and automatic upgrades',
    'Receive and monitor customs release messages 24/7',
    'Messages date- and time-stamped by CBP and CBSA for full audit capabilities',
    'Full reporting capabilities',
    '24/7 customer support via e-mail and phone'
  ]

  const technicalRequirements = [
    'PC with Pentium® 2-class or above',
    'Minimum 64MB RAM; at least 128MB RAM recommended',
    'Modem with Internet access; minimum 56kbps bandwidth; broadband of at least 256kbps recommended',
    'Microsoft® Internet Explorer® 6.0 or later versions',
    'Adobe® Acrobat® 4.0 (or higher) for viewing and printing of PDF files Microsoft® Internet Explorer® 6.0 or later versions'
  ]

  return (
    <Box className={classes.page}>
      <Box className={classes.background1}>
        <Container>
          <Grid container spacing>
            <Grid item xs={12} md={6}>
              <Box py={10}>
                <Typography variant='h1' style={{ fontWeight: 700 }}>
                  Our Solution
                </Typography>
                <Box mt={2} mb={1}>
                  <Box className={classes.divider} />
                </Box>
                <Grid container spacing={2}>
                  <Grid item>
                    <Box display='flex' style={{ gap: 8 }}>
                      <Typography variant='body2'>
                        Automated Commercial Environment
                      </Typography>
                      <Chip
                        avatar={<Avatar src={USAFlag} />}
                        label='ACE'
                        size='small'
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Divider orientation='vertical' />
                  </Grid>
                  <Grid item>
                    <Box display='flex' style={{ gap: 8 }}>
                      <Typography variant='body2'>
                        Advance Commercial Information
                      </Typography>
                      <Chip
                        avatar={<Avatar src={CanadaFlag} />}
                        label='ACI'
                        size='small'
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pb={6}>
        <Container style={{ padding: 24 }}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700 }}>
                Speed Preparation and Filing
              </Typography>
              <Box my={2}>
                <Box className={classes.divider} />
              </Box>
              <Box mb={2}>
                <Typography>
                  Available from UPS Customs Brokerage for importers, exporters
                  and carriers, UPS BorderReady speeds the preparation and
                  electronic filing of trade documentation and declarations.
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography style={{ fontWeight: 700 }}>
                  UPS BorderReady allows for the submission of ACE/ACI cargo
                  eManifests:
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Typography style={{ fontWeight: 700 }}>
                      Automated Commercial Environment (ACE)
                    </Typography>
                  </Box>
                  <Typography>
                    The Automated Commercial Environment (ACE) is the U.S.
                    Customs and Border Protection (CBP) trade processing system
                    used to facilitate legitimate trade and strengthen border
                    security. Highway carriers are required to send cargo and
                    conveyance details in electronic format to CBP before
                    reaching the U.S. border.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box mb={2}>
                    <Typography style={{ fontWeight: 700 }}>
                      Advance Commercial Information (ACI)
                    </Typography>
                  </Box>
                  <Typography>
                    The Advance Commercial Information (ACI) program requires
                    providing the Canada Border Services Agency (CBSA) with
                    electronic pre-arrival cargo and conveyance information in
                    order to identify health, safety and security threats
                    related to commercial cargo before goods arrival in Canada.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700 }}>
                Maximize Efficiency
              </Typography>
              <Box my={2}>
                <Box className={classes.divider} />
              </Box>
              <Typography style={{ marginBottom: 8 }}>
                Reduce time-consuming manual data entry and the costly hours and
                errors that go with it when submitting ACE/ACI cargo eManifests
                and trade declarations. An online account stores all your
                drivers, tractors, trailers, trade parties as well as previous
                trip and shipment details.
              </Typography>
              <Typography>
                Enhanced web-account features are available to ease your data
                entry, including creation of templates and cloning of eManifest
                and trade declaration details. Email alerts for responses
                received from CBP or CBSA can be communicated directly to trade
                participants to ensure smooth and seamless border crossings.
                After electronically submitting commercial invoices to UPS, you
                will receive entry numbers in electronic format once the cargo
                is cleared.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700 }}>
                Available Trade Documentation
              </Typography>
              <Box my={2}>
                <Box className={classes.divider} />
              </Box>
              <Box mb={2}>
                <Typography>
                  The following trade documents are available with UPS
                  BorderReady and can be used for submission to customs:
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ marginBottom: 8, fontWeight: 700 }}>
                    Canada
                  </Typography>
                  <ul className={classes.list}>
                    <li>Canada Customs Invoice (CCI)</li>
                    <li>UNITED STATES-MEXICO-CANADA AGREEMENT(USMCA-CUSMA)</li>
                    <li>Export Declaration (B13A)</li>
                    <li>
                      Import Declaration (Canadian Food Inspection Agency-CFIA)
                    </li>
                    <li>
                      Request for Documentation Review (Canadian Food Inspection
                      Agency- CFIA)
                    </li>
                  </ul>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ marginBottom: 8, fontWeight: 700 }}>
                    USA
                  </Typography>
                  <ul className={classes.list}>
                    <li>Commercial Invoice</li>
                    <li>
                      Pro Forma Invoice (Food and Drug Administration-FDA,
                      Bio-Terrorism Act- BTA)
                    </li>
                    <li>UNITED STATES-MEXICO-CANADA AGREEMENT(USMCA-CUSMA)</li>
                    <li>
                      Toxic Substance Control Act (TSCA)- Import Certification
                      Form
                    </li>
                    <li>
                      US Animal and Plant Health Inspection Service (USAPHIS
                      17-29)
                    </li>
                    <li>
                      US Environment Protection Agency (EPA 3520-1, EPA 3520-21)
                      Importation of Motor Vehicles and Motor Engines Subject to
                      Federal Air Pollution Regulations
                    </li>
                    <li>
                      Federal Communications Commission (FCC-740) Radio
                      Frequency Devices Capable of Causing Harmful Interference
                    </li>
                    <li>
                      Food and Drug Administration (FDA-2877) Electronic
                      Products Subject to Radiation Control Standards
                    </li>
                    <li>
                      Department of Transportation (DOT-HS-7) Federal Motor
                      Vehicle Safety, Bumper and Theft Prevention Standards
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h1' style={{ fontWeight: 700 }}>
                Protecting Your Data
              </Typography>
              <Box my={2}>
                <Box className={classes.divider} />
              </Box>
              <Box mb={3}>
                <ul className={classes.list}>
                  <li>
                    Only authorized users who have been assigned a user ID and
                    password can access UPS BorderReady
                  </li>
                  <li>Data is encrypted and secured during HTTPS transport</li>
                  <li>
                    Hardware encryptors and dedicated circuit are employed for
                    interchange with CBP/CBSA
                  </li>
                </ul>
              </Box>
              <Box bgcolor='#f2f1ef' borderRadius={2} p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box mb={3}>
                      <Typography variant='h4' style={{ fontWeight: 700 }}>
                        UPS BorderReady Features
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {upsBorderReadyFeatures.map((feature, index) => (
                        <Grid key={index} item xs={12}>
                          <Information text={feature} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box mb={3}>
                      <Typography variant='h4' style={{ fontWeight: 700 }}>
                        Technical Requirements
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {technicalRequirements.map((requirement, index) => (
                        <Grid key={index} item xs={12}>
                          <Information text={requirement} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Box mb={3}>
                      <Typography variant='h4' style={{ fontWeight: 700 }}>
                        System Interfaces
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Information>
                          Web browser to URL:{' '}
                          <Link onClick={() => openWindow('https://borderready.ups.com')}>
                            https://borderready.ups.com
                          </Link>
                        </Information>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          component='div'
                          variant='caption'
                          style={{ marginBottom: 8, lineHeight: 1.2 }}
                        >
                          Pentium is a trademark of Intel Corporation in the
                          U.S. and/or other countries.
                        </Typography>
                        <Typography
                          component='div'
                          variant='caption'
                          style={{ marginBottom: 8, lineHeight: 1.2 }}
                        >
                          Microsoft and Internet Explorer are registered
                          trademarks of Microsoft Corporation in the United
                          States and other countries.
                        </Typography>
                        <Typography
                          component='div'
                          variant='caption'
                          style={{ lineHeight: 1.2 }}
                        >
                          Adobe and Acrobat are either registered trademarks or
                          trademarks of Adobe Systems Incorporated in the United
                          States and/or other countries.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

function Information(props) {
  const { children, text } = props
  const classes = useStyles()

  return (
    <Box display='flex' style={{ gap: 16 }}>
      <Box className={classes.check}>
        <FontAwesomeIcon icon={['fa', 'check']} />
      </Box>
      <Box flexGrow={1}>{children || text}</Box>
    </Box>
  )
}

export default ProductInfoView
