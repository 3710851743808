import React, { useRef, useState } from 'react'
import ACEHighwayInsuranceFormProperties from './ACEHighwayInsuranceFormProperties'
import { Card, Chip, useTheme } from '@material-ui/core'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import LocalTable from '../../../components/aciacehighway/LocalTable'
import DialogForm from '../../../components/aciacehighway/DialogForm'

function ACEHighwayInsuranceSection() {
  const [dialog, setDialog] = useState({ open: false, insurance: null })
  const { getValues, setValue, watch } = useFormContext()
  const userInsuranceDetails = watch('userInsuranceDetails') || []
  const lastIndex = useRef(userInsuranceDetails.length)
  const theme = useTheme()

  const columns = [
    {
      title: 'Policy No.',
      field: 'insurancePolicyNo',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Insurance Company',
      field: 'insuranceCompany',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Liability Amount',
      field: 'liabilityAmount',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Issuance Year',
      field: 'yearOfIssuance',
      filterConfig: { type: 'textfield' }
    },
    {
      title: 'Status',
      field: 'insuranceFlag',
      render: (data) =>
        data.insuranceFlag ? (
          <Chip
            label='Active'
            size='small'
            style={{
              backgroundColor: theme.palette.success.main,
              color: theme.palette.success.contrastText
            }}
          />
        ) : (
          <Chip label='Inactive' size='small' />
        )
    }
  ]

  function handleAddInsurance(data) {
    const userInsuranceDetails = [
      ...getValues('userInsuranceDetails'),
      { ...data, _id: lastIndex.current++ }
    ]

    setValue('userInsuranceDetails', userInsuranceDetails)
    setDialog({ open: false, insurance: null })
  }

  function handleDeleteInsurance(data) {
    const userInsuranceDetails = [...getValues('userInsuranceDetails')].filter(
      (insurance) =>
        insurance.id ? insurance.id !== data.id : insurance._id !== data._id
    )

    setValue('userInsuranceDetails', userInsuranceDetails)
  }

  function handleEditInsurance(data) {
    const userInsuranceDetails = [...getValues('userInsuranceDetails')]
    const index = userInsuranceDetails.findIndex((insurance) =>
      insurance.id ? insurance.id === data.id : insurance._id === data._id
    )

    userInsuranceDetails[index] = data

    setValue('userInsuranceDetails', userInsuranceDetails)
    setDialog({ open: false, insurance: null })
  }

  return (
    <>
      <Card variant='outlined'>
        <LocalTable
          actions={[
            {
              buttonProps: { size: 'medium', color: 'secondary' },
              icon: ['fal', 'plus-circle'],
              label: 'Add Insurance',
              onClick: () => setDialog({ open: true, insurance: null })
            }
          ]}
          columns={columns}
          data={userInsuranceDetails}
          rowActions={[
            {
              icon: ['fal', 'pen'],
              onClick: (rowData) =>
                setDialog({ open: true, insurance: rowData }),
              tooltip: () => 'Edit'
            },
            {
              icon: ['fal', 'trash'],
              onClick: handleDeleteInsurance,
              tooltip: () => 'Delete'
            }
          ]}
        />
      </Card>
      <DialogForm
        formProperties={ACEHighwayInsuranceFormProperties}
        fullWidth
        maxWidth='md'
        open={dialog.open}
        onClose={() => setDialog({ open: false, insurance: null })}
        onSubmit={dialog.insurance ? handleEditInsurance : handleAddInsurance}
        title={`${dialog.insurance ? 'Edit' : 'Add'} Insurance`}
        value={dialog.insurance}
      />
    </>
  )
}

export default ACEHighwayInsuranceSection
