import React from 'react'
import { Box, Container, Grid, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import USAFlag from 'src/assets/img/us.svg'
import CanadaFlag from 'src/assets/img/ca.svg'
import TruckImage from 'src/assets/fciAssets/truckhighway.jpg'

const useStyles = makeStyles(() => ({
  divider: {
    height: 4,
    width: 48,
    maxWidth: '100%',
    backgroundColor: '#dd0028'
  },
  image: {
    maxWidth: '384px'
  }
}))

function MainContent() {
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Grid container justify='center' alignItems='center' style={{ textAlign: 'center' }}>
      <Grid item xs={12} md={6}>
        <Box py={xsDown ? 2 : 5}>
          <Typography variant='h3' style={{ fontWeight: 500 }}>Providing Excellence Since 2002</Typography>
          <Typography style={{ fontSize: '40pt', fontWeight: 700, color: '#dd0028' }}>Fineline Crossing</Typography>
          <Box mb={2}>
            <Typography style={{ marginBottom: 8 }}>
              Fineline Crossing is a part of Xpert IT Solutions Inc.
            </Typography>
            <Typography>
              We are a well-rounded team of technical, industry and interpersonal expertise including software developers, engineers, hardware technicians, customer service, support, administration and sales.
              We prize communication and professionalism internally within our team as well as externally with all interactions.
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

function SubContent() {
  return (
    <Container>
      <Box py={6} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography variant='h1' style={{ fontWeight: 700 }}>HIGHWAY CARRIERS</Typography>
          <Typography variant='subtitile2' style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Using Fineline Crossing, carriers can participate in the Automated Commercial Environment (ACE) and the Advance Commercial Information (ACI) programs.
          </Typography>
        </Grid>

        <Grid container spacing={5} style={{ marginTop: '10px' }}>
          <Grid item sm={12} md={4}>
            <Feature icon={USAFlag} title='Automated Commercial Environment'
              description='ACE was introduced by the United States Customs and Border Protection (CBP) in 2007 as a pre-arrival security program that enables CBP to screen inbound shipments with the use of electronic manifests.'
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Feature icon={CanadaFlag} title='Advance Commercial Information'
              description={'ACI is the Canada Border Service Agency (CBSA) counterpart to ACE, which requires an electronic manifest for commercial vehicle pre-screening. Carriers using ACI can also benefit from the Canadian which provides instantaneous notifications of goods clearance and release.'}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <Feature icon={TruckImage} title='Seamless Experience'
              description='The Fineline Crossing application enables carriers to submit electronic manifests either online, or fax, or through EDI integration. A customer service facility is available 24/7 to give support and assistance to customers in need.'
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

function Feature(props) {
  const { icon, title, description } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img src={icon} style={{ maxHeight: '80px' }} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h4' style={{ fontWeight: 700 }}>{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{description}</Typography>
      </Grid>
    </Grid>
  )
}

export { MainContent, SubContent } 
