import React from 'react'
import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Page from 'src/views/staticHomeView/ContactView/Page'
import TruckBanner from 'src/assets/rafAssets/truckBanner.jpg'


const useStyles = makeStyles(() => ({
  title: {
    color: '#526671',
    fontSize: '25px',
    fontFamily: 'Calibri'
  },
  header: {
    color: '#fff',
    padding: '5px',
    fontSize: '20px',
    fontFamily: 'Calibri'
  },
  contentHeader: {
    fontSize: '14px',
    fontFamily: 'Calibri',
    marginBottom: 1
  },
  content: {
    fontSize: '14px',
    fontFamily: 'Calibri',
    marginBottom: 15
  },
  list: {
    listStyle: 'none',
    '& > li': {
      fontSize: '14px',
      fontFamily: 'Calibri',
      display: 'flex',
      marginBottom: 2,
      '&:last-child': {
        marginBottom: 15
      }
    }
  },
  bulletPoint: {
    marginRight: 8
  }
}))

function RAFHomeView() {
  const classes = useStyles()
  sessionStorage.clear()
  sessionStorage.setItem('homePagePath', '/raf')

  return (
    <Page title='Home' style={{ paddingTop: '10px' }}>
      <Container>
        <Box display='flex' justifyContent='center' paddingBottom={2}>
          <img src={TruckBanner} />
        </Box>

        <Typography className={classes.title}>TradeSmartWeb eManifest</Typography>
        <Grid container spacing={4} style={{ marginBottom: 8 }}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.header} style={{ backgroundColor: '#c09b30' }}>Facilitating Business at the Border</Typography>
            <Typography className={classes.content}>
              TradeSmartWeb eManifest portal is an ACI eManifest solution designed to simplify and expedite cargo movements across the Canadian border.
              It provides a direct connection to Canada Border Services Agency (CBSA) for submission of conveyance and cargo data to ensure the highest level customs compliance.
            </Typography>
            <Typography className={classes.content}>
              The TradeSmartWeb eManifest portal helps navigate through the regulatory landscape of customs compliance with 24 x 7 customer support team available via phone or email to answer questions about regulations affecting the movement of your cargo.
            </Typography>
            <Typography className={classes.content}>
              Consider TradeSmartWeb eManifest as an extension of your business, to add to the implementation of a sound strategy to increase efficiency in operations, lower costs and gain visibility of cargo movements across borders giving your company a competitive edge to compete globally.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.header} style={{ backgroundColor: '#6e91a4' }}>Features of the TSW eManifest portal</Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container style={{ backgroundColor: '#dce0e3', padding: 4 }}>
                  <Grid item={12}>
                    <Typography className={classes.contentHeader}><strong>Web</strong></Typography>
                    <Typography className={classes.content}>
                      An online account which stores all your drivers, tractors, trailers, consignees/shippers as well as previous trip and shipment details.
                      Enhanced features on the web account are available to ease data;
                    </Typography>

                    <ul className={classes.list}>
                      <li>
                        <span><FontAwesomeIcon icon="fa-solid fa-caret-right" className={classes.bulletPoint} /></span>
                        <div>Creating trip templates for drivers which store the tractor, trailer and driver details for quicker eManifest creation.</div>
                      </li>
                      <li>
                        <span><FontAwesomeIcon icon="fa-solid fa-caret-right" className={classes.bulletPoint} /></span>
                        <div>Email alerts for responses received from Canada Customs can be communicated directly to the driver to ensure smooth and seamless border crossings.</div>
                      </li>
                    </ul>

                  </Grid>

                  <Grid item={12}>
                    <Typography className={classes.contentHeader}><strong>Integration</strong></Typography>
                    <Typography className={classes.content}>
                      The TradeSmartWeb eManifest portal also offers data integration that can seamlessly transfer manifest submissions and responses from in-house systems and submit directly to Canada Customs.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Container>
    </Page>
  )
}

export default RAFHomeView
